import React, { useEffect, useState } from 'react'
import styles from './Medico.module.css'
import { useAssociadoContext } from '../../useAssociadoContext'
import MedicoForm from './MedicoForm'
import { useMedicoContext } from '../../useMedicoContext'
import TituloAssociadoMed from '../Helper/TituloAssociado'
import TituloAssociado from '../Helper/TituloAssociado'
import Button from '../Forms/Button'
import { useUserContext } from '../../UserContext'
import { APROVA_CADASTRO_MEDICO, DELETE_MEDICO } from '../../api'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom'

const Medico = () => {
  const { medico, formData } = useMedicoContext()
  const navigate = useNavigate()
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const { loading, setLoading, token } = useUserContext()
  const dateOnly = medico[0].dat_nascimento.split('T')[0]
  const [ano, mes, dia] = dateOnly.split('-');
  const [openModal, setOpenModal] = useState(false);
  const dataNasc = dia + '/' + mes + '/' + ano;
  const [initialValues, setInitialValues] = useState({
    nom_medico: medico[0].nom_medico,
    dat_nascimento: dataNasc,
    num_cpf: medico[0].num_cpf,
    sgl_conselho: medico[0].sgl_conselho,
    num_crm: medico[0].num_crm,
    especialidades: medico[0].especialidades,
    num_identidade: medico[0].num_identidade,
    nom_orgao_expedidor: medico[0].nom_orgao_expedidor,
    naturalidade: medico[0].naturalidade,
    des_uf: medico[0].des_uf,
    des_sexo_medico: medico[0].des_sexo_medico,
    des_endereco: medico[0].des_endereco,
    des_estado_civil: medico[0].des_estado_civil,
    cidade: medico[0].cidade,
    num_cep: medico[0].num_cep,
    tel_fixo: medico[0].tel_fixo,
    tel_celular: medico[0].tel_celular,
    des_email: medico[0].des_email,
    whatsapp: medico[0].whatsapp,
    instagram: medico[0].instagram,
    resp_cadastro: medico[0].resp_cadastro,
    tel_resp_cadastro: medico[0].tel_resp_cadastro,
    docs: medico[0].docs
  })

  function fecharModal() {
    setOpenModal(false);
    setEmailSent(false);
    navigate('/area-associado/corpo-clinico')
  }
  function fecharModalExc() {
    setOpenModal(false);
  }

  async function aprovaCadastro() {
    try {
      setLoading(true);
      const { url, options } = APROVA_CADASTRO_MEDICO(token, { id_clinica: medico[0].id_clinica, id_medico: medico[0].id_medico, data: formData, altera_medico: true})
      const response = await fetch(url, options);
      const { message } = await response.json();
      if (!response.ok) throw new Error(message);
      setMensagem(message)
      setEmailSent(true)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  async function excluirMedico() {
    try {
      setLoading(true);
      setOpenModal(false);
      const { url, options } = DELETE_MEDICO(token, { id_clinica: medico[0].id_clinica, id_medico: medico[0].id_medico })
      const response = await fetch(url, options);
      const { message } = await response.json();
      if (!response.ok) throw new Error(message);
      setMensagem(message)
      setEmailSent(true)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    console.log(medico)
  }, [])


  return (

    <section className={`${styles.containerMain} animeLeft`}>
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <Button descricao='fechar' classe='second' onClick={fecharModal} />
      </Modal>
      <Modal
        className={styles.modal}
        isOpen={openModal}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {`Confirma a exclusão do(a) Dr.(a) ${medico[0].nom_medico} ? Essa operação não poderá ser desfeita!`}
        </p>
        <div className={styles.botoes}>
          <Button descricao='excluir médico' classe='submit' onClick={excluirMedico} />
          <Button descricao='cancelar' classe='cancel' onClick={fecharModalExc} />
        </div>
      </Modal>
      <div className={styles.divTitulo}>
        <TituloAssociado titulo={medico[0].nom_medico} crm={medico[0].num_crm} especialidade={medico[0].especialidades.replace(/\;/g, ' / ')} />
      </div>
      <div className={styles.divForm}>
        <MedicoForm initialValues={initialValues} />
        <div className={styles.botoes}>
          <Button descricao='salvar alterações' classe='submit' onClick={aprovaCadastro} />
          <Button descricao='excluir médico' classe='cancel' onClick={() => setOpenModal(true)} />
        </div>
      </div>
    </section>
  )
}

export default Medico
