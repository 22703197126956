// export const API_URL = 'https://amai-backend-87v1.vercel.app';
// export const API_URL = 'http://localhost:1337'
// export const API_URL = 'https://ec2-18-228-166-207.sa-east-1.compute.amazonaws.com:1338'
export const API_URL = 'https://amaionline.com.br:2053'

export function USER_LOGIN(body: Object) {
  return {
    url: API_URL + '/login',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

export function GET_LOGOS() {
  return {
    url: API_URL + '/logos',
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };
}

export function TOKEN_VALIDATE(token: string) {
  return {
    url: API_URL + '/token-validate',
    options: {
      method: 'GET',
      headers: {
        "Authorization": "Bearer " + token,
        'Content-Type': 'application/json'
      },
    },
  };
}

export function USER_GET(token: string) {
  return {
    url: API_URL + '/user',
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer' + token,
      },
    },
  };
}

export function FORGET_PASSWORD(cnpj: Object) {
  return {
    url: API_URL + '/esqueceu-senha',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cnpj)
    },
  };
}

export function RECOVER_PASSWORD(token: string | null, data: Object) {
  return {
    url: API_URL + "/recuperar-senha",
    options: {
      method: 'POST',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}

export function DELETE_CIRCULAR(token: string | null, data: Object) {
  return {
    url: API_URL + "/delete-circular-convenio",
    options: {
      method: 'DELETE',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}
export function DELETE_DOC_ESPECIALIDADE(token: string | null, data: Object) {
  return {
    url: API_URL + "/delete-doc-especialidade",
    options: {
      method: 'DELETE',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}

export function DELETE_MEDICO(token: string | null, data: Object) {
  return {
    url: API_URL + "/delete-medico-clinica",
    options: {
      method: 'DELETE',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}

export function DELETE_INFO(token: string | null, data: Object) {
  return {
    url: API_URL + "/delete-info-convenio",
    options: {
      method: 'DELETE',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}

export function DELETE_GUIA(token: string | null, data: Object) {
  return {
    url: API_URL + "/delete-guia-convenio",
    options: {
      method: 'DELETE',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}

export function REGISTER_NEW_ASS(data: Object) {
  return {
    url: API_URL + "/novo-associado",
    options: {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}

export function REGISTER_NEW_MED(data: Object, token: string | null) {
  return {
    url: API_URL + "/novo-medico",
    options: {
      method: 'POST',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}

export function CADASTRAR_CIRCULAR(data: Object) {
  return {
    url: API_URL + "/cadastrar-circular",
    options: {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}
export function CADASTRAR_DOC_ESP(data: Object) {
  return {
    url: API_URL + "/cadastrar-doc-especialidade",
    options: {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}
export function CADASTRAR_INFORMATIVO(data: Object) {
  return {
    url: API_URL + "/cadastrar-informativo",
    options: {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}
export function CADASTRAR_GUIA(data: Object) {
  return {
    url: API_URL + "/cadastrar-guia",
    options: {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}

export function GET_CIRCULARES_CONVENIO(data: object) {
  return {
    url: API_URL + "/obter-circulares-convenio",
    options: {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}
export function GET_INFOS_CONVENIO(data: object) {
  return {
    url: API_URL + "/obter-infos-convenio",
    options: {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}
export function GET_GUIAS_CONVENIO(data: object) {
  return {
    url: API_URL + "/obter-guias-convenio",
    options: {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}

export function NOVO_CONVENIO(data: object) {
  return {
    url: API_URL + "/cadastrar-convenio",
    options: {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}
export function ALTERAR_CONVENIO(data: object) {
  return {
    url: API_URL + "/alterar-convenio",
    options: {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}
export function NOVA_ESP(data: object) {
  return {
    url: API_URL + "/cadastrar-especialidade",
    options: {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}

export function REGISTER_LOGO(data: Object, token: string | null) {
  return {
    url: API_URL + "/cadastrar-logo",
    options: {
      method: 'POST',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}

export function GET_CONVENIOS(token: string | null) {
  return {
    url: API_URL + "/convenios",
    options: {
      method: 'GET',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
    },
  };
}

export function GET_CONVENIOS_GERENCIAL() {
  return {
    url: API_URL + "/convenios-gerencial",
    options: {
      method: 'GET',
      headers: {
        "Content-Type": "application/json"
      },
    },
  };
}

export function GET_AVISO() {
  return {
    url: API_URL + "/aviso",
    options: {
      method: 'GET',
      headers: {
        "Content-Type": "application/json"
      },
    },
  };
}
export function POST_AVISO(data: object) {
  return {
    url: API_URL + "/alterar-aviso",
    options: {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}

export function GET_CORPO_CLINICO(token: string | null) {
  return {
    url: API_URL + "/corpo-clinico",
    options: {
      method: 'GET',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
    },
  };

}
export function GET_ASSOCIADOS(token: string | null) {
  return {
    url: API_URL + "/associados-geral",
    options: {
      method: 'GET',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
    },
  };
}
export function GET_PROFISSIONAIS(token: string | null) {
  return {
    url: API_URL + "/profissionais-geral",
    options: {
      method: 'GET',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
    },
  };
}
export function GET_ESPECIALIDADES(token: string | null) {
  return {
    url: API_URL + "/especialidades",
    options: {
      method: 'GET',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
    },
  };
}
export function GET_DOCS_CLINICA(token: string | null, id_clinica: object) {
  return {
    url: API_URL + "/docs-clinica",
    options: {
      method: 'POST',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(id_clinica)
    },
  };
}
export function GET_DOCS_MEDICO(token: string | null, id_medico: object) {
  return {
    url: API_URL + "/docs-medico",
    options: {
      method: 'POST',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(id_medico)
    },
  };
}

export function APROVA_CADASTRO(token: string | null, data: object) {
  return {
    url: API_URL + "/aprovar-clinica",
    options: {
      method: 'POST',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}
export function ATUALIZAR_CADASTRO_CLINICA(token: string | null, data: object) {
  return {
    url: API_URL + "/atualizar-clinica",
    options: {
      method: 'POST',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}

export function APROVA_CADASTRO_MEDICO(token: string | null, data: object) {
  return {
    url: API_URL + "/aprovar-medico",
    options: {
      method: 'POST',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}
export function ALTERA_CADASTRO_MEDICO(token: string | null, data: object) {
  return {
    url: API_URL + "/alterar-medico",
    options: {
      method: 'POST',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    },
  };
}

export function GET_CLINICAS_PRE(token: string | null) {
  return {
    url: API_URL + "/clinicas-pre",
    options: {
      method: 'GET',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
    },
  };
}
export function GET_MEDICOS_PRE(token: string | null) {
  return {
    url: API_URL + "/medicos-pre",
    options: {
      method: 'GET',
      headers: {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
    },
  };
}


