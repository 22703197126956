import { ReactNode, useEffect } from 'react';
import { useUserContext } from '../../UserContext';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRouteGer = ({ children }: ProtectedRouteProps) => {
  const { loginGer } = useUserContext();

  return loginGer ? <>{children}</> : <Navigate to="/login-gerencial" />;
};

export default ProtectedRouteGer