import React, { useEffect, useState } from 'react'
import styles from './MedicoForm.module.css'
import Input from '../Forms/Input'
import useForm, { FormHook } from '../Hooks/useForm'
import { MedicoFormTypes, useMedicoContext } from '../../useMedicoContext';



interface InitialValuesTypes {
  initialValues: MedicoFormTypes;
}
const MedicoForm = ({ initialValues }: InitialValuesTypes) => {

  const { formData, setFormData } = useMedicoContext();

  useEffect(() => {
    setFormData(initialValues)
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (formData) setFormData({ ...formData, [name]: value });
  };

  return (
    <div className={styles.form}>
      <Input label='Nome Completo' type='text' name='nom_medico' onChange={handleInputChange} value={formData?.nom_medico} maxLength={50} />
      <Input label='Data nascimento' type='text' name='dat_nascimento' onChange={handleInputChange} value={formData?.dat_nascimento} classe='col2' maxLength={10} disabled={true}/>
      <Input label='Conselho' type='text' name='sgl_conselho' onChange={handleInputChange} value={formData?.sgl_conselho} classe='col2' maxLength={10} disabled={true}/>
      <Input label='Nº Conselho' type='text' name='num_crm' onChange={handleInputChange} value={formData?.num_crm} classe='col2' maxLength={10} disabled={true}/>
      <Input label='Especialidades (Se mais de uma especialidade, separá-las por ; )' type='text' name='especialidades' onChange={handleInputChange} value={formData?.especialidades} classe='col5' maxLength={300} disabled={true}/>
      <Input label='RG' type='text' name='num_identidade' onChange={handleInputChange} value={formData?.num_identidade} classe='col2' maxLength={30} disabled={true}/>
      <Input label='Emissor' type='text' name='nom_orgao_expedidor' onChange={handleInputChange} value={formData?.nom_orgao_expedidor} classe='col1' maxLength={20} disabled={true}/>
      <Input label='CPF' type='text' name='num_cpf' onChange={handleInputChange} value={formData?.num_cpf} classe='col2' disabled={true}/>
      <Input label='Naturalidade' type='text' name='naturalidade' onChange={handleInputChange} value={formData?.naturalidade} classe='col2' disabled={true}/>
      <Input label='UF' type='text' name='des_uf' onChange={handleInputChange} value={formData?.des_uf} classe='col1' maxLength={2} disabled={true}/>
      <Input label='Sexo' type='text' name='des_sexo_medico' onChange={handleInputChange} value={formData?.des_sexo_medico} classe='col1' maxLength={1} disabled={true}/>
      <Input label='Estado Civil' type='text' name='des_estado_civil' onChange={handleInputChange} value={formData?.des_estado_civil} classe='col2' maxLength={20} />
      <Input label='Endereço' type='text' name='des_endereco' onChange={handleInputChange} value={formData?.des_endereco} maxLength={150} />
      <Input label='Cidade' type='text' name='cidade' onChange={handleInputChange} value={formData?.cidade} classe='col3' maxLength={100} />
      <Input label='CEP' type='text' name='num_cep' onChange={handleInputChange} value={formData?.num_cep} classe='col2' maxLength={8} />
      <Input label='Telefone Fixo' type='text' name='tel_fixo' onChange={handleInputChange} value={formData?.tel_fixo} classe='col3' />
      <Input label='Celular' type='text' name='tel_celular' onChange={handleInputChange} value={formData?.tel_celular} classe='col3' />
      <Input label='E-mail' type='text' name='des_email' onChange={handleInputChange} value={formData?.des_email} classe='col5' maxLength={50} />
      <Input label='Contato Whatsapp' type='text' name='whatsapp' onChange={handleInputChange} value={formData?.whatsapp} classe='col3' maxLength={20} />
      <Input label='Instagram' type='text' name='instagram' onChange={handleInputChange} value={formData?.instagram} classe='col3' maxLength={300} />
      <Input label='Nome - Responsável Cadastro' type='text' name='resp_cadastro' onChange={handleInputChange} value={formData?.resp_cadastro} classe='col6' disabled={true}/>
      <Input label='Telefone - Responsável Cadastro' type='text' name='tel_resp_cadastro' onChange={handleInputChange} value={formData?.tel_resp_cadastro} classe='col5' disabled={true}/>
    </div>
  )
}

export default MedicoForm
