import React from 'react'
import styles from './LoginForm.module.css'
import Input from '../Forms/Input'
import useForm from '../Hooks/useForm'
import Button from '../Forms/Button'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useUserContext } from '../../UserContext'
import Head from '../Helper/Head'

const LoginForm = () => {
  const username = useForm('number')
  const password = useForm('')
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { userLogin, error, loading } = useUserContext()

  async function handleSubmit(e: any) {
    try {
      e.preventDefault();
      if (username.validate && password.validate && username.validate() && password.validate()) {
        userLogin(username.value, password.value);
      }
    } catch (e: any) {
      console.log(e.message)
    }
  }

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head title={pathname.includes('login-associado') ? 'Login - Associado' : 'Login - Gerencial'} description='Área do associado' />
      <form onSubmit={handleSubmit} className={styles.form}>
        <h3>Acesse a {pathname.includes('login-associado') ? 'área do associado' : 'área gerencial'}</h3>
        <Input label='Usuário' type='text' name='usuario' placeholder='Digite seu CNPJ ou CPF...' {...username} maxLength={14} />
        <Input label='Senha' type='password' name='senha' placeholder='********' {...password} />
        <Link to='/login-associado/esqueceu'>
          <p>Esqueceu sua senha?</p>
        </Link>
        <button type='submit' className={styles.fourth} disabled={loading}>{loading ? 'ACESSANDO...' : 'ACESSAR'}</button>
        <p className={styles.error}>{error}</p>
      </form>
    </section>
  )
}

export default LoginForm