import React from 'react'
import styles from './Formulario.module.css'
import Head from '../Helper/Head'
import TituloAssociado from '../Helper/TituloAssociado'
import { Link } from 'react-router-dom'

const FormularioGuias = () => {
  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Formulário de entrega de guias"
        description="Área do associado"
      />
      <TituloAssociado titulo='Formulário de entrega de guias' />
      <div className={styles.divFormulario}>
        <Link to='https://site-amai.s3.sa-east-1.amazonaws.com/formulario-entrega-guias/FORMUL%C3%81RIO+DE+ENTREGA+DE+GUIAS.doc' target='_blank'>Clique aqui para obter o formulário de entrega de guias</Link>
      </div>
    </section>
  )
}

export default FormularioGuias