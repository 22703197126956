import React, { useRef } from 'react'
import styles from './Principal.module.css'
import Button from '../Forms/Button'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'

const Principal = () => {
  const [ref, inView] = useInView({
    triggerOnce: true
  })

  return (
    <section className={styles.containerPrincipal} ref={ref}>
      <div className={styles.divPrincipal}>
        <div className={styles.divFiltro}>
          <div className={`${styles.divTextos} animeLeft`}>
            <h1>Seja bem vindo(a) à AMAI<span>.</span></h1>
            <p><span>Somos especialistas</span> em faturamento de contas médicas. <span>Associando-se à AMAI</span>, o profissional passa a fazer parte do corpo clínico e poderá <span>prestar atendimentos aos convênios contratados</span>.</p>
            <Link to='/novoassociado'>
              <Button classe='main' descricao='seja um associado'/>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Principal
