import React from 'react'
import styles from './VideosList.module.css'

const VideosList = () => {
  const url = `https://www.youtube.com/embed/YZ84iQrbYjw`;

  return (
      <iframe
        className={styles.video}
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube video player"
      ></iframe>
  )
}

export default VideosList