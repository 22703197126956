import React, { useState } from "react";
import styles from "./SelectSetor.module.css";
import setores from "./Setores";
import { useAssociadoContext } from "../../useAssociadoContext";

const SelectSetor = () => {
  const {destinatario, setDestinatario} = useAssociadoContext();

  const handleChangeSetores = (e: any) => {
    setDestinatario(e.target.value);
  };

  return (
    <div className={styles.divSetores}>
      {/* <label htmlFor="estados">Selecione a clínica</label> */}
      <label htmlFor="setores">Setor</label>
      <select
        name="setores"
        id="setores"
        onChange={handleChangeSetores}
        value={destinatario ? destinatario : ""}
      >
        <option value="">Selecione um setor...</option>
        {setores &&
          setores.map((setor, index) => (
            <option key={index} value={setor.email}>
              {setor.nome}
            </option>
        ))}
      </select>
    </div>
  );
};

export default SelectSetor;
