import AWS from 'aws-sdk';

// Configuração antiga
// AWS.config.update({
//   accessKeyId: 'AKIARQLRNUJH4D4SGM73',
//   secretAccessKey: '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',
//   region: 'sa-east-1',  // Substitua pela sua região
// });
AWS.config.update({
  accessKeyId: 'AKIA47CRXOMDYNSQZG4T',
  secretAccessKey: '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',
  region: 'sa-east-1',  // Substitua pela sua região
});

// Cria um novo objeto S3
const s3 = new AWS.S3();

// Define o nome do bucket e o prefixo (pasta)
const bucketName = 'dev-projetos';

async function ListFiles(prefix: string, index: string | undefined) {
  const params = {
    Bucket: 'site-amai',
    Prefix: prefix,
    StartAfter: index ? index : ''
  };

  try {
    const data = await s3.listObjectsV2(params).promise();
    // const files = data?.Contents?.map(file => file.Key);
    return data.Contents;
  } catch (error) {
    console.error("Erro ao listar arquivos:", error);
  }
}

export default ListFiles;