import React, { useEffect, useState } from 'react'
import styles from './NovaTabela.module.css'
import Head from '../Helper/Head'
import TituloAssociado from '../Helper/TituloAssociado'
import { Link, useNavigate } from 'react-router-dom'
import Anexos from '../NovoAssociado/Anexos'
import Button from '../Forms/Button'
import { useUserContext } from '../../UserContext'
import { useFileContext } from '../../useFileContext'
import UploadFiles from '../ServerSide/uploadToS3'
import Modal from 'react-modal'

const NovaTabela = () => {
  const { loading, setLoading, error, setError } = useUserContext();
  const [tipo, setTipo] = useState('');
  const { uploadedFiles = [], setUploadedFiles } = useFileContext();
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const navigate = useNavigate();

  const fecharModal = () => {
    setEmailSent(false);
    navigate(-1)
  }

  async function handleChangeTipo(e: any) {
    setTipo(e.target.value)
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    try {
      if (uploadedFiles.length === 1 && tipo) {
        setLoading(true)
        setError(null)
        const response = await UploadFiles(uploadedFiles, '', 'tabelas/' + tipo);
        setMensagem('Tabela cadastrada com sucesso')
        setEmailSent(true)
      } else {
        setError('Preencha todos os campos')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setUploadedFiles([]);
    setError(null)
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Nova Tabela"
        description="Área do associado"
      />
      <TituloAssociado titulo='Cadastrar tabela' />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
      <div className={styles.divNovaTabela}>
        <div className={styles.divCadastro}>
          <form onSubmit={handleSubmit}>
            <Anexos campo={0} label='Nova Tabela' nome='tabela' accept={{
              'application/msword': ['.doc'],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
              'application/pdf': ['.pdf'],
              'text/plain': ['.txt'],
            }} />
            <div className={styles.divTipo}>
              <label htmlFor="setores">Tipo de tabela</label>
              <select
                name="setores"
                id="setores"
                onChange={handleChangeTipo}
                value={tipo ? tipo : ""}
              >
                <option value="">Selecione o tipo da tabela...</option>
                <option value="amb">AMB/92 e de referências </option>
                <option value="cbhpm">CBHPM</option>
                <option value="portes">Tabela de Portes</option>
              </select>
            </div>
            <Button descricao={loading ? 'enviando...' : 'enviar'} classe='submit' disabled={loading} />
            <p className={styles.error}>{error}</p>
          </form>
        </div>
      </div>
    </section>
  )
}

export default NovaTabela