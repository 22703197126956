import React, { useEffect, useState } from 'react'
import styles from './NovoMedico.module.css'
import TituloAssociado from '../Helper/TituloAssociado'
import MedicoFormNew from './MedicoFormNew'
import Anexos from '../NovoAssociado/Anexos'
import Button from '../Forms/Button'
import { useUserContext } from '../../UserContext'
import { useFileContext } from '../../useFileContext'
import UploadFiles from '../ServerSide/uploadToS3'
import { format } from 'date-fns'
import { REGISTER_NEW_MED } from '../../api'
import { useMedicoContext } from '../../useMedicoContext'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom'

const NovoMedico = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const navigate = useNavigate()
  const { loading, setLoading, token, error, setError } = useUserContext();
  const { uploadedFiles = [], setUploadedFiles } = useFileContext();
  const { nome, dataNasc, conselho, numConselho, especialidades, rg, emissor, cpf, naturalidade, uf, sexo, estadoCivil, endereco, cidade, cep, telefoneFixo, celular, email, whatsapp, instagram, resp_cadastro, tel_resp_cadastro } = useMedicoContext();

  const resetForm = () => {
    nome.setValue('');
    dataNasc.setValue('');
    conselho.setValue('');
    numConselho.setValue('');
    especialidades.setValue('');
    rg.setValue('');
    emissor.setValue('');
    cpf.setValue('');
    naturalidade.setValue('');
    uf.setValue('');
    sexo.setValue('');
    estadoCivil.setValue('');
    endereco.setValue('');
    cidade.setValue('');
    cep.setValue('');
    telefoneFixo.setValue('');
    celular.setValue('');
    email.setValue('');
    whatsapp.setValue('');
    instagram.setValue('');
    resp_cadastro.setValue('');
    tel_resp_cadastro.setValue('');
  };

  function verificaArray(array: any) {
    return array.some((item: any) => item === undefined)
  }

  function fecharModal() {
    setEmailSent(false);
    navigate('/area-associado/corpo-clinico')
  }
  async function handleSubmit(e: any) {
    try {
      e.preventDefault();
      setError(null)
      if (nome.validate &&
        dataNasc.validate &&
        conselho.validate &&
        numConselho.validate &&
        especialidades.validate &&
        rg.validate &&
        emissor.validate &&
        cpf.validate &&
        naturalidade.validate &&
        uf.validate &&
        sexo.validate &&
        estadoCivil.validate &&
        endereco.validate &&
        cidade.validate &&
        cep.validate &&
        telefoneFixo.validate &&
        celular.validate &&
        email.validate &&
        whatsapp.validate &&
        instagram.validate &&
        resp_cadastro.validate &&
        tel_resp_cadastro.validate &&
        nome.validate() &&
        dataNasc.validate() &&
        conselho.validate() &&
        numConselho.validate() &&
        especialidades.validate() &&
        rg.validate() &&
        emissor.validate() &&
        cpf.validate() &&
        naturalidade.validate() &&
        uf.validate() &&
        sexo.validate() &&
        estadoCivil.validate() &&
        endereco.validate() &&
        cidade.validate() &&
        cep.validate() &&
        telefoneFixo.validate() &&
        celular.validate() &&
        email.validate() &&
        whatsapp.validate() &&
        instagram.validate() &&
        resp_cadastro.validate() &&
        tel_resp_cadastro.validate()) {
        if (!verificaArray(uploadedFiles) && uploadedFiles.length === 8) {
          const agora = new Date();
          const dataFormatada = format(agora, 'yyyy-MM-dd');
          const [dia, mes, ano] = dataNasc.value.split('/');
          const dataNascimento = ano + '-' + mes + '-' + dia;
          setLoading(true)
          const response = await UploadFiles(uploadedFiles, cpf.value + '-', 'docs-medicos')
          const docsResp = response?.map((item: any, index) => {
            return { url: item ? item.Location : '', tipo: index + 1 };
          })
          const dadosMed = {
            nom_medico: nome.value,
            dat_nascimento: dataNascimento,
            num_cpf: cpf.value.replace(/\D/g, ''),
            sgl_conselho: conselho.value,
            num_crm: numConselho.value,
            especialidades: especialidades.value,
            dat_atualizacao: dataFormatada,
            num_identidade: rg.value,
            nom_orgao_expedidor: emissor.value,
            naturalidade: naturalidade.value,
            des_uf: uf.value,
            des_sexo_medico: sexo.value,
            des_endereco: endereco.value,
            des_estado_civil: estadoCivil.value,
            cidade: cidade.value,
            num_cep: cep.value,
            tel_fixo: telefoneFixo.value,
            tel_celular: celular.value,
            des_email: email.value,
            whatsapp: whatsapp.value,
            instagram: instagram.value,
            resp_cadastro: resp_cadastro.value,
            tel_resp_cadastro: tel_resp_cadastro.value,
            docs: docsResp
          }
          const { url, options } = REGISTER_NEW_MED(dadosMed, token);
          const data = await fetch(url, options);
          const { message } = await data.json();
          if (!data.ok) throw new Error(message);
          setMensagem(message);
          setEmailSent(true)
        } else {
          setError('Anexe todos os arquivos solicitados')
        }
      } else {
        setError('Preencha todos os campos do formulário')
      }
    } catch (e: any) {
      console.log(e)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    resetForm();
    setUploadedFiles([])
  }, [])

  return (
    <section className={`${styles.containerMain} animeLeft`}>
      <TituloAssociado titulo='Cadastrar novo profissional' />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
      <div className={styles.divForm}>
        <h2 className={styles.subTitulo}>Dados Cadastrais</h2>
        <form onSubmit={handleSubmit}>
          <MedicoFormNew />
          <h2 className={styles.subTitulo}>Documentos</h2>
          <Anexos label='Documento de identidade' nome='rg' campo={0} accept={{
            'application/pdf': ['.pdf'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          }} />
          <Anexos label='CPF' nome='cpf' campo={1} accept={{
            'application/pdf': ['.pdf'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          }} />
          <Anexos label='Carteira do conselho' nome='carteiraConselho' campo={2} accept={{
            'application/pdf': ['.pdf'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          }} />
          <Anexos label='Diploma' nome='diploma' campo={3} accept={{
            'application/pdf': ['.pdf'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          }} />
          <Anexos label='Titulo de especialização ou Residência médica registrado no conselho ' nome='tituloEspecializacao' campo={4} accept={{
            'application/pdf': ['.pdf'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          }} />
          <Anexos label='Currículo' nome='curriculo' campo={5} accept={{
            'application/pdf': ['.pdf'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          }} />
          <Anexos label='Quitação com conselho' nome='quitacaoConselho' campo={6} accept={{
            'application/pdf': ['.pdf'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          }} />
          <Anexos label='Carta solicitando a inclusão do(a) profissional assinada de forma digital pelo responsável pela empresa ' nome='cartaInclusao' campo={7} accept={{
            'application/pdf': ['.pdf'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          }} />
          <Button descricao={loading ? 'enviando...' : 'enviar'} classe='submit' />
          <p className={styles.error}>{error}</p>
        </form>
      </div>
    </section>
  )
}

export default NovoMedico
