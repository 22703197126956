import React, { useRef } from 'react'
import styles from './ConveniosPage.module.css'
import Head from '../Helper/Head'
import Grade from '../Helper/Grade'
import associados from '../Helper/Associados'

const ConveniosPage = () => {

  const myElementRef = useRef(null);

  return (
  <>
    <Head title='Convênios' description='Todos os convênios da AMAI'/>
    <section className={`${styles.containerPrincipal} animeLeft`} ref={myElementRef}>
      <h1>Nossos Convênios</h1>
      <Grade data={associados}/>
    </section>
  </>
  )
}

export default ConveniosPage