import React, { useEffect, useState } from 'react'
import Principal from './Principal'
import Numeros from './Numeros'
import Circulares from './Circulares'
import Parceiros from './Parceiros'
import Convenios from './Convenios'
import Head from '../Helper/Head'
import Modal from 'react-modal';
import styles from './Home.module.css'
import { GET_AVISO } from '../../api'

const Home = () => {
  const el = null;
  const [tituloAviso, setTituloAviso] = useState();
  const [textoAviso, setTextoAviso] = useState();
  const [ativar, setAtivar] = useState(false);

  useEffect(() => {
    async function getAviso(){
      try {
        const {url, options} = GET_AVISO();
        const response = await fetch(url,options);
        const {titulo, ativo, texto} = await response.json();
        if(!response.ok) throw new Error('Erro ao buscar aviso')
        setTituloAviso(titulo);
        setTextoAviso(texto);
        setAtivar(ativo);
      } catch (error) {
        console.log(error.message)
      }
    }
    getAviso();
  },[])

  useEffect(() => {

  }, [textoAviso])

  return (
    <>  
      <Head title='Home' description='Página inicial do site'/>
      <Modal
          appElement={el}
          className={styles.modal}
          isOpen={ativar}
          // onRequestClose={() => setAviso(false)}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
        >
          <h2 style={styles.tituloAviso}>
            {tituloAviso}
          </h2>
          <pre style={styles.textoAviso}>
            {textoAviso}
          </pre>
          <button onClick={() => setAtivar(false)}>ciente</button>
      </Modal>
      <Principal/>
      <Numeros/>
      <Circulares/>
      <Parceiros/>
      <Convenios/>
    </>
  )
}

export default Home