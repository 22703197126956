import React from 'react'
import styles from './MedicoFormNew.module.css'
import Input from '../Forms/Input'
import useForm, { FormHook } from '../Hooks/useForm'
import { useMedicoContext } from '../../useMedicoContext'
import { useFileContext } from '../../useFileContext'

const MedicoFormNew = () => {
  const { nome, dataNasc, conselho, numConselho, especialidades, rg, emissor, cpf, naturalidade, uf, sexo, estadoCivil, endereco, cidade, cep, telefoneFixo, celular, email, whatsapp, instagram, resp_cadastro, tel_resp_cadastro } = useMedicoContext()

  return (
    <div className={styles.form}>
      <Input label='Nome Completo' type='text' name='nome' {...nome} maxLength={50} />
      <Input label='Data nascimento' type='text' name='dataNascimento' {...dataNasc} classe='col2' maxLength={10} />
      <Input label='Conselho' type='text' name='conselho' {...conselho} classe='col2' maxLength={10} />
      <Input label='Nº Conselho' type='text' name='numConselho' {...numConselho} classe='col2' maxLength={10} />
      <Input label='Especialidades (Se mais de uma especialidade, separá-las por ; )' type='text' name='especialidades' {...especialidades} classe='col5' maxLength={300} />
      <Input label='RG' type='text' name='rg' {...rg} classe='col2' maxLength={30} />
      <Input label='Emissor' type='text' name='emissor' {...emissor} classe='col1' maxLength={20} />
      <Input label='CPF' type='text' name='cpf' {...cpf} classe='col2' />
      <Input label='Naturalidade' type='text' name='naturalidade' {...naturalidade} classe='col2' />
      <Input label='UF' type='text' name='uf' {...uf} classe='col1' maxLength={2} />
      <Input label='Sexo' type='text' name='sexo' {...sexo} classe='col1' maxLength={1} />
      <Input label='Estado Civil' type='text' name='estadoCivil' {...estadoCivil} classe='col2' maxLength={20} />
      <Input label='Endereço' type='text' name='endereco' {...endereco} maxLength={150} />
      <Input label='Cidade' type='text' name='cidade' {...cidade} classe='col3' maxLength={100} />
      <Input label='CEP' type='text' name='cep' {...cep} classe='col2' maxLength={8} />
      <Input label='Telefone Fixo' type='text' name='telefoneFixo' {...telefoneFixo} classe='col3' />
      <Input label='Celular' type='text' name='celular' {...celular} classe='col3' />
      <Input label='E-mail' type='text' name='email' {...email} classe='col5' maxLength={50} />
      <Input label='Contato Whatsapp' type='text' name='whatsapp' {...whatsapp} classe='col3' maxLength={20} />
      <Input label='Instagram' type='text' name='instagram' {...instagram} classe='col3' maxLength={300} />
      <Input label='Nome - Responsável Cadastro' type='text' name='respCadastro' {...resp_cadastro} classe='col6' />
      <Input label='Telefone - Responsável Cadastro' type='text' name='telRespCadastro' {...tel_resp_cadastro} classe='col5' />
    </div>
  )
}

export default MedicoFormNew
