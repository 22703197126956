import React from 'react'
import styles from './Titulo.module.css'

interface TituloProps {
  titulo: string;
  especialidade?: string;
  crm?: string;
  usuario?: string;
  senha?: string;
  tipoAut?: string;
  dataCred?: string;
  horaCred?: string;
}

const TituloAssociado = ({ titulo, especialidade, crm, usuario, senha, tipoAut, dataCred, horaCred }: TituloProps) => {
  return (
    <div className={styles.divTitulo}>
      <h1 className={styles.titulo}>{titulo}</h1>
      <h3 className={styles.h3}>{especialidade?.replace(/;/g, ' / ')}</h3>
      <h3 className={styles.h3}>{crm}</h3>
      {usuario && <h3 className={styles.dadosAut}><span>Usuário: </span>{usuario}</h3>}
      {senha && <h3 className={styles.dadosAut}><span>Senha: </span>{senha}</h3>}
      {dataCred && horaCred && <h3 className={styles.dadosAut}><span>Data da última atualização: </span>{dataCred +  ' - ' + horaCred + 'h'}</h3>}
      {tipoAut && <h3 className={styles.dadosAut}><span>Tipo: </span>{tipoAut}</h3>}
    </div>

  )
}

export default TituloAssociado