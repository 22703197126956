import React, { MouseEventHandler } from 'react'
import './Button.css'

interface Button {
  descricao: string;
  classe: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

const Button = ({ descricao, classe, disabled, onClick }: Button) => {
  return (
    <button className={classe} disabled={disabled} onClick={onClick}>{descricao}</button>
  )
}

export default Button