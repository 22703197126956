import React, { useState } from 'react'
import styles from './EsqueceuSenha.module.css'
import Input from '../Forms/Input'
import useForm from '../Hooks/useForm'
import { useUserContext } from '../../UserContext'
import Head from '../Helper/Head'
import { FORGET_PASSWORD } from '../../api'
import Button from '../Forms/Button'
import Email from '../Email/Email'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-modal'

const EsqueceuSenha = () => {
  const cnpj = useForm('cnpj');
  const { error } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const navigate = useNavigate();

  function fecharModal() {
    setEmailSent(false);
    navigate('/login-associado')
  }

  async function handleSubmit(e: any) {
    try {
      e.preventDefault();
      if (cnpj.validate) {
        setLoading(true)
        const { url, options } = FORGET_PASSWORD({ username: cnpj.value.replace(/\D/g, '') })
        const response = await fetch(url, options);
        const { message, email, link } = await response.json()
        if (!response.ok) throw new Error(`${message}`)
        const enviado = await Email.enviarEmailRecSenha(email, cnpj.value, link)
        setMensagem(message)
        setEmailSent(enviado)
        setLoading(false)
      }
    } catch (e: any) {
      console.log(e.message)
      setLoading(false)
    }
  }

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head title='Recuperar senha' description='Área do associado' />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
      <form onSubmit={handleSubmit} className={styles.form}>
        <h3>Recuperação de senha</h3>
        <Input label='CNPJ' type='text' name='cnpj' placeholder='Digite seu cnpj para recuperar sua senha...' {...cnpj} />
        <Button classe='third' descricao={loading ? 'enviando...' : 'enviar'} disabled={loading} />
        <p className={styles.error}>{error}</p>
      </form>
    </section>
  )
}

export default EsqueceuSenha