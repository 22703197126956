import React, { useEffect } from 'react'
import styles from './AreaGerencial.module.css'
import { UserStorage, useUserContext } from '../../UserContext'
import { Route, Routes, useLocation } from 'react-router-dom'
import HomeGerencial from './HomeGerencial'
import NavGerencial from '../NavGerencial/NavGerencial'
import CircularesGer from './CircularesGer'
import AtendimentoRestritoGer from './AtendimentoRestritoGer'
import ConvenioGer from './ConvenioGer'
import FormularioGuiasGer from './FormularioGuiasGer'
import ConveniosGeralGer from './ConveniosGeralGer'
import ExtratosGer from './ExtratosGer'
import NovaCircular from './NovaCircular'
import CircularesConv from './CircularesConv'
import NovoConvenio from './NovoConvenio'
import ManuaisGer from './ManuaisGer'
import ManuaisLinksGer from './ManuaisLinksGer'
import NovoManual from './NovoManual'
import TabelasGer from './TabelasGer'
import NovaTabela from './NovaTabela'
import EspecialidadesGer from './EspecialidadesGer'
import Associados from './Associados'
import Clinica from './Clinica'
import PreClinicas from './PreClinicas'
import ClinicaPre from './ClinicaPre'
import PreMedicos from './PreMedicos'
import MedicoPre from './MedicoPre'
import EspecialidadeGer from './EspecialidadeGer'
import NovoDocEsp from './NovoDocEsp'
import NovaEspecialidade from './NovaEspecialidade'
import InfosConvGer from './InfosConvGer'
import NovoInformativo from './NovoInformativo'
import AlterarConv from './AlterarConv'
import GuiasConvGer from './GuiasConvGer'
import NovaGuia from './NovaGuia'
import { ReactComponent as Arrow } from "../../Assets/arrow.svg";
import Profissionais from './Profissionais'
import Medico from './Medico'


const AreaGerencial = () => {
  const { menuVisible, setMenuVisible } = useUserContext();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    setMenuVisible(false)
  }, [pathname])

  function toggleMenu() {
    setMenuVisible(!menuVisible);
  }
  return (
    <>
      <section className={styles.main}>
        <NavGerencial />
        <div className={styles.toggleButton} onClick={toggleMenu}>
          <Arrow className={menuVisible ? styles.svg : styles.svgReverse} />
        </div>
        <Routes>
          <Route path="/aviso" element={<HomeGerencial />} />
          <Route path="/circulares" element={<CircularesGer />} />
          <Route path="/circulares/nova-circular" element={<NovaCircular />} />
          <Route path="/convenios/atendimento-restrito" element={<AtendimentoRestritoGer />} />
          <Route path="/convenios/:id" element={<ConvenioGer />} />
          <Route path="/convenios/:id/alterar-convenio" element={<AlterarConv />} />
          <Route path="/convenios/:id/informativos" element={<InfosConvGer />} />
          <Route path="/convenios/:id/informativos/novo-informativo" element={<NovoInformativo />} />
          <Route path="/convenios/:id/guias" element={<GuiasConvGer />} />
          <Route path="/convenios/:id/guias/nova-guia" element={<NovaGuia />} />
          <Route path="/convenios/geral/novo-convenio" element={<NovoConvenio />} />
          <Route path="/convenios/atendimento-restrito/novo-convenio" element={<NovoConvenio />} />
          <Route path="/convenios/:id/circulares" element={<CircularesConv />} />
          <Route path="/convenios/formulario-entrega" element={<FormularioGuiasGer />} />
          <Route path="/convenios/geral" element={<ConveniosGeralGer />} />
          <Route path="/convenios/manuais" element={<ManuaisGer />} />
          <Route path="/convenios/manuais/novo-manual" element={<NovoManual />} />
          <Route path="/convenios/manuais/:param" element={<ManuaisLinksGer />} />
          <Route path="/convenios/especialidades" element={<EspecialidadesGer />} />
          <Route path="/convenios/especialidades/nova-especialidade" element={<NovaEspecialidade />} />
          <Route path="/convenios/especialidades/:id" element={<EspecialidadeGer />} />
          <Route path="/convenios/especialidades/:id/novo-doc-especialidade" element={<NovoDocEsp />} />
          <Route path="/convenios/tabelas" element={<TabelasGer />} />
          <Route path="/convenios/tabelas/nova-tabela" element={<NovaTabela />} />
          <Route path="/corpo-clinico/associados" element={<Associados />} />
          <Route path="/corpo-clinico/profissionais" element={<Profissionais />} />
          <Route path="/corpo-clinico/profissionais/:id" element={<Medico />} />
          <Route path="/corpo-clinico/associados/:id" element={<Clinica />} />
          <Route path="/corpo-clinico/nova-clinica-pre" element={<PreClinicas />} />
          <Route path="/corpo-clinico/nova-clinica-pre/:id" element={<ClinicaPre />} />
          <Route path="/corpo-clinico/novo-medico-pre" element={<PreMedicos />} />
          <Route path="/corpo-clinico/novo-medico-pre/:id" element={<MedicoPre />} />
          <Route path="/extratos" element={<ExtratosGer />} />
        </Routes>
      </section>
    </>
  )
}

export default AreaGerencial