import React, { useEffect, useMemo, useState } from 'react'
import styles from './Associados.module.css'
import Head from '../Helper/Head'
import TituloAssociado from '../Helper/TituloAssociado'
import { Link } from 'react-router-dom'
import { useMedicoContext } from '../../useMedicoContext'
import { useUserContext } from '../../UserContext'
import { GET_ASSOCIADOS } from '../../api'
import { useAssociadoContext } from '../../useAssociadoContext'

const Associados = () => {
  const { setClinica, clinicas = [], setClinicas } = useMedicoContext();
  const { loading, setLoading, tokenGer } = useUserContext();
  const { setFormDataClinica } = useAssociadoContext();
  const [pesquisar, setPesquisar] = React.useState('');
  const [pesquisando, setPesquisando] = React.useState(false)
  const [pesquisados, setPesquisados] = React.useState([])
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });


  function definirClinica(id: any): React.MouseEventHandler<HTMLAnchorElement> {
    return (event): void => {
      setClinica(clinicas.filter((item: any) => item.id_clinica === id));
    };
  }

  function handleChange({ target }: any) {
    setPesquisar(target.value.toUpperCase())
  }

  useEffect(() => {
    if (pesquisar && pesquisar.length > 2) {
      setPesquisando(true);
      setPesquisados(clinicas.filter((clinica: any) => {
        if ((clinica.nom_fantasia && clinica.nom_fantasia.toUpperCase().includes(pesquisar)) || clinica.num_cnpj.toUpperCase().includes(pesquisar) || clinica.nom_clinica.toUpperCase().includes(pesquisar))
          return clinica;
      }))
      // setPesquisados(pesquisados)
    } else {
      setPesquisando(false)
      setPesquisados([]);
    }

  }, [pesquisar])

  useEffect(() => {
    async function buscarClinicas() {
      try {
        setLoading(true)
        const { url, options } = GET_ASSOCIADOS(tokenGer);
        const response = await fetch(url, options);
        const data = await response.json();
        if (!response.ok) throw new Error(data.message);
        setClinicas(data);
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false);
      }
    }
    setClinica([])
    buscarClinicas();
    setFormDataClinica({})
  }, [])

  const sortedClinicas = useMemo(() => {
    return [...clinicas].sort((a, b) => {
      if (sortConfig.key) {
        let aValue = a[sortConfig.key] || '';
        let bValue = b[sortConfig.key] || '';
        if (sortConfig.key === 'nom_fantasia' || sortConfig.key === 'nom_clinica') {
          aValue = aValue.toUpperCase();
          bValue = bValue.toUpperCase();
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      }
      return 0;
    });
  }, [clinicas, sortConfig]);


  function requestSort(key: string) {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  }

  return (
    <section className={`${styles.containerMain} animeLeft`}>
      <Head
        title="Área Gerencial - Associados"
        description="Área gerencial"
      />
      <TituloAssociado titulo='Associados' />
      <input className={styles.procurar} type="text" placeholder='Pesquisar clínica' onChange={handleChange} value={pesquisar} />
      <div className={styles.divCorpoClinico}>
        <table className={styles.table}>
          <tr>
            <th onClick={() => requestSort('nom_clinica')}>Nome</th>
            <th onClick={() => requestSort('num_cnpj')}>CNPJ</th>
            <th onClick={() => requestSort('ind_status')}>Status</th>
          </tr>

          {!pesquisando ? sortedClinicas && sortedClinicas.map((item: any, index: any) => (
            <>
              <Link key={index} to={item.num_cnpj} onClick={definirClinica(item.id_clinica)}>
                <tr>
                  <td>{item.nom_clinica}</td>
                  <td>{item.num_cnpj}</td>
                  <td>{item.ind_status}</td>
                </tr>
              </Link>
              <span className={styles.sep}></span>
            </>

          )) : pesquisados && pesquisados.map((item: any, index: any) => (
            <>
              <Link key={index} to={item.num_cnpj} onClick={definirClinica(item.id_clinica)}>
                <tr>
                  <td>{item.nom_clinica}</td>
                  <td>{item.num_cnpj}</td>
                  <td>{item.ind_status}</td>
                </tr>
              </Link>
              <span className={styles.sep}></span>
            </>

          ))}
        </table>
      </div>
    </section>
  )
}

export default Associados