import React from 'react'
import styles from './Profissionais.module.css'
import BuscaProf from './BuscaProf'
import Resultado from './Resultado'
import Head from '../Helper/Head'

const Profissionais = () => {
  return (
    <section className={styles.containerPrincipal}>
      <Head title='Profissionais' description='Busque profissionais'/>
      <BuscaProf/>
      <Resultado/>
    </section >
  )
}

export default Profissionais