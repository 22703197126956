import React, { useEffect } from 'react';
import styles from './AtendimentoRestritoGer.module.css';
import TituloAssociado from '../Helper/TituloAssociado';
import Head from '../Helper/Head';
import { Link } from 'react-router-dom';
import { useAssociadoContext } from '../../useAssociadoContext';
import { GET_CONVENIOS, GET_CONVENIOS_GERENCIAL } from '../../api';
import { useUserContext } from '../../UserContext';
import Button from '../Forms/Button';

const AtendimentoRestritoGer = () => {
  const { convenios, setConvenios } = useAssociadoContext();
  const { token } = useUserContext();

  useEffect(() => {
    async function getConvenios() {
      const { url, options } = GET_CONVENIOS_GERENCIAL();
      const response = await fetch(url, options);
      const data = await response.json();
      if (!response.ok) throw new Error('Erro no get convenios.')
      setConvenios(data);
    }
    getConvenios()
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <TituloAssociado titulo="CONVÊNIOS COM ATENDIMENTO RESTRITO A ASSOCIADOS DO HOSPITAL ANCHIETA" />
      <Head
        title="Área do Associado - Atendimento Restrito Hosp. Anchieta"
        description="Área do associado"
      />
      <div className={styles.divConvenios}>
        {convenios && convenios?.map((item: any, index) => (
          item.restrito === true ?
            <Link to={`/area-gerencial/convenios/${item.id}`} key={index}>{item.nome}</Link> : null
        ))}
        <Link to='novo-convenio' className={styles.botao}>
          <Button descricao='+ novo convênio' classe='submit' />
        </Link>
      </div>
    </section>
  );
};

export default AtendimentoRestritoGer;
