import React, { useEffect } from 'react'
import styles from './AssociadoForm.module.css'
import Input from '../Forms/Input';
import useForm from '../Hooks/useForm';
import { useAssociadoContext } from '../../useAssociadoContext';

const AssociadoForm = () => {
  const { razaoSocial, nomeFantasia, especialidades, cnpj, inscricaoEst, numConselho, endereco, bairro, cidade, uf, cep, telefones, email, whatsapp, instagram, rt, cpf, numConselhoRT, emailRT, telefoneRT, nomeFat, telefoneFat, emailFat, resp_cadastro, tel_resp_cadastro } = useAssociadoContext()

  function handleSubmit(e: any) {
    e.preventDefault();
  }

  return (
    <div className={styles.divPrincipal}>
      <div onSubmit={handleSubmit} className={styles.form}>
        <Input
          name="razaoSocial"
          type="text"
          label="Razão Social"
          placeholder="Digite a razão social da empresa aqui..."
          maxLength={150}
          {...razaoSocial}
        />
        <Input
          name="nomeFantasia"
          type="text"
          label="Nome Fantasia"
          placeholder="Digite o nome fantasia da empresa aqui..."
          maxLength={50}
          {...nomeFantasia}
        />
        <Input
          name="especialidades"
          type="text"
          label="Especialidade(s) (Em caso de mais de uma especialidade separá-las por ;  Ex: Cardiologia; Clínica Médica)"
          placeholder="Especialidade(s) que a clínica atende"
          maxLength={500}
          {...especialidades}
        />
        <Input
          name="cnpj"
          type="text"
          label="CNPJ"
          placeholder="00.000.000/0000-00"
          maxLength={18}
          classe='col4'
          {...cnpj}
        />
        <Input
          name="inscricaoEstadual"
          type="text"
          label="Inscrição Estadual"
          placeholder="Digite apenas números..."
          maxLength={13}
          classe='col4'
          {...inscricaoEst}
        />
        <Input
          name="numeroConselhoClinica"
          type="text"
          label="Nº Registro Conselho"
          placeholder="Digite o número do conselho da clínica..."
          maxLength={100}
          classe='col4'
          {...numConselho}
        />
        <Input
          name="endereco"
          type="text"
          label="Endereço"
          placeholder="Digite o endereço da clínica aqui..."
          maxLength={150}
          {...endereco}
        />
        <Input
          name="bairro"
          type="text"
          label="Bairro"
          placeholder="Digite o bairro..."
          maxLength={30}
          classe='col4'
          {...bairro}
        />
        <Input
          name="cidade"
          type="text"
          label="Cidade"
          placeholder="Digite a cidade..."
          maxLength={100}
          classe='col4'
          {...cidade}
        />
        <Input
          name="uf"
          type="text"
          label="UF"
          placeholder="Estado..."
          maxLength={2}
          classe='col2'
          {...uf}
        />
        <Input
          name="cep"
          type="text"
          label="CEP"
          placeholder="00.000-000"
          maxLength={8}
          classe='col2'
          {...cep}
        />
        <Input
          name="telefonesClinica"
          type="text"
          label="Telefone(s) Clínica (Em caso de mais de um telefone separá-los por ; Ex: (61) 99999-9999; (61) 3333-3333)"
          placeholder="Digite o(s) telefone(s) aqui..."
          maxLength={200}
          {...telefones}
        />
        <Input
          name="emailsClínica"
          type="text"
          label="E-mail(s) Clínica (Em caso de mais de um email separá-los por ; )"
          placeholder="Digite o(s) e-mail(s) aqui..."
          maxLength={500}
          {...email}
        />
        <Input
          name="whatsapp"
          type="text"
          label="Whatsapp (Clínica)"
          placeholder="(99) 99999-9999"
          maxLength={15}
          classe='col6'
          {...whatsapp}
        />
        <Input
          name="instagram"
          type="text"
          label="Instragram (Clínica)"
          placeholder="@perfil"
          maxLength={100}
          classe='col6'
          {...instagram}
        />
        <Input
          name="responsavelTecnico"
          type="text"
          label="Responsável Técnico"
          placeholder="Digite o nome do RT..."
          maxLength={300}
          classe='col5'
          {...rt}
        />
        <Input
          name="cpf"
          type="text"
          label="CPF"
          placeholder="000.000.000-00"
          maxLength={14}
          classe='col4'
          {...cpf}
        />
        <Input
          name="numeroRegistroConselhoRT"
          type="text"
          label="Nº Registro Conselho"
          placeholder="Conselho do RT..."
          maxLength={20}
          classe='col3'
          {...numConselhoRT}
        />
        <Input
          name="emailRT"
          type="mail"
          label="E-mail Responsável Técnico"
          placeholder="email@contato.com"
          classe="col8"
          maxLength={200}
          {...emailRT}
        />
        <Input
          name="telefoneRT"
          type="text"
          label="Telefone Responsável Técnico"
          placeholder=""
          maxLength={15}
          classe='col4'
          {...telefoneRT}
        />
        <Input
          name="nomeFat"
          type="text"
          label="Nome - Faturista"
          placeholder="Nome do(a) faturista..."
          maxLength={200}
          classe='col4'
          {...nomeFat}
        />
        <Input
          name="telefoneFat"
          type="text"
          label="Telefone - Faturista"
          placeholder="Telefone do(a) faturista..."
          maxLength={15}
          classe='col3'
          {...telefoneFat}
        />
        <Input
          name="emailFat"
          type="text"
          label="E-mail - Faturista"
          placeholder="E-mail do(a) faturista..."
          maxLength={200}
          classe='col5'
          {...emailFat}
        />
        <Input
          name="respCadastro"
          type="text"
          label="Nome - Responsável Cadastro"
          placeholder="Digite o nome do responsável pelo cadastro..."
          maxLength={200}
          classe='col6'
          {...resp_cadastro}
        />
        <Input
          name="telRespCadastro"
          type="text"
          label="Telefone - Responsável Cadastro"
          placeholder="Digite o telefone do responsável pelo cadastro..."
          maxLength={200}
          classe='col6'
          {...tel_resp_cadastro}
        />
      </div>
    </div>
  )
}

export default AssociadoForm
