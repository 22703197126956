import React, { useEffect, useState } from 'react'
import styles from './InfosConvGer.module.css'
import Head from '../Helper/Head'
import TituloAssociado from '../Helper/TituloAssociado'
import { useAssociadoContext } from '../../useAssociadoContext'
import { useUserContext } from '../../UserContext'
import { DELETE_INFO, GET_CIRCULARES_CONVENIO, GET_INFOS_CONVENIO } from '../../api'
import SubTituloAss from '../Helper/SubTituloAss'
import { Link } from 'react-router-dom'
import Button from '../Forms/Button'
import Modal from 'react-modal'
import { ReactComponent as Delete } from "../../Assets/delete.svg";

const InfosConvGer = () => {
  const { convenio } = useAssociadoContext()
  const [infos, setInfos] = useState([])
  const { loading, setLoading, tokenGer } = useUserContext();
  const [mensagem, setMensagem] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [idInfo, setIdInfo] = useState(0);

  async function getCirculares() {
    try {
      setLoading(true)
      const { url, options } = GET_INFOS_CONVENIO({ id_convenio: convenio[0].id });
      const response = await fetch(url, options);
      const json = await response.json();
      if (!response.ok) throw new Error(json.message)
      setInfos(json);
    } catch (error: any) {
      console.log(error.message)
    } finally {
      setLoading(false)
    }
  }
  async function deleteInfo() {
    try {
      setLoading(true)
      setModalOpen(false)
      const { url, options } = DELETE_INFO(tokenGer, { id: idInfo })
      const response = await fetch(url, options);
      const { message } = await response.json();
      setMensagem(message)
      if (!response.ok) throw new Error(message);
      getCirculares();
      setModalOpen2(true)
    } catch (error) {
      console.log('Erro no deleteInfo: ' + error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  function fecharModal() {
    setModalOpen(false)
    setModalOpen2(false)
  }

  function abrirModal(id: any) {
    setModalOpen(true)
    setIdInfo(id)
    setMensagem('Confirma a EXCLUSÃO do informativo? Essa operação não poderá ser desfeita!')
  }


  useEffect(() => {
    getCirculares();
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Modal
        className={styles.modal}
        isOpen={modalOpen}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <div className={styles.botoes}>
          <Button descricao='excluir' classe='submit' onClick={deleteInfo} />
          <Button descricao='cancelar' classe='cancel' onClick={fecharModal} />
        </div>

      </Modal>
      <Modal
        className={styles.modal}
        isOpen={modalOpen2}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <Button descricao='fechar' classe='main' onClick={fecharModal} />
      </Modal>
      <Head
        title={"Área Gerencial - Informativos " + convenio[0].nome}
        description="Área Gerencial"
      />
      <div className={styles.divTitulo}>
        <TituloAssociado titulo={'informativos - ' + convenio[0].nome} />
        <Link to='novo-informativo'>
          <Button descricao='+ cadastrar informativo' classe='submit' />
        </Link>
      </div>
      <div className={styles.divCirculares}>
        {infos.length > 0 ? infos?.map((item: any, index) => (
          <div className={styles.info} key={index}>
            <Link to={item.url} target='blank' >{item.nome_arquivo.replace('informativos/', '').replace('.pdf', '')}</Link>
            <Delete className={styles.svg} onClick={() => abrirModal(item.id)} title='Excluir' />
          </div>

        )) : <p className={styles.alerta}>Não existem informativos para esse convênio ainda.</p>}
        { }
      </div>
    </section>
  )
}

export default InfosConvGer