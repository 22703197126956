import React, { MouseEventHandler, useEffect } from 'react'
import styles from './CorpoClinico.module.css'
import TituloAssociado from '../Helper/TituloAssociado'
import { useAssociadoContext } from '../../useAssociadoContext'
import { useUserContext } from '../../UserContext'
import { GET_CORPO_CLINICO } from '../../api'
import { Link } from 'react-router-dom'
import Head from '../Helper/Head'
import Button from '../Forms/Button'
import { useMedicoContext } from '../../useMedicoContext'

const CorpoClinico = () => {
  const { medicos, medico, setMedico, setMedicos, setFormData } = useMedicoContext();
  const { loading, setLoading, token } = useUserContext();

  function definirMedico(id: any): React.MouseEventHandler<HTMLAnchorElement> {
    return (event): void => {
      setMedico(medicos.filter((item: any) => item.id_medico === id));
    };
  }

  useEffect(() => {
    async function buscarMedicos() {
      try {
        setLoading(true)
        const { url, options } = GET_CORPO_CLINICO(token);
        const response = await fetch(url, options);
        const data = await response.json();
        if (!response.ok) throw new Error(data.message);
        setMedicos(data);
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false);
      }
    }
    setMedico({})
    setFormData(undefined)
    buscarMedicos();
  }, [])

  return (
    <section className={`${styles.containerMain} animeLeft`}>
      <Head
        title="Área do Associado - Corpo Clínico"
        description="Área do associado"
      />
      <div className={styles.divTitulo}>
        <TituloAssociado titulo='corpo clínico' />
        <Link to='novo-medico'>
          <Button descricao='+ novo profissional' classe='submit' />
        </Link>
      </div>
      <div className={styles.divCorpoClinico}>
        <table className={styles.table}>
          <tr>
            <th>Nome</th>
            <th>Nº Conselho</th>
            <th>Especialidade</th>
          </tr>

          {medicos && medicos.map((item: any, index: any) => (
            <>
              <Link to={item.nom_medico} onClick={definirMedico(item.id_medico)}>
                <tr key={index}>
                  <td >{item.nom_medico}</td>
                  <td >{(item.sgl_convenio ? item.sgl_convenio + ' - ' : '') + item.num_crm}</td>
                  <td >{item.especialidades.replace(/\;/g, ' / ')}</td>
                </tr>
              </Link>
              <span className={styles.sep}></span>
            </>

          ))}
        </table>
      </div>
    </section>
  )
}

export default CorpoClinico