import React, { useEffect, useRef, useState } from 'react'
import styles from './FaleConosco.module.css'
import Input from '../Forms/Input';
import { hasFormSubmit } from '@testing-library/user-event/dist/utils';
import useForm from '../Hooks/useForm';
import Button from '../Forms/Button';
import SelectSetor from '../Forms/SelectSetor';
import Mapa from './Mapa';
import marcador from '../../Assets/marcador.svg'
import horario from '../../Assets/horario.svg'
import { APIProvider } from '@vis.gl/react-google-maps';
import Head from '../Helper/Head';
import { useInView } from 'react-intersection-observer';
import enviarEmail from "../Email/Email";
import { useAssociadoContext } from '../../useAssociadoContext';
import Modal from "react-modal";

const FaleConosco = () => {
  const nome = useForm('');
  const cnpj = useForm("cnpj");
  const email = useForm("email");
  const telefone = useForm("telefone");
  const { destinatario, setDestinatario} = useAssociadoContext();
  const [mensagem, setMensagem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true
  })
  const [ref2, inView2] = useInView({
    triggerOnce: true
  })
  const [ref3, inView3] = useInView({
    triggerOnce: true
  })
  
  const [ref4, inView4] = useInView({
    triggerOnce: true
  })
  
  function handleMessage(event: any) {
    setMensagem(event.target.value);
  }

  async function handleSubmit(e: any){
    e.preventDefault();
    if (email?.validate?.() && nome?.validate?.() && telefone?.validate?.()) {
      setLoading(true);
      const enviou = await enviarEmail.enviarEmailSuporte(
        `<br/><br/><p>${mensagem}</p>`,
        nome.value,
        telefone.value,
        email.value,
        "",
        destinatario,
        cnpj.value
      );
      if (enviou) {
        setLoading(false);
        setEmailSent(true);
      }
    }
  }

  return (
    <section className={styles.containerPrincipal}>
      <Head title='Fale Conosco' description='Página de contato do site'/>
      <div className={`${styles.divIntro} animeLeft`}>
        <div className={`${styles.textos} animeLeft`}>
          <h1>Fale Conosco</h1>
          <p>Envie-nos uma mensagem selecionando o setor específico com o qual deseja falar que entraremos em conato com você.</p>
        </div>
        <div className={`${styles.divForm} animeLeft`}>
          <Modal
            className={styles.modal}
            isOpen={emailSent}
            onRequestClose={() => setEmailSent(false)}
            ariaHideApp={false}
          >
            <h2>
              Recebemos sua mensagem<span>!</span>
            </h2>
            <p>
              Entratemos em contato com você <span>em breve.</span>
            </p>
            <button onClick={() => setEmailSent(false)}>Fechar</button>
          </Modal>
          <form className={styles.form} onSubmit={handleSubmit}>
            <h2>Envie-nos uma mensagem</h2>
            <Input
                name="nome"
                type="text"
                label="Nome"
                placeholder="Seu nome"
                maxLength={300}
                {...nome}
              />
            <Input
                name="cnpj"
                type="text"
                label="CNPJ"
                placeholder="00.000.000/0000-00"
                maxLength={18}
                {...cnpj}
              />
            <Input
                name="email"
                type="text"
                label="E-mail"
                placeholder="email@email.com"
                maxLength={200}
                {...email}
              />
            <Input
                name="telefone"
                type="text"
                label="Telefone"
                placeholder="(61) 99999-9999"
                maxLength={15}
                {...telefone}
              />
              <SelectSetor />
              <div className={styles.mensagem}>
                <label htmlFor="mensagem">Mensagem</label>
                <textarea
                  id="mensagem"
                  placeholder="Em que podemos ajudar?"
                  onChange={handleMessage}
                  value={mensagem ? mensagem : ""}
                />
              </div>
              <Button classe='third' descricao={loading ? 'enviando...' : 'enviar mensagem'} disabled={loading}/>
          </form>
        </div>
      </div>
      <div className={styles.divContatos}>
        <h1>Informações de Contato</h1>
        <APIProvider apiKey={process.env.REACT_APP_MAPS_API_KEY ? process.env.REACT_APP_MAPS_API_KEY : ''}>
        <div className={`${inView ? `${styles.divContato} animeLeft` : `${styles.divContato}`} `} ref={ref}>
          <div className={styles.divMapa}>
            <Mapa lat={-15.841362199999983} lng={-48.02330109999999} idMapa={process.env.REACT_APP_ID_KEY_MAP}/>
          </div>
          <div className={styles.divDados}>
            <div className={styles.tituloDados}>
              <img src={marcador} alt="marcador colorido do texto" />
              <h1>águas claras shopping</h1>
            </div>
            <div className={styles.enderecoDados}>
              <p>Avenida Araucárias lote 1835 Sala 407 4º Andar</p>
              <p>Águas Claras-DF</p>
              <p>(61) 3351-6245</p>
            </div>
            <div className={styles.horaFunc}>
              <img src={horario} alt="marcador do horário de funcionamento colorido" />
              <p>08h-17h de seg. à sex.</p>
            </div>
          </div>
        </div>
        <div className={`${inView2 ? `${styles.divContato} animeRight` : `${styles.divContato}`} `} ref={ref2}>
          <div className={styles.divMapa}>
            <Mapa lat={-15.823730585245002} lng={-48.066621313074094} idMapa={process.env.REACT_APP_ID_KEY_MAP} />
          </div>
          <div className={styles.divDados}>
            <div className={styles.tituloDados}>
              <img src={marcador} alt="marcador colorido do texto" />
              <h1>Hospital Anchieta</h1>
            </div>
            <div className={styles.enderecoDados}>
              <p>Setor “C” Norte Área Especial 8/9/10 Térreo Bloco B Loja 8-A</p>
              <p>Taguatinga-DF</p>
              <p>(61) 3351-6245</p>
            </div>
            <div className={styles.horaFunc}>
              <img src={horario} alt="marcador do horário de funcionamento colorido" />
              <p>08h-12h e 13-17h de seg. à sex.</p>
            </div>
          </div>
        </div>
        <div className={`${inView3 ? `${styles.divContato} animeLeft` : `${styles.divContato}`} `} ref={ref3}>
          <div className={styles.divMapa}>
            <Mapa lat={-15.811883392932508} lng={-47.91314803184181} idMapa={process.env.REACT_APP_ID_KEY_MAP} />
          </div>
          <div className={styles.divDados}>
            <div className={styles.tituloDados}>
              <img src={marcador} alt="marcador colorido do texto" />
              <h1>Mix Park Sul</h1>
            </div>
            <div className={styles.enderecoDados}>
              <p>SGAS 910 Conjunto B Bloco F Sala 203</p>
              <p>Asa Sul-DF</p>
              <p>(61) 3351-6245</p>
            </div>
            <div className={styles.horaFunc}>
              <img src={horario} alt="marcador do horário de funcionamento colorido" />
              <p>08h-17h de seg. à sex.</p>
            </div>
          </div>
        </div>
        <div className={`${inView4 ? `${styles.divContato} animeRight` : `${styles.divContato}`} `} ref={ref4}>
          <div className={styles.divMapa}>
            <Mapa lat={-15.822522810809842} lng={-48.06621824256096} idMapa={process.env.REACT_APP_ID_KEY_MAP} />
          </div>
          <div className={styles.divDados}>
            <div className={styles.tituloDados}>
              <img src={marcador} alt="marcador colorido do texto" />
              <h1>PRIME EXCELÊNCIA MÉDICA</h1>
            </div>
            <div className={styles.enderecoDados}>
              <p>Área Especial 1 Setor “C” Norte Lotes 1 a 12 Bloco C Loja 10 Térreo</p>
              <p>Taguatinga-DF</p>
              <p>(61) 3351-6245</p>
            </div>
            <div className={styles.horaFunc}>
              <img src={horario} alt="marcador do horário de funcionamento colorido" />
              <p>08h-12h e 13-17h de seg. à sex.</p>
            </div>
          </div>
        </div>
        </APIProvider>
      </div>
    </section>
  )
}

export default FaleConosco