import React, { useState } from 'react'
import Head from '../Helper/Head'
import useForm from '../Hooks/useForm';
import styles from './RecuperaraSenha.module.css'
import Input from '../Forms/Input';
import { useUserContext } from '../../UserContext';
import { RECOVER_PASSWORD } from '../../api';
import { useNavigate } from 'react-router-dom';
import Button from '../Forms/Button';
import Modal from 'react-modal';

const RecuperaraSenha = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const token: string | null = params.get("token");
  const novaSenha = useForm('');
  const confirmSenha = useForm('');
  const { error, setError } = useUserContext();
  const [loading, setLoading] = useState(false)
  const [mensagem, setMensagem] = useState(null)
  const [emailSent, setEmailSent] = useState(false);
  const [requirementsMet, setRequirementsMet] = React.useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
  });

  const checkRequirements = (value: string) => {
    const lengthRegex = /.{8,}/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /[0-9]/;

    setRequirementsMet({
      length: lengthRegex.test(value),
      uppercase: uppercaseRegex.test(value),
      lowercase: lowercaseRegex.test(value),
      number: numberRegex.test(value),
    });
  };

  function fecharModal() {
    setEmailSent(false);
    navigate('/login-associado')
  }

  async function handleSubmit(e: any) {
    try {
      e.preventDefault();
      if (
        requirementsMet.length &&
        requirementsMet.uppercase &&
        requirementsMet.lowercase &&
        requirementsMet.number
      ) {
        if (novaSenha.value === confirmSenha.value) {
          setLoading(true)
          const { url, options } = RECOVER_PASSWORD(token, { password: novaSenha.value })
          const response = await fetch(url, options);
          const { message } = await response.json();
          if (!response.ok) throw new Error(await message);
          setError(null)
          setMensagem(message);
          setEmailSent(true)
          setLoading(false)
        } if (novaSenha.value !== confirmSenha.value) {
          setError('As senhas não conferem.')
        }
      }
      if (!requirementsMet.length ||
        !requirementsMet.uppercase ||
        !requirementsMet.lowercase ||
        !requirementsMet.number) {
        setError('A senha deve obedecer todos os requisitos.')
      }
    } catch (e: any) {
      console.log(e.message)
      setLoading(false)
    }
  }

  React.useEffect(() => {
    checkRequirements(novaSenha.value);
  }, [novaSenha.value]);

  React.useEffect(() => {
    setError(null)
  }, [])


  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head title='Recuperação de Senha' description='Área do associado' />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
      <form onSubmit={handleSubmit} className={styles.form}>
        <h3>Acesse a área do associado</h3>
        <Input label='Nova senha' type='password' name='novasenha' placeholder='********' {...novaSenha} />
        <Input label='Confirmar senha' type='password' name='confirmarsenha' placeholder='********' {...confirmSenha} />
        <ul>
          <li className={requirementsMet.length ? styles.verde : styles.vermelho}>
            Deve conter pelo menos 8 caracteres
          </li>
          <li className={requirementsMet.uppercase ? styles.verde : styles.vermelho}>
            Deve conter pelo menos uma letra maiúscula
          </li>
          <li className={requirementsMet.lowercase ? styles.verde : styles.vermelho}>
            Deve conter pelo menos uma letra minúscula
          </li>
          <li className={requirementsMet.number ? styles.verde : styles.vermelho}>
            Deve conter pelo menos um número
          </li>
        </ul>
        <Button descricao={loading ? 'atualizando...' : 'atualizar senha'} classe='fourth' disabled={loading} />
        <p className={styles.error}>{error}</p>
      </form>
    </section>
  )
}

export default RecuperaraSenha