import React, { useEffect, useState } from 'react'
import styles from './CircularesGer.module.css'
import TituloAssociado from '../Helper/TituloAssociado'
import { useUserContext } from '../../UserContext'
import { Link } from 'react-router-dom'
import SubTituloAss from '../Helper/SubTituloAss'
import Head from '../Helper/Head'
import ListFiles from '../ServerSide/findFromS3'
import Button from '../Forms/Button'
interface FileProps {
  Key: string;
}

const CircularesGer = () => {
  const { circulares, setCirculares } = useUserContext();
  const [anos, setAnos] = useState<any>()
  const [circularesFilt, setCircularesFilt] = useState<FileProps[]>();

  useEffect(() => {
    async function getYears(circular: any) {
      if (circular) {
        const mappedFiles = circular.map((file: any) => {
          return { Key: file.Key }; // Mapeando os objetos para o formato FileProps
        });
        setCirculares(mappedFiles);
        let anos: any[] = []
        mappedFiles?.map((item: any) => {
          const ano = parseInt(item.Key.substr(14, 4));
          if (!anos.includes(ano) && ano) {
            anos.push(ano);
          }
        })
        anos.sort((a, b) => b - a)
        setAnos(anos)
        return anos;
      }
    }
    async function fetchArquivos() {
      try {
        let filesFim: any = [];
        let files: any = await ListFiles('circulares/', '');
        while (files && files?.length >= 1000) {
          filesFim.push(...files);
          const lastFile: any = files[files.length - 1].Key;
          files = await ListFiles('circulares/', lastFile)
          filesFim.push(...await files)
        }
        if (filesFim) {
          const mappedFiles = filesFim.map((file: any) => {
            return { Key: file.Key }; // Mapeando os objetos para o formato FileProps
          });
          setCirculares(/* prevState => [...prevState ?? [], ... */mappedFiles/* ] */);

          mappedFiles.sort((a: any, b: any) => {
            // Extrai os anos dos nomes dos arquivos (assumindo que o ano está na posição 6 e 7)
            const yearA = parseInt(a.Key.substr(14, 4));
            const yearB = parseInt(b.Key.substr(14, 4));

            // Extrai os números dos nomes dos arquivos (assumindo que o número começa na posição 10)
            const numberA = parseInt(a.Key.substr(11, 3));
            const numberB = parseInt(b.Key.substr(11, 3));

            // Compara primeiro pelo ano
            if (yearA !== yearB) {
              return yearB - yearA; // Ordem decrescente (do mais recente para o mais antigo)
            }
            // Se os anos forem iguais, compara pelo número
            return numberB - numberA; // Ordem crescente (do menor para o maior)
          });
          setCircularesFilt(prevState => [...prevState ?? [], ...mappedFiles]);
          getYears(mappedFiles)
        }
      } catch (error) {
        console.error("Erro ao buscar arquivos:", error);
      }
    }
    fetchArquivos();
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <div className={styles.divTitulo}>
        <TituloAssociado titulo='Circulares' />
        <Link to='nova-circular'>
          <Button descricao='+ cadastrar circular' classe='submit' />
        </Link>
      </div>
      <Head title='Área Gerencial - Circulares' description='Área Gerencial' />
      <div className={styles.divCirculares}>
        {anos && anos?.map((ano: any, index: any) => (
          <div className={styles.divAno} key={index}>
            <SubTituloAss subTitulo={'circulares - ano ' + ano} />
            {circulares?.map((item, index) => (

              index !== 0 && new RegExp("^.{14}" + ano).test(item.Key) ? <Link to={'https://site-amai.s3.sa-east-1.amazonaws.com/' + item.Key} target='blank' key={index}>{'Nº ' + item.Key.replace('circulares/', '').replace('.pdf', '').replace(/\+/g, ' ')}</Link> : ""

            ))}
          </div>
        ))}

      </div>
    </section>
  )
}

export default CircularesGer