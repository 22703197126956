import axios from 'axios';
import { API_KEY } from '../../../src/config.js';

const apiKey = atob(API_KEY);
const enviarEmailSuporte = async (
  mensagem,
  nome,
  telefone,
  email,
  modulos,
  destinatario,
  cnpj,
) => {
  let sentEmail = false;
  await axios
    .post(
      'https://api.sendinblue.com/v3/smtp/email',
      {
        sender: { name: 'Site - Fale Conosco', email: 'luismqdf@hotmail.com' },
        type: 'classic',
        to: [{ email: destinatario }],
        subject: `${'Fale Conosco: ' + nome + ' - ' + cnpj}`,
        htmlContent: `<html><body><div><p>Nome: ${nome}</p><p>Telefone: ${telefone}</p><p>Email: ${email}</p><p>CNPJ: ${cnpj}</p><p>${mensagem}</p></div></body></html>`,
      },
      {
        headers: {
          'api-key': apiKey,
          'Content-Type': 'application/json',
        },
      },
    )
    .then((response) => {
      if (response.status === 201) {
        sentEmail = true;
      }
    })
    .catch((error) => {
      console.error('Erro ao enviar e-mail', error);
    });
  return sentEmail;
};

const enviarEmailRecSenha = async (email, cnpj, link) => {
  let headersList = {
    'api-key': apiKey,
    'Content-Type': 'application/json',
  };
  const emails = await email.map((item) => {
    return {email: item}
  })

  let bodyContent = JSON.stringify({
    sender: {
      name: 'Amai',
      email: 'luismqdf@gmail.com',
    },
    to: emails,
    templateId: 1,
    params: {
      LINK: link,
      CNPJ: cnpj,
    },
  });

  let response = await fetch('https://api.sendinblue.com/v3/smtp/email', {
    method: 'POST',
    body: bodyContent,
    headers: headersList,
  });
  console.log(response)
  if (response.status === 201) return true;
  else return false;
};
const enviarEmailAprovaCadastroClinica = async (email, codigo) => {
  const emails = await email.map((item) => {
    return {email: item}
  })
  let headersList = {
    'api-key': apiKey,
    'Content-Type': 'application/json',
  };

  let bodyContent = JSON.stringify({
    sender: {
      name: 'Amai',
      email: 'luismqdf@gmail.com',
    },
    to: emails,
    templateId: 2,
    params: {
      COD: codigo
    }
  });

  let response = await fetch('https://api.sendinblue.com/v3/smtp/email', {
    method: 'POST',
    body: bodyContent,
    headers: headersList,
  });
  if (response.status === 201) return true;
  else return false;
};

export default { enviarEmailSuporte, enviarEmailRecSenha, enviarEmailAprovaCadastroClinica };
