import React, { useEffect, useState } from 'react'
import styles from './CircularesConv.module.css'
import Head from '../Helper/Head'
import TituloAssociado from '../Helper/TituloAssociado'
import { useAssociadoContext } from '../../useAssociadoContext'
import { useUserContext } from '../../UserContext'
import { DELETE_CIRCULAR, GET_CIRCULARES_CONVENIO } from '../../api'
import SubTituloAss from '../Helper/SubTituloAss'
import { Link } from 'react-router-dom'
import { set } from 'date-fns'
import Modal from 'react-modal'
import { ReactComponent as Delete } from "../../Assets/delete.svg";
import Button from '../Forms/Button'

const CircularesConv = () => {
  const { convenio } = useAssociadoContext()
  const [circulares, setCirculares] = useState([])
  const [circularesFilt, setCircularesFilt] = useState([])
  const [anos, setAnos] = useState<any[]>();
  const { loading, setLoading, tokenGer } = useUserContext();
  const [mensagem, setMensagem] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [idCircular, setIdCircular] = useState(0);

  async function getCirculares() {
    try {
      setLoading(true)
      const { url, options } = GET_CIRCULARES_CONVENIO({ id_convenio: convenio[0].id });
      const response = await fetch(url, options);
      const json = await response.json();
      console.log(json)
      if (!response.ok) throw new Error(json.message)
      if (json) {
        const mappedFiles = json.reduce((acc: any, file: any) => {
          // Verifica se o nome do arquivo já existe no array
          const isUnique = acc.every((item: any) => item.nome_arquivo !== file.nome_arquivo);

          // Se for único, adiciona ao array
          if (isUnique) {
            acc.push({ nome_arquivo: file.nome_arquivo, url: file.url, id: file.id });
          }

          return acc;
        }, []);
        console.log(mappedFiles)
        setCirculares(mappedFiles);

        mappedFiles.sort((a: any, b: any) => {
          // Extrai os anos dos nomes dos arquivos (assumindo que o ano está na posição 6 e 7)
          const yearA = parseInt(a.nome_arquivo.substr(14, 4));
          const yearB = parseInt(b.nome_arquivo.substr(14, 4));

          // Extrai os números dos nomes dos arquivos (assumindo que o número começa na posição 10)
          const numberA = parseInt(a.nome_arquivo.substr(11, 3));
          const numberB = parseInt(b.nome_arquivo.substr(11, 3));

          // Compara primeiro pelo ano
          if (yearA !== yearB) {
            return yearB - yearA; // Ordem decrescente (do mais recente para o mais antigo)
          }
          // Se os anos forem iguais, compara pelo número
          return numberB - numberA; // Ordem crescente (do menor para o maior)
        });
        setCircularesFilt(mappedFiles);
      }
    } catch (error: any) {
      console.log(error.message)
    } finally {
      setLoading(false)
    }
  }
  async function deleteCircular() {
    try {
      setLoading(true)
      setModalOpen(false)
      const { url, options } = DELETE_CIRCULAR(tokenGer, { id: idCircular })
      const response = await fetch(url, options);
      const { message } = await response.json();
      setMensagem(message)
      if (!response.ok) throw new Error(message);
      getCirculares();
      setModalOpen2(true)
    } catch (error) {
      console.log('Erro no deleteCircular: ' + error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  function fecharModal() {
    setModalOpen(false)
    setModalOpen2(false)
  }

  function abrirModal(id: any) {
    setModalOpen(true)
    setIdCircular(id)
    setMensagem('Confirma a EXCLUSÃO da circular? Essa operação não poderá ser desfeita!')
  }

  useEffect(() => {
    function getYears() {
      const anos: any[] = []
      circulares?.map((item: any) => {
        const ano = parseInt(item.nome_arquivo.substr(14, 4));
        if (!anos.includes(ano) && ano) {
          anos.push(ano);
        }
      })
      anos.sort((a, b) => b - a)

      return anos;
    }
    setAnos(getYears());
  }, [circulares])

  useEffect(() => {
    getCirculares();
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Modal
        className={styles.modal}
        isOpen={modalOpen}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <div className={styles.botoes}>
          <Button descricao='excluir' classe='submit' onClick={deleteCircular} />
          <Button descricao='cancelar' classe='cancel' onClick={fecharModal} />
        </div>

      </Modal>
      <Modal
        className={styles.modal}
        isOpen={modalOpen2}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <Button descricao='fechar' classe='main' onClick={fecharModal} />
      </Modal>
      <Head
        title="Área Gerencial - Extratos"
        description="Área Gerencial"
      />
      <TituloAssociado titulo={'circulares - ' + convenio[0].nome} />
      <div className={styles.divCirculares}>
        {anos?.length !== 0 && anos?.map((ano: any, index: any) => (
          <div className={styles.divAno} key={index}>
            <SubTituloAss subTitulo={'circulares - ano ' + ano} />
            {circularesFilt?.map((item: any, index) => (
              new RegExp("^.{14}" + ano).test(item.nome_arquivo) ?
                <div className={styles.circular} key={index}>
                  <Link to={item.url} target='blank' >{'Nº ' + item.nome_arquivo.replace('circulares/', '').replace('.pdf', '').replace(/\+/g, ' ')}</Link>
                  <Delete className={styles.svg} onClick={() => abrirModal(item.id)} title='Excluir' />
                </div> : ""
            ))}
          </div>
        ))}
      </div>
    </section>
  )
}

export default CircularesConv