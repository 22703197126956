import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFileContext } from '../../useFileContext';
import styles from './AnexosMultiplos.module.css'

const AnexosMultiplos = () => {
  // Estado para armazenar os arquivos anexados
  const { uploadedFiles = [], setUploadedFiles } = useFileContext();
  const onDrop = acceptedFiles => {
    // Anexa os novos arquivos aos já existentes
    setUploadedFiles(curr => [...curr, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*', // Exemplo de aceitação apenas de imagens. Ajuste conforme necessário.
    multiple: true // Permite selecionar múltiplos arquivos
  });

  return (
    <div className={styles.containerMain}>
      <div {...getRootProps()} className={styles.divAddFiles}>
        <input {...getInputProps()} />
        <p className={styles.tituloFiles}>Arraste arquivos para cá ou clique para selecionar arquivos</p>
        {uploadedFiles.length !== 0 && <h2>Arquivos Adicionados</h2>}
        {uploadedFiles.map((file, index) => (
          <p className={styles.item} key={index}>{file.name}</p> // Exibe o nome de cada arquivo
        ))}
      </div>
    </div>
  );
}

export default AnexosMultiplos;