import React from 'react'
import TituloAssociado from '../Helper/TituloAssociado'
import Head from '../Helper/Head'
import styles from './ManuaisGer.module.css'
import { Link } from 'react-router-dom'
import Button from '../Forms/Button'

const ManuaisGer = () => {
  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Manuais"
        description="Área do associado"
      />
      <div className={styles.divTitulo}>
        <TituloAssociado titulo='Manuais' />
        <Link to='novo-manual'>
          <Button descricao='+ cadastrar manual' classe='submit' />
        </Link>
      </div>
      <div className={styles.divManuais}>
        <Link to='medicos'>Manual - médicos</Link>
        <Link to='demais'>Manual - demais profissionais</Link>
      </div>
    </section>
  )
}

export default ManuaisGer