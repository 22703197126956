import React, { useEffect } from "react";

const Head = (props: any) => {
  useEffect(() => {
    document.title = props.title + " | AMAI";
    const description = document.querySelector("meta[name='description']");
    description?.setAttribute("content", props.description || "");
  }, [props]);
  return <></>;
};

export default Head;
