import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import Footer from './Components/Footer/Footer';
import SobreNos from './Components/SobreNos/SobreNos';
import Associados from './Components/Associados/Associados';
import ConveniosPage from './Components/Convenios/ConveniosPage';
import FaleConosco from './Components/FaleConosco/FaleConosco';
import NovoAssociado from './Components/NovoAssociado/NovoAssociado';
import { FileProvider } from './useFileContext';
import { AssociadoProvider } from './useAssociadoContext';
import Profissionais from './Components/Profissionais/Profissionais';
import Login from './Components/Login/Login';
import AreaAssociado from './Components/AreaDoAssociado/AreaAssociado';
import ProtectedRoute from './Components/Helper/ProtectedRoute';
import { UserStorage } from './UserContext';
import { MedicoProvider } from './useMedicoContext';
import AreaGerencial from './Components/AreaGerencial/AreaGerencial';
import ProtectedRouteGer from './Components/Helper/ProtectedRouteGer';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <AvisoProvider> */}
        <ScrollToTop />
        <UserStorage>
          <FileProvider>
            <MedicoProvider>
              <AssociadoProvider>
                <Header />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/sobrenos" element={<SobreNos />} />
                  <Route path="/associados" element={<Associados />} />
                  <Route path="/convenios" element={<ConveniosPage />} />
                  <Route path="/faleconosco" element={<FaleConosco />} />
                  <Route path="/novoassociado" element={<NovoAssociado />} />
                  <Route path="/profissionais" element={<Profissionais />} />
                  <Route path="/login-associado/*" element={<Login />} />
                  <Route path="/login-gerencial/*" element={<Login />} />
                  <Route path="/area-associado/*"
                    element={
                      <ProtectedRoute>
                        <AreaAssociado />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/area-gerencial/*"
                    element={
                      <ProtectedRouteGer>
                        <AreaGerencial />
                      </ProtectedRouteGer>
                    }
                  />
                </Routes>
                <Footer />
              </AssociadoProvider>
            </MedicoProvider>
          </FileProvider>
        </UserStorage>
        {/* </AvisoProvider> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
