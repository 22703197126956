import React, { useEffect, useState } from 'react'
import styles from './ClinicaPre.module.css'
import TituloAssociado from '../Helper/TituloAssociado'
import { useMedicoContext } from '../../useMedicoContext'
import ClinicaForm from './ClinicaForm'
import { APROVA_CADASTRO, GET_DOCS_CLINICA } from '../../api'
import { useUserContext } from '../../UserContext'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../Forms/Button'
import enviarEmail from "../Email/Email";
import Modal from "react-modal";
import { useAssociadoContext } from '../../useAssociadoContext'
import Input from '../Forms/Input'
import useForm from '../Hooks/useForm'

const ClinicaPre = () => {
  const codigoCliente = useForm('')
  const { tokenGer, loading, setLoading, error, setError } = useUserContext();
  const { clinica } = useMedicoContext();
  const [docs, setDocs] = useState<any>([])
  const tipos = ['Contrato social', 'CNPJ', 'Inscrição estadual', 'Licença de funcionamento', 'Termo de responsabilidade técnica', 'Inscrição no conselho profissional', 'Comprovante de conta corrente', 'Comprovante de endereço', 'Carta de inclusão']
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const navigate = useNavigate();
  const { formDataClinica, setFormDataClinica } = useAssociadoContext();
  const [initialValues, setInitialValues] = useState({
    id_clinica: clinica[0].id_clinica,
    nom_clinica: clinica[0].nom_clinica,
    num_cnpj: clinica[0].num_cnpj,
    des_endereco: clinica[0].des_endereco,
    bairro: clinica[0].bairro,
    num_cep: clinica[0].num_cep,
    data: clinica[0].dataFormatada,
    num_inscricao: clinica[0].num_inscricao,
    des_email: clinica[0].des_email,
    nom_fantasia: clinica[0].nom_fantasia,
    telefones: clinica[0].telefones,
    especialidades: clinica[0].especialidades,
    num_reg_conselho: clinica[0].num_reg_conselho,
    cidade: clinica[0].cidade,
    uf: clinica[0].uf,
    email_clinica: clinica[0].email_clinica,
    whatsapp: clinica[0].whatsapp,
    insta_clinica: clinica[0].insta_clinica,
    rt_clinica: clinica[0].rt_clinica,
    cpf_rt: clinica[0].cpf_rt,
    num_conselho_rt: clinica[0].num_conselho_rt,
    email_rt: clinica[0].email_rt,
    telefone_rt: clinica[0].telefone_rt,
    nome_fat: clinica[0].nome_fat,
    telefone_fat: clinica[0].telefone_fat,
    email_fat: clinica[0].email_fat,
    resp_cadastro: clinica[0].resp_cadastro,
    tel_resp_cadastro: clinica[0].tel_resp_cadastro,
    cod_clinica: clinica[0].cod_clinica ? clinica[0].cod_clinica : ''
  })

  const fecharModal = () => {
    setEmailSent(false);
    navigate(-1)
  }

  async function aprovaCadastro() {
    try {
      if (formDataClinica.cod_clinica) {
        setLoading(true);
        const { url, options } = APROVA_CADASTRO(tokenGer, formDataClinica ? formDataClinica : {})
        const response = await fetch(url, options);
        const { email } = await response.json();
        if (!response.ok) throw new Error('Erro ao tentar aprovar o cadastro da clínica');
        const envioEmail = await enviarEmail.enviarEmailAprovaCadastroClinica(email, formDataClinica.cod_clinica);
        if (envioEmail) {
          setMensagem('Clínica aprovada com sucesso, e-mail enviado com sucesso para o cliente.')
          setLoading(false);
          setEmailSent(true);
        } else {
          setError('Erro ao enviar o email')
        }
      } else {
        setError('Preencha o campo código do cliente antes de continuar')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    async function getDocsClinica() {
      try {
        const { url, options } = GET_DOCS_CLINICA(tokenGer, { id_clinica: clinica[0].id_clinica });
        const response = await fetch(url, options);
        const json = await response.json();
        if (!response.ok) throw new Error('Erro ao tentar trazer os arquivos da clínica');
        setDocs(json);
      } catch (error) {
        console.log(error)
      }
    }
    getDocsClinica()
  }, [])

  return (
    <section className={`${styles.containerMain} animeLeft`}>
      <TituloAssociado titulo={clinica[0].nom_fantasia ? clinica[0].nom_fantasia : clinica[0].nom_clinica} />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <Button descricao='fechar' classe='third' onClick={fecharModal} />
      </Modal>
      <div className={styles.divForm}>
        <ClinicaForm initialValues={initialValues} />
        <div className={styles.divDocumentos}>
          <h2>Documentos</h2>
          {docs && docs.length === 0 ?
            <p>Nenhum documento encontrado para essa clínica</p>
            :
            <div className={styles.divLinks}>
              {docs.map((item: any, index: any) => (
                <Link key={index} to={item.url} target='_blank'>{tipos[item.tipo - 1]}</Link>
              ))}
            </div>
          }
          {/* <Input label={'Código do cliente'} type={'text'} name={'codCliente'} {...codigoCliente} /> */}
        </div>
        <div className={styles.buttons}>
          <Button descricao='recusar' classe='cancel' disabled={loading} />
          <Button descricao='aprovar' classe='submit' disabled={loading} onClick={aprovaCadastro} />
        </div>
        <p className={styles.error}>{error}</p>
      </div>
    </section>
  )
}

export default ClinicaPre
