import React, { ReactNode, createContext, useContext, useState } from 'react';
import useForm, { FormHook } from './Components/Hooks/useForm';

export interface DocsProps {
  url: string;
  tipo: number;
}

export interface NovoAssociadoForm {
  nom_clinica: string;
  num_cnpj: string;
  des_endereco: string;
  data?: string;
  bairro: string;
  num_cep: string;
  num_inscricao: string;
  des_email: string;
  nom_fantasia: string;
  telefones: string;
  especialidades: string;
  num_reg_conselho: string;
  cidade: string;
  uf: string;
  email_clinica: string;
  whatsapp: string;
  insta_clinica: string;
  rt_clinica: string;
  cpf_rt: string;
  num_conselho_rt: string;
  email_rt: string;
  telefone_rt: string;
  nome_fat: string;
  telefone_fat: string;
  email_fat: string;
  resp_cadastro: string;
  tel_resp_cadastro: string;
  docs?: DocsProps[] | undefined;
  ind_status?: string;
  cod_clinica?: string;
}
interface AssociadoContextProps {
  razaoSocial: FormHook;
  nomeFantasia: FormHook;
  especialidades: FormHook;
  cnpj: FormHook;
  inscricaoEst: FormHook;
  numConselho: FormHook;
  endereco: FormHook;
  bairro: FormHook;
  cidade: FormHook;
  uf: FormHook;
  cep: FormHook;
  telefones: FormHook;
  email: FormHook;
  whatsapp: FormHook;
  instagram: FormHook;
  rt: FormHook;
  cpf: FormHook;
  numConselhoRT: FormHook;
  emailRT: FormHook;
  telefoneRT: FormHook;
  nomeFat: FormHook;
  telefoneFat: FormHook;
  emailFat: FormHook;
  resp_cadastro: FormHook;
  tel_resp_cadastro: FormHook;
  formDataClinica: NovoAssociadoForm | any;
  setFormDataClinica: React.Dispatch<React.SetStateAction<NovoAssociadoForm | any>>
  destinatario: string | undefined;
  setDestinatario: React.Dispatch<React.SetStateAction<string | undefined>>;
  dadosAss: NovoAssociadoForm | {};
  setDadosAss: React.Dispatch<React.SetStateAction<NovoAssociadoForm | {}>>;
  convenios: Object[] | undefined;
  setConvenios: React.Dispatch<React.SetStateAction<Object[] | undefined>>
  especialidade: any | undefined;
  setEspecialidade: React.Dispatch<React.SetStateAction<any | undefined>>
  convenio: any | undefined;
  setConvenio: React.Dispatch<React.SetStateAction<any | undefined>>
}

const AssociadoContext = createContext<AssociadoContextProps | undefined>(undefined);

export const AssociadoProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const razaoSocial = useForm('')
  const nomeFantasia = useForm('')
  const especialidades = useForm('')
  const cnpj = useForm('cnpj')
  const inscricaoEst = useForm('')
  const numConselho = useForm('number')
  const endereco = useForm('')
  const bairro = useForm('')
  const cidade = useForm('')
  const uf = useForm('')
  const cep = useForm('')
  const telefones = useForm('')
  const email = useForm('')
  const whatsapp = useForm('telefone')
  const instagram = useForm('')
  const rt = useForm('')
  const cpf = useForm('cpf')
  const numConselhoRT = useForm('number')
  const emailRT = useForm('email')
  const telefoneRT = useForm('telefone')
  const nomeFat = useForm('')
  const telefoneFat = useForm('telefone')
  const emailFat = useForm('email')
  const resp_cadastro = useForm('')
  const tel_resp_cadastro = useForm('')
  const [convenios, setConvenios] = useState<Object[] | undefined>([]);
  const [convenio, setConvenio] = useState<any | undefined>(undefined);
  const [destinatario, setDestinatario] = useState<string | undefined>('');
  const [especialidade, setEspecialidade] = useState<any | undefined>();
  const [formDataClinica, setFormDataClinica] = useState<NovoAssociadoForm | any>();
  const [dadosAss, setDadosAss] = useState<NovoAssociadoForm | {}>({
    nom_clinica: '',
    num_cnpj: '',
    des_endereco: '',
    bairro: '',
    num_cep: '',
    num_inscricao: '',
    des_email: '',
    nom_fantasia: '',
    telefones: '',
    especialidades: '',
    num_reg_conselho: '',
    cidade: '',
    uf: '',
    email_clinica: '',
    whatsapp: '',
    insta_clinica: '',
    rt_clinica: '',
    cpf_rt: '',
    num_conselho_rt: '',
    email_rt: '',
    telefone_rt: '',
    nome_fat: '',
    telefone_fat: '',
    email_fat: '',
    resp_cadastro: '',
    tel_resp_cadastro: ''
  })




  return (
    <AssociadoContext.Provider
      value={{
        resp_cadastro,
        tel_resp_cadastro,
        formDataClinica,
        setFormDataClinica,
        especialidade,
        setEspecialidade,
        convenio,
        setConvenio,
        convenios,
        setConvenios,
        dadosAss,
        setDadosAss,
        razaoSocial,
        nomeFantasia,
        especialidades,
        cnpj,
        inscricaoEst,
        numConselho,
        endereco,
        bairro,
        cidade,
        uf,
        cep,
        telefones,
        email,
        whatsapp,
        instagram,
        rt,
        cpf,
        numConselhoRT,
        emailRT,
        telefoneRT,
        nomeFat,
        telefoneFat,
        emailFat,
        destinatario,
        setDestinatario
      }}
    >
      {children}
    </AssociadoContext.Provider>
  );
};

// Crie um hook para consumir o contexto
export const useAssociadoContext = (): AssociadoContextProps => {
  const context = useContext(AssociadoContext);

  if (!context) {
    throw new Error('useAssociadoContext deve ser utilizado dentro de um AssociadoProvider');
  }

  return context;
};
