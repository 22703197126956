import React from "react";

interface TypeItem {
  regex?: RegExp;
  message: string;
  validate?: Function;
}

export interface FormHook {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  error: string | null;
  validate?: () => boolean | undefined;
}

const types: Record<string, TypeItem> = {
  email: {
    regex:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Preencha um e-mail válido",
  },
  number: {
    regex: /^\d+$/,
    message: "Utilize apenas números",
  },
  data: {
    regex: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/,
    message: "Data inválida",
  },
  telefone: {
    regex: /\([1-9]\d\)\s9?\d{4}-\d{4}$/,
    message: "Digite um telefone válido",
  },
  cpf: {
    validate: (cpf: string): Boolean => {
      const regex = /[a-zA-Z]/;
      cpf = cpf.replace(/[^\d]+/g, "");

      if (cpf.length !== 11) {
        return false;
      }

      if (/^(\d)\1+$/.test(cpf)) {
        return false;
      }

      // Calcula o primeiro dígito verificador
      let soma = 0;
      for (let i = 0; i < 9; i++) {
        soma += parseInt(cpf.charAt(i)) * (10 - i);
      }
      let resto = 11 - (soma % 11);
      let digito1 = resto >= 10 ? 0 : resto;

      if (parseInt(cpf.charAt(9)) !== digito1) {
        return false;
      }

      // Calcula o segundo dígito verificador
      soma = 0;
      for (let i = 0; i < 10; i++) {
        soma += parseInt(cpf.charAt(i)) * (11 - i);
      }
      resto = 11 - (soma % 11);
      let digito2 = resto >= 10 ? 0 : resto;

      return parseInt(cpf.charAt(10)) === digito2;
    },
    message: "CPF inválido",
  },
  cnpj: {
    validate: (cnpjParam: string) => {
      const cnpj = cnpjParam.replace(/[^\d]+/g, '');

      if (cnpj == '') return false;

      if (cnpj.length != 14) return false;

      // Elimina CNPJs invalidos conhecidos
      if (
        cnpj == '00000000000000' ||
        cnpj == '11111111111111' ||
        cnpj == '22222222222222' ||
        cnpj == '33333333333333' ||
        cnpj == '44444444444444' ||
        cnpj == '55555555555555' ||
        cnpj == '66666666666666' ||
        cnpj == '77777777777777' ||
        cnpj == '88888888888888' ||
        cnpj == '99999999999999'
      )
        return false;

      // Valida DVs
      let tamanho = cnpj.length - 2;
      let numeros: any = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado: any = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) return false;

      return true;
    },
    message: "CNPJ inválido"
  }
};



type Types = keyof typeof types;

const useForm = (type: string): FormHook => {
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState<string | null>(null);

  function validate(value: string) {
    if (!type && value.length === 0) {
      setError("Preencha um valor.");
      return false;
    }
    if (value.length === 0) {
      setError("Preencha um valor.");
      return false;
    } else if (
      types[type] &&
      types[type].regex &&
      !types[type].regex?.test(value)
    ) {
      setError(types[type].message);
    } else if (
      type === "cpf" &&
      types.cpf.validate &&
      !types.cpf.validate(value)
    ) {
      setError(types["cpf"].message);
    } else if (
      type === "cnpj" &&
      types.cnpj.validate &&
      types.cnpj.validate(value) === false
    ) {
      setError(types[type].message);
    } else {
      setError(null);
      return true;
    }
  }

  function onChange({ target }: React.ChangeEvent<HTMLInputElement>) {
    if (type === "email" || type === "number" || type === "") {
      if (error) validate(target.value);
      setValue(target.value);
    } else if (type === "telefone") {
      const formatarTelefone = (valor: string) => {
        valor = valor.replace(/\D/g, "");
        valor = valor.replace(/^([1-9]\d)/, "($1) ");
        valor = valor.replace(/(\s9?\d{4})(\d{4})$/, "$1-$2");

        return valor;
      };
      setValue(formatarTelefone(target.value));
    } else if (type === "cpf") {
      const formatarCPF = (cpf: string) => {
        cpf = cpf.replace(/\D/g, "");
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      };
      setValue(formatarCPF(target.value));
    } else if (type === "cnpj") {
      const formatarCNPJ = (cnpj: string) => {
        cnpj = cnpj.replace(/\D/g, "");
        return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      };
      setValue(formatarCNPJ(target.value));
    } else if (type === "data") {
      const formatarData = (data: string) => {
        data = data.replace(/\D/g, "");
        data = data.replace(/^(\d{2})(\d)/, '$1/$2');
        data = data.replace(/^(\d{2})\/(\d{2})(\d)/, '$1/$2/$3');
        return data
      };
      setValue(formatarData(target.value));
    }
  }

  return {
    value,
    setValue,
    onChange,
    error,
    validate: () => validate(value),
    onBlur: () => validate(value),
  };
};

export default useForm;
