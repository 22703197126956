import React, { useEffect, useState } from 'react'
import styles from './NovaCircular.module.css'
import TituloAssociado from '../Helper/TituloAssociado'
import { Link, useNavigate } from 'react-router-dom'
import Head from '../Helper/Head'
import Anexos from '../NovoAssociado/Anexos'
import { useFileContext } from '../../useFileContext'
import Button from '../Forms/Button'
import { useAssociadoContext } from '../../useAssociadoContext'
import { CADASTRAR_CIRCULAR, GET_CONVENIOS_GERENCIAL } from '../../api'
import { useUserContext } from '../../UserContext'
import UploadFiles from '../ServerSide/uploadToS3'
import Modal from 'react-modal'

const NovaCircular = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const navigate = useNavigate()
  const { uploadedFiles = [], setUploadedFiles } = useFileContext()
  const { convenio, setConvenio, convenios, setConvenios } = useAssociadoContext();
  const { loading, setLoading, error, setError } = useUserContext();

  const handleChangeConvenio = (e: any) => {
    setConvenio(e.target.value);
  };

  function fecharModal() {
    setEmailSent(false);
    // navigate('/area-gerencial/circulares')
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    try {
      if (convenio && uploadedFiles.length !== 0) {
        setLoading(true)
        const response = await UploadFiles(uploadedFiles, '', 'circulares')
        const docsResp = response?.map((item: any, index) => {
          return { url: item ? item.Location : '', nome_arquivo: item.Key };
        })
        if (!response) throw new Error('Erro ao enviar documento para a nuvem.')
        const { url, options } = CADASTRAR_CIRCULAR({
          id_convenio: convenio,
          url: docsResp ? docsResp[0].url : '',
          nome_arquivo: docsResp ? docsResp[0].nome_arquivo : ''
        })
        const data = await fetch(url, options);
        const { message } = await data.json();
        if (!data.ok) throw new Error(message);
        setMensagem(message)
        setEmailSent(true)
        setError(null)
      } else if (!convenio) {
        setError('Selecione o convênio antes de enviar')
      } else if (uploadedFiles.length === 0) {
        setError('Anexe o arquivo antes de continuar')
      }
    } catch (e: any) {
      console.log(e.message)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setUploadedFiles([]);
    setConvenio(null)
    async function getConvenios() {
      const { url, options } = GET_CONVENIOS_GERENCIAL();
      const response = await fetch(url, options);
      const data = await response.json();
      if (!response.ok) throw new Error('Erro no get convenios.')
      setConvenios(data);
    }
    if (convenios?.length === 0) getConvenios();
  }, [])
  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <TituloAssociado titulo='Circulares - Nova Circular' />
      <Head title='Área Gerencial - Nova Circular' description='Área Gerencial' />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
      <div className={styles.divNovaCircular}>
        <form onSubmit={handleSubmit}>
          <div className={styles.divCampos}>
            <div className={styles.divConveniosSelect}>
              <label htmlFor="setores">Convênio</label>
              <select
                name="setores"
                id="setores"
                onChange={handleChangeConvenio}
                value={convenio ? convenio : ""}
              >
                <option value="">Selecione um convênio...</option>
                {convenios &&
                  convenios.map((item: any, index) => (
                    <option key={index} value={item.id}>
                      {item.nome}
                    </option>
                  ))}
              </select>
            </div>
            <Anexos label='Nova Circular' nome='novaCircular' campo={0} accept={{
              'application/pdf': ['.pdf'],
              'text/plain': ['.txt'],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            }} />

          </div>
          <Button classe='submit' descricao='enviar' disabled={loading} />
          <p className={styles.error}>{error}</p>
        </form>
      </div>
    </section>
  )
}

export default NovaCircular