import styles from './NovaEspecialidade.module.css'
import TituloAssociado from '../Helper/TituloAssociado'
import Head from '../Helper/Head';
import { useUserContext } from '../../UserContext';
import Input from '../Forms/Input';
import useForm from '../Hooks/useForm';
import { NOVA_ESP } from '../../api';
import Button from '../Forms/Button';
import Modal from 'react-modal';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';



const NovaEspecialidade = () => {
  const nome = useForm('');
  const { loading, setLoading, setError, error } = useUserContext();
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const navigate = useNavigate();

  const fecharModal = () => {
    setEmailSent(false);
    navigate(-1)
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    try {
      setError(null)
      if (nome.validate && nome.validate() && nome.value) {
        setLoading(true)
        const { url, options } = NOVA_ESP({ descricao: nome.value });
        const response = await fetch(url, options);
        const json = await response.json();
        if (!response.ok) {
          if (json.code === '23505') {
            setMensagem('Especialidade já existe.')
            setEmailSent(true)
          }
          throw new Error(json.code)
        }
        setMensagem('Especialidade cadastrada com sucesso')
        setEmailSent(true)
      } else {
        setError('Preencha o nome da Especialidade')
      }
    } catch (error: any) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setError(null)
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head title='Área Gerencial - Aviso' description='Área Gerencial' />
      <TituloAssociado titulo='cadastrar especialidade' />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
      <div className={styles.divNovoConv}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <Input label='Nome' type='text' name='nomeEsp' placeholder='Digite o nome da especialidade aqui...' {...nome} />
          <Button classe='submit' descricao='Salvar' disabled={loading} />
          <p className={styles.error}>{error}</p>
        </form>
      </div>
    </section>
  )
}

export default NovaEspecialidade