import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFileContext } from '../../useFileContext';
import styles from './AnexosPrevisao.module.css'

const AnexosPrevisao = () => {
  // Estado para armazenar os arquivos anexados
  const { uploadedPrev = [], setUploadedPrev } = useFileContext();
  const onDrop = acceptedFiles => {
    // Anexa os novos arquivos aos já existentes
    setUploadedPrev(curr => [...curr, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*', // Exemplo de aceitação apenas de imagens. Ajuste conforme necessário.
    multiple: true // Permite selecionar múltiplos arquivos
  });

  return (
    <div className={styles.containerMain}>
      <div {...getRootProps()} className={styles.divAddFiles}>
        <input {...getInputProps()} />
        <p className={styles.tituloFiles}>Arraste arquivos para cá ou clique para selecionar arquivos</p>
        {uploadedPrev.length !== 0 && <h2>Arquivos Adicionados</h2>}
        {uploadedPrev.map((file, index) => (
          <p className={styles.item} key={index}>{file.name}</p> // Exibe o nome de cada arquivo
        ))}
      </div>
    </div>
  );
}

export default AnexosPrevisao;