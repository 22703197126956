import { useState, useEffect } from 'react'
import styles from './Header.module.css'
import logo from '../../Assets/logo-amai.png'
import { Link, useLocation } from 'react-router-dom'
import MainButton from '../Forms/Button'
import Button from '../Forms/Button'

const Header = () => {
  const [currentRoute, setCurrentRoute] = useState("");
  const location = useLocation();

  useEffect(() => {
    setCurrentRoute(location.pathname);
    const checkbox = document.getElementById('checkbox-menu') as HTMLInputElement;
    if (checkbox) {
      checkbox.checked = false;
    }
  }, [location])

  return (
    <header className={styles.containerHeader}>
      <div className={styles.divMenu}>
        <Link className={styles.logo} to="/" aria-label="Konsist - Home">
          <img src={logo} alt="logo da empresa" />
        </Link>
        <input
          type="checkbox"
          className={styles.checkboxMenu}
          id="checkbox-menu"
        />
        <label className={styles.menuButtonContainer} htmlFor="checkbox-menu">
          <span></span>
          <span></span>
          <span></span>
        </label>
        <nav className={styles.menu}>
          <div className={styles.itensMenu}>
            <div className={styles.itemMenu}>
              <Link
                className={
                  currentRoute.includes("/sobrenos")
                    ? styles.selectedResp
                    : styles.item
                }
                to="/sobrenos"
              >
                Sobre nós
              </Link>
              <span className={currentRoute === "/sobrenos"
                ? styles.selected
                : styles.opaco}></span>
            </div>
            <div className={styles.itemMenu}>
              <Link
                to="/associados"
                className={currentRoute.includes("/associados")
                  ? styles.selectedResp
                  : styles.item}
              >
                Associados
              </Link>
              <span className={currentRoute === "/associados"
                ? styles.selected
                : styles.opaco}></span></div>
            <div className={styles.itemMenu}>
              <Link
                className={currentRoute.includes("/profissionais")
                  ? styles.selectedResp
                  : styles.item}
                to="/profissionais"
              >
                Profissionais
              </Link>
              <span className={currentRoute === "/profissionais"
                ? styles.selected
                : styles.opaco}></span>
            </div>
            <div className={styles.itemMenu}>
              <Link
                className={currentRoute.includes("/convenios")
                  ? styles.selectedResp
                  : styles.item}
                to="/convenios"
              >
                Convênios
              </Link>
              <span className={currentRoute === "/convenios"
                ? styles.selected
                : styles.opaco}>
              </span>
            </div>
            <div className={styles.itemMenu}>
              <Link
                className={currentRoute.includes("/faleconosco")
                  ? styles.selectedResp
                  : styles.item}
                to="/faleconosco"
              >
                Fale conosco
              </Link>
              <span className={currentRoute === "/faleconosco"
                ? styles.selected
                : styles.opaco}>
              </span>
            </div>
            {window.innerWidth < 600 ? (<>
              <div className={styles.itemMenu}>
                <Link to="/login-associado"
                  className={currentRoute.includes("/login-associado") || currentRoute.includes("/area-associado")
                    ? styles.selectedResp
                    : styles.item}>Área do associado
                </Link>
                <span className={currentRoute.includes("/login-associado") || currentRoute.includes("/area-associado")
                  ? styles.selected
                  : styles.opaco}>
                </span>
              </div>
              <div className={styles.itemMenu}>
                <Link to="/novoassociado"
                  className={currentRoute.includes("/novoassociado")
                    ? styles.selectedResp
                    : styles.item}>
                  Associe-se
                </Link>
                <span className={currentRoute.includes("/novoassociado")
                  ? styles.selected
                  : styles.opaco}>
                </span>
              </div></>
            ) : (
              ""
            )}
          </div>
          <div className={styles.buttonsMenu}>
            <Link to="/login-associado"><Button classe="second" descricao='área do associado' /></Link>
            <Link to="/novoassociado"><Button classe="main" descricao='associe-se' /></Link>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header