import React from 'react'
import styles from './SobreNos.module.css'
import CaixaTexto from './CaixaTexto'
import Head from '../Helper/Head'
import { useInView } from 'react-intersection-observer'

const SobreNos = () => {
  const [ref, inView] = useInView({
    triggerOnce: true
  })
  const [ref2, inView2] = useInView({
    triggerOnce: true
  })
  const [ref3, inView3] = useInView({
    triggerOnce: true
  })
  
  const [ref4, inView4] = useInView({
    triggerOnce: true
  })
  const [ref5, inView5] = useInView({
    triggerOnce: true
  })

  return (
    <>
      <Head title='Sobre Nós' description='História da AMAI'/>
      <section className={`${styles.containerPrincipal} ${inView ? 'animeLeft' : ''}`} ref={ref}>
        <h2>um pouco da história da</h2>
        <h1>ASSOCIAÇÃO MÉDICA DE ASSISTÊNCIA INTEGRADA</h1>
        <CaixaTexto classe={`${inView2 ? 'texto1 animeLeft' : 'texto1'}`} myRef={ref2} titulo='sobre nós' texto='A Associação Médica de Assistência Integrada, com sede no Prime Excelência Médica em Taguatinga e suas filiais em Águas Claras e Asa Sul. Fundada em 28 de agosto de 1987, é uma associação de direito privado, constituída por tempo indeterminado, sem fins lucrativos, voltada exclusivamente ao interesse técnico-profissional, cultural, social e econômico de seus associados.'/>
        <CaixaTexto classe={`${inView3 ? 'texto2 animeRight' : 'texto2'}`} myRef={ref3} titulo='missão' texto='Administrar como mandatária os interesses de seus associados no que refere a contratação de convênios e intermediando a cobrança e repasse de seus honorários. Negociar junto a comissão melhores valores para seus associados.'/>
        <CaixaTexto classe={`${inView4 ? 'texto3 animeLeft' : 'texto3'}`} myRef={ref4} titulo='visão' texto='Tornar-se uma associação com referencial maior no Distrito Federal e Entorno, demonstrando suas características de honestidade, seriedade, transparência e lealdade para com seus associados.'/>
        <CaixaTexto classe={`${inView5 ? 'texto4 animeRight' : 'texto4'}`} myRef={ref5}titulo='valores' texto='Há uma busca contínua em excelência na qualidade dos serviços prestados, comprometendo-se sempre no melhor para seus associados.'/>
      </section>
    </>
  )
}

export default SobreNos