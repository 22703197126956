import React, { useEffect, useState } from 'react'
import styles from './FormularioGuiasGer.module.css'
import Head from '../Helper/Head'
import TituloAssociado from '../Helper/TituloAssociado'
import { Link, useNavigate } from 'react-router-dom'
import Input from '../Forms/Input'
import Anexos from '../NovoAssociado/Anexos'
import Button from '../Forms/Button'
import { useUserContext } from '../../UserContext'
import { useFileContext } from '../../useFileContext'
import UploadFiles from '../ServerSide/uploadToS3'
import useForm from '../Hooks/useForm'
import Modal from 'react-modal'

const FormularioGuiasGer = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const navigate = useNavigate();
  const url = useForm('');
  const { loading, setLoading, token, data } = useUserContext();
  const { uploadedFiles = [], setUploadedFiles } = useFileContext();

  function fecharModal() {
    setEmailSent(false);
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    try {
      setLoading(true)
      const response = await UploadFiles(uploadedFiles, '', 'formulario-entrega-guias')
      if (!response) throw new Error('Erro ao enviar documento para a nuvem.')
      setMensagem('Formulário atualizado com sucesso');
      setEmailSent(true);
      setUploadedFiles([]);
    } catch (e: any) {
      console.log(e.message)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setUploadedFiles([]);
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Formulário de entrega de guias"
        description="Área do associado"
      />
      <TituloAssociado titulo='Formulário de entrega de guias' />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
      <div className={styles.divFormulario}>
        <Link to='https://site-amai.s3.sa-east-1.amazonaws.com/formulario-entrega-guias/FORMUL%C3%81RIO+DE+ENTREGA+DE+GUIAS.doc' target='_blank'>Clique aqui para obter o formulário de entrega de guias</Link>
        <span className={styles.sep}></span>
        <div className={styles.divCadastro}>
          <form onSubmit={handleSubmit}>
            <Anexos campo={0} label='Novo formulário (Manter padrão de nome do arquivo em: FORMULÁRIO DE ENTREGA DE GUIAS.doc)' nome='logomarca' accept={{
              'application/msword': ['.doc'],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            }} />
            <Button descricao={loading ? 'enviando...' : 'enviar'} classe='submit' disabled={loading} />
          </form>
        </div>
      </div>
    </section>
  )
}

export default FormularioGuiasGer