import React, { useEffect, useState } from 'react'
import styles from './Clinica.module.css'
import TituloAssociado from '../Helper/TituloAssociado'
import { useMedicoContext } from '../../useMedicoContext'
import ClinicaForm from './ClinicaForm'
import { ATUALIZAR_CADASTRO_CLINICA, GET_DOCS_CLINICA } from '../../api'
import { useUserContext } from '../../UserContext'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../Forms/Button'
import { useAssociadoContext } from '../../useAssociadoContext'
import Modal from 'react-modal'
import enviarEmail from "../Email/Email";

const Clinica = () => {
  const { tokenGer, setLoading, loading } = useUserContext();
  const { clinica } = useMedicoContext();
  const { formDataClinica } = useAssociadoContext();
  const [docs, setDocs] = useState<any>([])
  const tipos = ['Contrato social', 'CNPJ', 'Inscrição estadual', 'Licença de funcionamento', 'Termo de responsabilidade técnica', 'Inscrição no conselho profissional', 'Comprovante de conta corrente', 'Comprovante de endereço']
  const [initialValues, setInitialValues] = useState({})
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const navigate = useNavigate();

  const fecharModal = () => {
    setEmailSent(false);
  }

  const enviaEmailCadastro = async () => {
    const email = await formDataClinica.email_clinica?.replace(/\s/g, '').split(';')
    const enviou = await enviarEmail.enviarEmailAprovaCadastroClinica(email, formDataClinica.cod_clinica);
    console.log(enviou)
    if (enviou) {
      setMensagem("Email de confirmação enviado com sucesso!")
      setEmailSent(true)
    } else if (!enviou) {
      setMensagem("Erro no envio do email.")
      setEmailSent(true)
    }
  }
  async function atualizaCadastro() {
    try {
      setLoading(true);
      console.log(formDataClinica)
      const { url, options } = ATUALIZAR_CADASTRO_CLINICA(tokenGer, formDataClinica ? formDataClinica : {})
      const response = await fetch(url, options);
      const { message } = await response.json();
      if (!response.ok) throw new Error(message);
      setMensagem(message)
      setEmailSent(true)

    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    async function getDocsClinica() {
      try {
        const { url, options } = GET_DOCS_CLINICA(tokenGer, { id_clinica: clinica[0].id_clinica });
        const response = await fetch(url, options);
        const json = await response.json();
        if (!response.ok) throw new Error('Erro ao tentar trazer os arquivos da clínica');
        setDocs(json);
      } catch (error) {
        console.log(error)
      }
    }
    getDocsClinica()
  }, [])

  useEffect(() => {
    setInitialValues({
      id_clinica: clinica[0].id_clinica,
      nom_clinica: clinica[0].nom_clinica,
      num_cnpj: clinica[0].num_cnpj,
      des_endereco: clinica[0].des_endereco,
      bairro: clinica[0].bairro,
      num_cep: clinica[0].num_cep,
      data: clinica[0].dataFormatada,
      num_inscricao: clinica[0].num_inscricao,
      des_email: clinica[0].des_email,
      nom_fantasia: clinica[0].nom_fantasia,
      telefones: clinica[0].telefones,
      especialidades: clinica[0].especialidades,
      num_reg_conselho: clinica[0].num_reg_conselho,
      cidade: clinica[0].cidade,
      uf: clinica[0].uf,
      email_clinica: clinica[0].email_clinica,
      whatsapp: clinica[0].whatsapp,
      insta_clinica: clinica[0].insta_clinica,
      rt_clinica: clinica[0].rt_clinica,
      cpf_rt: clinica[0].cpf_rt,
      num_conselho_rt: clinica[0].num_conselho_rt,
      email_rt: clinica[0].email_rt,
      telefone_rt: clinica[0].telefone_rt,
      nome_fat: clinica[0].nome_fat,
      telefone_fat: clinica[0].telefone_fat,
      email_fat: clinica[0].email_fat,
      resp_cadastro: clinica[0].resp_cadastro,
      tel_resp_cadastro: clinica[0].tel_resp_cadastro,
      ind_status: clinica[0].ind_status,
      cod_clinica: clinica[0].cod_clinica
    })
  }, [])

  return (
    <section className={`${styles.containerMain} animeLeft`}>
      <TituloAssociado titulo={clinica[0].nom_fantasia ? clinica[0].nom_fantasia : clinica[0].nom_clinica} />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
      <div className={styles.divForm}>
        {initialValues && clinica[0].id_clinica && <ClinicaForm initialValues={initialValues} />}
        <div className={styles.divDocumentos}>
          <h2>Documentos</h2>
          {docs && docs.length === 0 ?
            <p>Nenhum documento encontrado para essa clínica</p>
            :
            <div className={styles.divLinks}>
              {docs.map((item: any, index: any) => (
                <Link key={index} to={item.url} target='_blank'>{tipos[item.tipo - 1]}</Link>
              ))}
            </div>
          }
        </div>
        <div className={styles.botoes}>
          <Button descricao='reenviar confirmação' classe='third' onClick={enviaEmailCadastro} />
          <Button descricao='salvar' classe='submit' onClick={atualizaCadastro} />
        </div>
      </div>
    </section>
  )
}

export default Clinica