import React, { useEffect, useState } from 'react'
import styles from './ClinicaForm.module.css'
import Input from '../Forms/Input'
import useForm, { FormHook } from '../Hooks/useForm'
import { NovoAssociadoForm, useAssociadoContext } from '../../useAssociadoContext';
import { useMedicoContext } from '../../useMedicoContext';
import { useLocation } from 'react-router-dom';

interface InitialValuesTypes {
  initialValues: NovoAssociadoForm | any;
}

const ClinicaForm = ({ initialValues }: InitialValuesTypes) => {

  const { formDataClinica, setFormDataClinica } = useAssociadoContext();
  const { clinica } = useMedicoContext();
  const [ativo, setAtivo] = useState<boolean>(initialValues.ind_status === 'Ativo');
  const location = useLocation();
  const { pathname } = location;

  function handleAtivo(e: any) {
    setAtivo(!ativo)
  }

  // useEffect(() => {
  //   console.log(formDataClinica.ind_status)
  // }, [ativo])

  useEffect(() => {
    setFormDataClinica(initialValues)
    setAtivo(initialValues.ind_status === 'Ativo');
  }, [initialValues])


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;

    if (name === 'ind_status') {
      const newStatus = checked ? 'Ativo' : 'Inativo';
      setAtivo(checked);
      if (formDataClinica) setFormDataClinica({ ...formDataClinica, [name]: newStatus });
    } else {
      if (formDataClinica) setFormDataClinica({ ...formDataClinica, [name]: value });
    }
  };

  return (
    <div className={styles.form}>
      {pathname.includes('/corpo-clinico/associados') ?


        <label htmlFor="ativar" className={styles.ativarAviso}>
          <input type='checkbox' name='ind_status' id='ativar' checked={ativo} onChange={handleInputChange} value={ativo ? 'Ativo' : 'Inativo'} />
          {/* <span className={styles.checkmark}></span> */}
          Ativar Clínica
        </label>

        : <></>
      }
      <Input label='Razão Social' type='text' name='nom_clinica' onChange={handleInputChange} value={formDataClinica?.nom_clinica} maxLength={150} />
      <Input label='Nome Fantasia' type='text' name='nom_fantasia' onChange={handleInputChange} value={formDataClinica?.nom_fantasia} maxLength={50} />
      <Input label='Especialidades (Em caso de mais de uma especialidade separá-las por ;  Ex: Cardiologia; Clínica Médica)' type='text' name='especialidades' onChange={handleInputChange} value={formDataClinica?.especialidades} maxLength={500} />
      <Input label='CNPJ' type='text' name='num_cnpj' onChange={handleInputChange} value={formDataClinica?.num_cnpj} classe='col4' maxLength={18} />
      <Input label='Inscrição Estadual' type='text' name='num_inscricao' onChange={handleInputChange} value={formDataClinica?.num_inscricao} classe='col3' maxLength={13} />
      <Input label='Nº Registro Conselho' type='text' name='num_reg_conselho' onChange={handleInputChange} value={formDataClinica?.num_reg_conselho} classe='col3' maxLength={100} />
      <Input label='Endereço' type='text' name='des_endereco' onChange={handleInputChange} value={formDataClinica?.des_endereco} maxLength={150} />
      <Input label='Bairro' type='text' name='bairro' onChange={handleInputChange} value={formDataClinica?.bairro} classe='col3' maxLength={30} />
      <Input label='Cidade' type='text' name='cidade' onChange={handleInputChange} value={formDataClinica?.cidade} classe='col3' maxLength={100} />
      <Input label='UF' type='text' name='uf' onChange={handleInputChange} value={formDataClinica?.uf} classe='col1' maxLength={2} />
      <Input label='CEP' type='text' name='num_cep' onChange={handleInputChange} value={formDataClinica?.num_cep} classe='col3' maxLength={8} />
      <Input label='Telefone(s) (Em caso de mais de um telefone separá-los por ; Ex: (61) 99999-9999; (61) 3333-3333)' type='text' name='telefones' onChange={handleInputChange} value={formDataClinica?.telefones} maxLength={200} />
      <Input label='Email(s) Clínica (Em caso de mais de um email separá-los por ; )' type='text' name='email_clinica' onChange={handleInputChange} value={formDataClinica?.email_clinica} maxLength={500} />
      <Input label='Contato Whatsapp' type='text' name='whatsapp' onChange={handleInputChange} value={formDataClinica?.whatsapp} classe='col5' maxLength={15} />
      <Input label='Instagram' type='text' name='insta_clinica' onChange={handleInputChange} value={formDataClinica?.insta_clinica} classe='col5' maxLength={100} />
      <Input label='Responsável Técnico' type='text' name='rt_clinica' onChange={handleInputChange} value={formDataClinica?.rt_clinica} classe='col4' maxLength={300} />
      <Input label='CPF RT' type='text' name='cpf_rt' onChange={handleInputChange} value={formDataClinica?.cpf_rt} classe='col3' maxLength={14} />
      <Input label='Nº Registro Conselho RT' type='text' name='num_conselho_rt' onChange={handleInputChange} value={formDataClinica?.num_conselho_rt} classe='col3' maxLength={20} />
      <Input label='E-mail Responsável Técnico' type='text' name='email_rt' onChange={handleInputChange} value={formDataClinica?.email_rt} classe='col6' maxLength={200} />
      <Input label='Telefone Responsável Técnico' type='text' name='telefone_rt' onChange={handleInputChange} value={formDataClinica?.telefone_rt} classe='col4' maxLength={15} />
      <Input label='Nome - Faturista' type='text' name='nome_fat' onChange={handleInputChange} value={formDataClinica?.nome_fat} classe='col3' maxLength={200} />
      <Input label='Telefone - Faturista' type='text' name='telefone_fat' onChange={handleInputChange} value={formDataClinica?.telefone_fat} classe='col3' maxLength={15} />
      <Input label='Email - Faturista' type='text' name='email_fat' onChange={handleInputChange} value={formDataClinica?.email_fat} classe='col4' maxLength={200} />
      <Input label='Nome - Responsável Cadastro' type='text' name='resp_cadastro' onChange={handleInputChange} value={formDataClinica?.resp_cadastro} classe='col5' maxLength={200} />
      <Input label='Telefone - Responsável Cadastro' type='text' name='tel_resp_cadastro' onChange={handleInputChange} value={formDataClinica?.tel_resp_cadastro} classe='col5' maxLength={200} />
      <Input label='Código Clínica' type='text' name='cod_clinica' onChange={handleInputChange} value={formDataClinica?.cod_clinica} classe='col2' maxLength={5} />
    </div>
  )
}

export default ClinicaForm
