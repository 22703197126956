import React from 'react'
import styles from './Mapa.module.css'
import { useState } from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import { types } from 'util';


interface Locale{
  lat: number;
  lng: number;
  idMapa: string | undefined;
  chave?: string;
}

const Mapa = ({lat, lng, idMapa, chave}: Locale) => {
  const position = { lat: lat, lng: lng };
  const [open, setOpen] = useState(false);
    

  return (
      <div className={styles.mapa}>
        <Map zoom={15} center={position} mapId={idMapa}>
          <AdvancedMarker position={position} onClick={() => setOpen(true)}>
            <Pin/>
          </AdvancedMarker>
        </Map>
      </div>
  )
}

export default Mapa


