import React, { useEffect } from 'react'
import styles from './ConveniosGeral.module.css'
import { useAssociadoContext } from '../../useAssociadoContext'
import { Link } from 'react-router-dom'
import { GET_CONVENIOS } from '../../api'
import { useUserContext } from '../../UserContext'
import Head from '../Helper/Head'
import TituloAssociado from '../Helper/TituloAssociado'

const ConveniosGeral = () => {
  const { convenios, setConvenios } = useAssociadoContext();
  const { token } = useUserContext();

  useEffect(() => {
    async function getConvenios() {
      if (token) {
        const { url, options } = GET_CONVENIOS(token);
        const response = await fetch(url, options);
        const data = await response.json();
        if (!response.ok) throw new Error('Erro no get convenios.')
        setConvenios(data);
      }
    }
    if (convenios?.length === 0) getConvenios();
  }, [token])
  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Convenios Geral"
        description="Área do associado"
      />
      <TituloAssociado  titulo="lista de convênios" especialidade='para saber o convênio que pode ser atendido entre na relação de especialidade desejada' />
      <div className={styles.divConvenios}>
        {convenios && convenios?.map((item: any, index) => (
          item.restrito === false ?
            <Link to={`/area-associado/convenios/${item.id}`} key={index}>{item.nome}</Link> : null
        ))}
      </div>
    </section>
  )
}

export default ConveniosGeral