import React, { useEffect, useState } from 'react'
import styles from './Resultado.module.css'
import { useFileContext } from '../../useFileContext'

const Resultado = () => {
  const { busca, buscou, medicos, clinicas, resultadoFiltrado, setResultadoFiltrado, resultado } = useFileContext();
  const [buscaFinal, setBuscaFinal] = useState<string | undefined>('');

  function setClinica(id_clinica: number): React.MouseEventHandler<HTMLDivElement> {
    return (event): void => {
      setResultadoFiltrado(resultado?.filter((item: any) => item.id_clinica === id_clinica))
    }
  }
  function setMedico(id_medico: number): React.MouseEventHandler<HTMLElement> {
    return (event): void => {
      setResultadoFiltrado(resultado?.filter((item: any) => item.id_medico === id_medico))
    }
  }

  useEffect(() => {
    if (buscou) {
      setBuscaFinal(busca)
    }
  }, [buscou])

  return (
    <div className={`${styles.divPrincipal} animeLeft`}>
      {resultadoFiltrado?.length === 0 && buscou && busca ?
        <>
          <h1>Resultado da busca: "{buscaFinal}"</h1>
          <p>Nenhum resultado encontrado para a busca "{buscaFinal}".</p>
        </>
        : resultadoFiltrado?.length !== 0 ?
          <>
            <h1>Resultado da busca: "{buscaFinal}"</h1>
            <div className={styles.divResultados}>
              {medicos && medicos.map((medico, index) => (
                <div key={index} className={styles.resultado}>
                  <span className={styles.separador}></span>
                  <h3>{medico.nome}</h3>
                  <p>{medico.sgl_crm} {medico.num_crm} - {medico.especialidade_padrao.replace(/\;/g, " / ")}</p>
                  {medico.clinicas?.map((clinica, index) => (
                    <div key={index} className={styles.clinicaMedico} onClick={setClinica(clinica.id_clinica)}>
                      <p className={styles.nomeClinica}>{clinica.nom_clinica}</p>
                      <p className={styles.dadosClinica}>{clinica.endereco}</p>
                      <p className={styles.dadosClinica}>{clinica.telefone.replace(/;/g, ' / ')}</p>
                    </div>
                  ))}
                </div>
              ))
              }
              {clinicas && clinicas.map((clinica, index) => (
                <div key={index} className={styles.resultado}>
                  <span className={styles.separador}></span>
                  <h3>{clinica.nom_clinica}</h3>
                  <p>{clinica.endereco}</p>
                  <p>{clinica.telefone.replace(';', ' / ').replace(';', ' / ')}</p>
                  <div key={index} className={styles.medicosClinica}>
                    {clinica.medicos?.map((medico: any, index: any) => (
                      <p onClick={setMedico(medico.id_medico)} key={index} className={styles.medicoClinica}>{medico.nome} - {medico.especialidade_padrao.replace(/\;/g, " / ")} - {medico.sgl_crm} {medico.num_crm}</p>
                    ))}
                  </div>
                </div>
              ))
              }
            </div></> : <></>}
    </div>
  )
}

export default Resultado