import React, { useEffect } from 'react'
import styles from './ConveniosGeralGer.module.css'
import { useAssociadoContext } from '../../useAssociadoContext'
import { Link } from 'react-router-dom'
import { GET_CONVENIOS, GET_CONVENIOS_GERENCIAL } from '../../api'
import { useUserContext } from '../../UserContext'
import Head from '../Helper/Head'
import TituloAssociado from '../Helper/TituloAssociado'
import Button from '../Forms/Button'

const ConveniosGeralGer = () => {
  const { convenios, setConvenios } = useAssociadoContext();
  const { token } = useUserContext();

  useEffect(() => {
    async function getConvenios() {
      const { url, options } = GET_CONVENIOS_GERENCIAL();
      const response = await fetch(url, options);
      const data = await response.json();
      if (!response.ok) throw new Error('Erro no get convenios.')
      setConvenios(data);
    }
    getConvenios();
  }, [])
  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Convenios Geral"
        description="Área do associado"
      />
      <div className={styles.divTitulo}>
        <TituloAssociado titulo='Convênios gerais' />
        <Link to='novo-convenio'>
          <Button descricao='+ novo convênio' classe='submit' />
        </Link>
      </div>
      <div className={styles.divConvenios}>
        {convenios && convenios?.map((item: any, index) => (
          item.restrito === false ?
            <Link to={`/area-gerencial/convenios/${item.id}`} key={index}>{item.nome}</Link> : null
        ))}
      </div>
    </section>
  )
}

export default ConveniosGeralGer