import React, { useEffect, useState } from 'react'
import styles from './HomeGerencial.module.css'
import TituloAssociado from '../Helper/TituloAssociado'
import ListFiles from '../ServerSide/findFromS3';
import { Link, useNavigate } from 'react-router-dom';
import Head from '../Helper/Head';
import { useUserContext } from '../../UserContext';
import Input from '../Forms/Input';
import useForm from '../Hooks/useForm';
import { GET_AVISO, POST_AVISO } from '../../api';
import Button from '../Forms/Button';
import { setEmitFlags } from 'typescript';
import Modal from 'react-modal';



const HomeGerencial = () => {
  const [titulo, setTitulo] = useState(null);
  const [mensagem, setMensagem] = useState(null);
  const [mensagemReq, setMensagemReq] = useState('')
  const [emailSent, setEmailSent] = useState(false);
  const { loading, setLoading } = useUserContext();
  const [ativo, setAtivo] = useState<boolean>(false);

  function fecharModal() {
    setEmailSent(false);
  }

  function handleMessage(event: any) {
    setMensagem(event.target.value);
  }

  function handleTitulo(e: any) {
    setTitulo(e.target.value)
  }

  function handleAtivo(e: any) {
    setAtivo(!ativo)
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    try {
      setLoading(true)
      const { url, options } = POST_AVISO({ titulo: titulo, ativo: ativo, texto: mensagem });
      const response = await fetch(url, options);
      const json = await response.json();
      if (!response.ok) throw new Error(json.mensagem)
      setMensagemReq('Aviso Alterado com secusso.')
      setEmailSent(true)
    } catch (error) {
      console.log(e.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    async function getAviso() {
      try {
        setLoading(true)
        const { url, options } = GET_AVISO();
        const response = await fetch(url, options);
        const json = await response.json();
        if (!response.ok) throw new Error(json.message)
        setMensagem(json.texto)
        setTitulo(json.titulo);
        setAtivo(json.ativo)
      } catch (error: any) {
        console.log(error.message)
        setLoading(false)
      } finally {
        setLoading(false)
      }
    }
    getAviso();
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head title='Área Gerencial - Aviso' description='Área Gerencial' />
      <TituloAssociado titulo='aviso' />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagemReq}
        </p>
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
      <div className={styles.divAviso}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.ativarAviso}>
            <input type='checkbox' name='ativarAviso' id='ativar' checked={ativo} onChange={handleAtivo} />
            <label htmlFor="ativar">Ativar aviso</label>
          </div>
          <Input label='Título' type='text' name='titulo' placeholder='Digite o titulo do aviso aqui' value={titulo ? titulo : ''} onChange={handleTitulo} />
          <div className={styles.mensagem}>
            <label htmlFor="mensagem">Texto do aviso</label>
            <textarea
              id="mensagem"
              placeholder="Digite o texto do aviso aqui..."
              onChange={handleMessage}
              value={mensagem ? mensagem : ""}
            />
          </div>
          <Button classe='submit' descricao='Salvar' disabled={loading} />
        </form>

      </div>
    </section>
  )
}

export default HomeGerencial