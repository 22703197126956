import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './Anexos.module.css';
import Input from '../Forms/Input';
import { useFileContext } from '../../useFileContext';
import InputFile from '../Forms/InputFile';
import { useLocation } from 'react-router-dom';

// interface Anexos{
//   campo: number;
//   label: string;
//   nome: string;
// }

const Anexos = ({ campo, label, nome, accept }) => {
  const location = useLocation();
  const { pathname } = location;
  const { uploadedFiles = [], setUploadedFiles } = useFileContext();

  /* const setValue = (e: any) => { */
  const onDrop = useCallback(
    (acceptedFiles) => {
      // Atualiza o estado para incluir os arquivos adicionados
      setUploadedFiles((prevFiles) => {
        const newFiles = [...(prevFiles || [])];

        // newFiles[campo] = e.target.files[0];
        newFiles[campo] = acceptedFiles[0];

        // Adiciona os arquivos restantes na nova posição
        for (let i = 0; i < campo + 1 /* (prevFiles || []).length */; i++) {
          if (i !== campo) {
            if (!newFiles[i]) {
              newFiles[i] = undefined;
              // newFiles[i] = (prevFiles || [])[i]
            }
          }
        }

        return newFiles;
      });
    },
    [uploadedFiles],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: accept,
  });
  return (
    <div className={styles.divAnexo}>
      {/* <InputFile name={nome} label={label} onChange={setValue} classe='input100'/> */}
      <Input
        name={nome}
        label={
          label +
          (pathname.includes('cadastrar-logo')
            ? ' (*extensões aceitas: .jpeg, .jpg, .png)'
            : ' (*extensões aceitas: .pdf, .docx)')
        }
        type="text"
        placeholder=""
        classe="input100"
        value={uploadedFiles[campo]?.name || ''}
        anexo={true}
        maxLength={1000}
      />
      <div {...getRootProps()} className={styles.anexo}>
        <input {...getInputProps()} />
        <p>anexar</p>
      </div>
    </div>
  );
};

export default Anexos;
