import React, { useEffect, useState } from 'react'
import styles from './CircularesConv.module.css'
import Head from '../Helper/Head'
import TituloAssociado from '../Helper/TituloAssociado'
import { useAssociadoContext } from '../../useAssociadoContext'
import { useUserContext } from '../../UserContext'
import { GET_CIRCULARES_CONVENIO } from '../../api'
import SubTituloAss from '../Helper/SubTituloAss'
import { Link } from 'react-router-dom'

const CircularesConv = () => {
  const { convenio } = useAssociadoContext()
  const [circulares, setCirculares] = useState([])
  const [circularesFilt, setCircularesFilt] = useState([])
  const [anos, setAnos] = useState<any[]>();
  const { loading, setLoading } = useUserContext();

  useEffect(() => {
    function getYears() {
      const anos: any[] = []
      circulares?.map((item: any) => {
        const ano = parseInt(item.nome_arquivo.substr(14, 4));
        if (!anos.includes(ano) && ano) {
          anos.push(ano);
        }
      })
      anos.sort((a, b) => b - a)

      return anos;
    }
    setAnos(getYears());
  }, [circulares])

  useEffect(() => {
    async function getCirculares() {
      try {
        setLoading(true)
        const { url, options } = GET_CIRCULARES_CONVENIO({ id_convenio: convenio[0].id });
        const response = await fetch(url, options);
        const json = await response.json();
        if (!response.ok) throw new Error(json.message)
        if (json) {
          // const mappedFiles = json.map((file: any) => {
          //   return { nome_arquivo: file.nome_arquivo, url: file.url }; // Mapeando os objetos para o formato FileProps
          // });
          const mappedFiles = json.reduce((acc: any, file: any) => {
            // Verifica se o nome do arquivo já existe no array
            const isUnique = acc.every((item: any) => item.nome_arquivo !== file.nome_arquivo);

            // Se for único, adiciona ao array
            if (isUnique) {
              acc.push({ nome_arquivo: file.nome_arquivo, url: file.url });
            }

            return acc;
          }, []);
          setCirculares(mappedFiles);

          mappedFiles.sort((a: any, b: any) => {
            // Extrai os anos dos nomes dos arquivos (assumindo que o ano está na posição 6 e 7)
            const yearA = parseInt(a.nome_arquivo.substr(14, 4));
            const yearB = parseInt(b.nome_arquivo.substr(14, 4));

            // Extrai os números dos nomes dos arquivos (assumindo que o número começa na posição 10)
            const numberA = parseInt(a.nome_arquivo.substr(11, 3));
            const numberB = parseInt(b.nome_arquivo.substr(11, 3));

            // Compara primeiro pelo ano
            if (yearA !== yearB) {
              return yearB - yearA; // Ordem decrescente (do mais recente para o mais antigo)
            }
            // Se os anos forem iguais, compara pelo número
            return numberB - numberA; // Ordem crescente (do menor para o maior)
          });
          setCircularesFilt(mappedFiles);
        }
      } catch (error: any) {
        console.log(error.message)
      } finally {
        setLoading(false)
      }
    }
    getCirculares();
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title={"Área Gerencial - Circulares " + convenio[0].nome}
        description="Área Gerencial"
      />
      <TituloAssociado titulo={'circulares - ' + convenio[0].nome} />
      <div className={styles.divCirculares}>
        {circularesFilt.length > 0 ? anos?.length !== 0 && anos?.map((ano: any, index: any) => (
          <div className={styles.divAno} key={index}>
            <SubTituloAss subTitulo={'circulares - ano ' + ano} />
            {circularesFilt?.map((item: any, index) => (
              new RegExp("^.{14}" + ano).test(item.nome_arquivo) ? <Link to={item.url} target='blank' key={index}>{'Nº ' + item.nome_arquivo.replace('circulares/', '').replace('.pdf', '').replace(/\+/g, ' ')}</Link> : ""
            ))}
          </div>
        )) : <p className={styles.alerta}>Não existem circulares para esse convênio ainda.</p>}

      </div>
    </section>
  )
}

export default CircularesConv