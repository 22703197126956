import amai from '../../Assets/logo-amai.png';
import afeb from '../../Assets/afeb.png'
import allianz from '../../Assets/allianz.png'
import golden from '../../Assets/golden.png'
import brb from '../../Assets/brb.png'
import caesan from '../../Assets/caesan.png'
import saudeCaixa from '../../Assets/saude-caixa.png'
import casec from '../../Assets/casec.png'
import casembrapa from '../../Assets/casembrapa.png'
import cbmdf from '../../Assets/cmbdf.png'
import conab from '../../Assets/conab.png'
import embratel from '../../Assets/embratel.png'
import evida from '../../Assets/evida.png'
import faceb from '../../Assets/faceb.png'
import fapes from '../../Assets/fapes.png'
import fascal from '../../Assets/fascal.png'
import fusex from '../../Assets/fusex.png'
import gama from '../../Assets/gama.png'
import inas from '../../Assets/inas.png'
import sulamerica from '../../Assets/sulamerica.png'
import mediservice from '../../Assets/mediservice.png'
import notredame from '../../Assets/notredame.png'
import omint from '../../Assets/omint.png'
import bacen from '../../Assets/bacen.png'
import pfsaude from '../../Assets/pf-saude.png'
import planassiste from '../../Assets/planassiste.png'
import postal from '../../Assets/postal.png'
import proasa from '../../Assets/proasa.png'
import real from '../../Assets/real.png'
import samp from '../../Assets/samp.png'
import petrobras from '../../Assets/petrobras.png'
import serpro from '../../Assets/serpro.png'
import sis from '../../Assets/sis.png'
import stf from '../../Assets/stf.png'
import stj from '../../Assets/stj.png'
import stm from '../../Assets/stm.png'
import tjdft from '../../Assets/tjdft.png'
import tre from '../../Assets/tre.png'
import trf from '../../Assets/trf.png'
import trt from '../../Assets/trt.png'
import tst from '../../Assets/tst.png'
import unafisco from '../../Assets/unafisco.png'
import unimed from '../../Assets/unimed.png'


const associados = [
  {
    link_clinica: 'https://afeb.brasal.com.br/login',
    url_logo: afeb,
    nome: 'AFEB',
  },
  {
    link_clinica: 'https://www.allianz.com.br/',
    url_logo: allianz,
    nome: 'Allianz',
  },
  {
    link_clinica: 'https://saudebrb.com.br/',
    url_logo: brb,
    nome: 'Saúde Brb',
  },
  {
    link_clinica: 'http://caesan.bios.inf.br/login-autorizacoes/',
    url_logo: caesan,
    nome: 'CAESAN',
  },
  // {
  //   link_clinica: 'https://www.capesesp.com.br/',
  //   url_logo: capesesp,
  //   nome: 'CAPESESP',
  // },
  {
    link_clinica: 'https://www.casecsaude.com.br/',
    url_logo: casec,
    nome: 'Casec',
  },
  {
    link_clinica: 'https://casembrapa.com.br/public/',
    url_logo: casembrapa,
    nome: 'Casembrapa',
  },
  {
    link_clinica: 'https://www.cbm.df.gov.br/hospitais-e-clinicas-credenciados/',
    url_logo: cbmdf,
    nome: 'CBM-DF',
  },
  {
    link_clinica: 'https://www.conab.gov.br/institucional/conab-corporativa/assistencia-a-saude',
    url_logo: conab,
    nome: 'Conab',
  },
  {
    link_clinica: 'https://www.fundacaotelos.com.br/',
    url_logo: embratel,
    nome: 'Embratel',
  },
  {
    link_clinica: 'https://evida.org.br/',
    url_logo: evida,
    nome: 'E-Vida',
  },
  // {
  //   link_clinica: 'https://www.faceb.com.br/saude/',
  //   url_logo: faceb,
  //   nome: 'FACEB',
  // },
  {
    link_clinica: 'https://www.fapes.com.br/saude/',
    url_logo: fapes,
    nome: 'FAPES',
  },
  {
    link_clinica: 'https://www.cl.df.gov.br/cldf-saude',
    url_logo: fascal,
    nome: 'Fascal',
  },
  {
    link_clinica: 'https://www2.eb.mil.br/web/interno/fusex',
    url_logo: fusex,
    nome: 'Fusex',
  },
  {
    link_clinica: 'https://www.gamasaude.com.br/',
    url_logo: gama,
    nome: 'Gama Saúde',
  },
  {
    link_clinica: 'https://www.goldencross.com.br/',
    url_logo: golden,
    nome: 'Golden Cross',
  },
  {
    link_clinica: 'https://www.inas.df.gov.br/',
    url_logo: inas,
    nome: 'INAS',
  },
  // {
  //   link_clinica: 'https://www4.infraero.gov.br/',
  //   url_logo: infraero,
  //   nome: 'INFRAERO',
  // },
  // {
  //   link_clinica: 'https://www.mediservice.com.br/',
  //   url_logo: mediservice,
  //   nome: 'Mediservice',
  // },
  {
    link_clinica: 'https://www.gndi.com.br/',
    url_logo: notredame,
    nome: 'Notre Dame',
  },
  {
    link_clinica: 'https://www.omint.com.br/',
    url_logo: omint,
    nome: 'Omint',
  },
  {
    link_clinica: 'https://www.bcb.gov.br/acessoinformacao/bcsaude',
    url_logo: bacen,
    nome: 'Bacen',
  },
  {
    link_clinica: 'https://www.gov.br/pf/pt-br/assuntos/pf-saude',
    url_logo: pfsaude,
    nome: 'PF Saúde',
  },
  {
    link_clinica: 'https://planassiste.mpu.mp.br/',
    url_logo: planassiste,
    nome: 'Planassiste',
  },
  {
    link_clinica: 'https://www.postalsaude.com.br/',
    url_logo: postal,
    nome: 'Postal Saúde',
  },
  {
    link_clinica: 'https://producao-mv.proasa.org.br/mvsaudeweb/#/inicio',
    url_logo: proasa,
    nome: 'Proasa',
  },
  {
    link_clinica: 'https://www.frg.com.br/',
    url_logo: real,
    nome: 'Real Grandeza',
  },
  {
    link_clinica: 'https://www.agmp.org.br/saude/samp/',
    url_logo: samp,
    nome: 'SAMP',
  },
  {
    link_clinica: 'https://saude.caixa.gov.br/',
    url_logo: saudeCaixa,
    nome: 'Saúde Caixa',
  },
  {
    link_clinica: 'https://saudepetrobras.com.br/',
    url_logo: petrobras,
    nome: 'Saúde Petrobrás',
  },
  {
    link_clinica: 'https://www.transparencia.serpro.gov.br/acesso-a-informacao/servidores/plano-de-saude',
    url_logo: serpro,
    nome: 'Serpro',
  },
  {
    link_clinica: 'https://www12.senado.leg.br/institucional/sis',
    url_logo: sis,
    nome: 'SIS',
  },
  {
    link_clinica: 'https://saude.stf.jus.br/',
    url_logo: stf,
    nome: 'STF MED',
  },
  // {
  //   link_clinica: 'https://proser.stj.jus.br/WebAppPortal/Login?ReturnUrl=%2fWebAppPortal%3f',
  //   url_logo: stj,
  //   nome: 'Pró-Ser STJ',
  // },
  {
    link_clinica: 'https://www.stm.jus.br/plano-de-saude/plas-pagina-inicial',
    url_logo: stm,
    nome: 'PLAS / JMU STM',
  },
  {
    link_clinica: 'https://www.stm.jus.br/plano-de-saude/plas-pagina-inicial',
    url_logo: stm,
    nome: 'PLAS / JMU STM',
  },
  {
    link_clinica: 'https://portal.sulamericaseguros.com.br/',
    url_logo: sulamerica,
    nome: 'Sul América',
  },
  {
    link_clinica: 'https://www.tre-df.jus.br/institucional/tre-saude-2',
    url_logo: tre,
    nome: 'TRE Saúde',
  },
  {
    link_clinica: 'https://prosocial.trf1.jus.br/e-prosocial/index.aspx',
    url_logo: trf,
    nome: 'Pró-Social TRF',
  },
  {
    link_clinica: 'https://www.trt10.jus.br/servicos/saude10/',
    url_logo: trt,
    nome: 'TRT Saúde',
  },
  {
    link_clinica: 'https://www.tst.jus.br/web/tstsaude',
    url_logo: tst,
    nome: 'TST Saúde',
  },
  {
    link_clinica: 'https://unafiscosaude.org.br/site/',
    url_logo: unafisco,
    nome: 'Unafisco',
  },
  {
    link_clinica: 'https://www.unimedvtrp.com.br/',
    url_logo: unimed,
    nome: 'Unimed VTRP',
  },
  
];

export default associados;
