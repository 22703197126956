import React from 'react'
import { Route, Routes } from 'react-router-dom'
import LoginForm from './LoginForm'
import { UserStorage } from '../../UserContext'
import Head from '../Helper/Head'
import EsqueceuSenha from './EsqueceuSenha'
import RecuperaraSenha from './RecuperaraSenha'

const Login = () => {
  return (
      <Routes>
        <Route path="/" element={<LoginForm />}/>
        <Route path="/esqueceu" element={<EsqueceuSenha />}/>
        <Route path="/recuperar/*" element={<RecuperaraSenha />}/>
      </Routes>
  )
}

export default Login