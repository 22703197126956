import React, { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { TOKEN_VALIDATE, USER_GET, USER_LOGIN } from './api';
import { useLocation, useNavigate } from 'react-router-dom';
import { History, createBrowserHistory } from 'history'


export interface Circular {
  Key: string;
}


interface UserContextType {
  userLogout: () => Promise<void>;
  error: string | null;
  setError: React.Dispatch<React.SetStateAction<string | null>>
  menuVisible: boolean;
  setMenuVisible: React.Dispatch<React.SetStateAction<boolean>>
  loading: boolean;
  login: boolean | null;
  setLogin: React.Dispatch<React.SetStateAction<boolean | null>>;
  loginGer: boolean | null;
  data: any;
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
  tokenGer: string | null;
  setTokenGer: React.Dispatch<React.SetStateAction<string | null>>;
  circulares: Circular[] | null;
  setCirculares: React.Dispatch<React.SetStateAction<Circular[] | null>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  history: History;
  /* userLogout: () => Promise<void>; */
  userLogin: (username: string, password: string) => Promise<void>;
}

export const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserStorage: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [data, setData] = useState(null);
  const [adminGer, setAdminGer] = useState<boolean | null>(null);
  const [login, setLogin] = useState<boolean | null>(null);
  const [loginGer, setLoginGer] = useState<boolean | null>(null);
  const [token, setToken] = useState<string | null>(null)
  const [tokenGer, setTokenGer] = useState<string | null>(null)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [circulares, setCirculares] = useState<Circular[] | null>(null)
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const history: History = createBrowserHistory();
  const [menuVisible, setMenuVisible] = useState(false);



  async function getUser(token: string) {
    window.localStorage.removeItem('tokenGer')
    const { url, options } = TOKEN_VALIDATE(token);
    const response = await fetch(url, options);
    const json = await response.json();
    setData(json);
    setToken(token)
    setLogin(true);
    if (!pathname.includes('area-associado') && !pathname.includes('login-gerencial') && !pathname.includes('sobrenos') && !pathname.includes(typeof 1) && !pathname.includes('associados') && !pathname.includes('profissionais') && !pathname.includes('area-gerencial') && !pathname.includes('convenios') && !pathname.includes('faleconosco') && !pathname.includes('novoassociado') && pathname !== '/') navigate('/area-associado')
    // if (shouldNavigate()) navigate('/area-associado');
  }
  async function getUserGer(token: string) {
    window.localStorage.removeItem('token')
    const { url, options } = TOKEN_VALIDATE(token);
    const response = await fetch(url, options);
    const { num_cnpj } = await response.json();
    setData(num_cnpj);
    setTokenGer(token)
    setLoginGer(true);
    if (!pathname.includes('area-gerencial') && !pathname.includes('login-associado') && !pathname.includes('sobrenos') && !pathname.includes(typeof 1) && !pathname.includes('associados') && !pathname.includes('profissionais') && !pathname.includes('convenios') && !pathname.includes('faleconosco') && !pathname.includes('novoassociado') && pathname !== '/') navigate('/area-gerencial/aviso')
    // if (shouldNavigate()) navigate('/area-associado');
  }


  async function userLogin(username: string, password: string) {
    try {
      setError(null);
      setLoading(true);
      const { url, options } = USER_LOGIN({ username: username, password: password });
      const tokenRes = await fetch(url, options);
      const { token, message, admin } = await tokenRes.json();
      if (!tokenRes.ok) throw new Error(`${message}`);
      if (admin) {
        if (pathname.includes('login-associado')) {
          window.localStorage.setItem('token', token);
          await getUser(token)
        } else {
          window.localStorage.setItem('tokenGer', token);
          setAdminGer(admin)
          await getUserGer(token)
        }
      } if (!admin) {
        window.localStorage.setItem('token', token);
        setAdminGer(admin)
        await getUser(token)
      }
    } catch (err: any) {
      setError(err.message);
      console.log(err.message)
      setLogin(false);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    window.localStorage.setItem('lastVisitedRoute', window.location.pathname);
  }, [navigate])

  const userLogout = useCallback(async function () {
    setData(null);
    setError(null);
    setLoading(false);
    setLogin(false);
    setLoginGer(false)
    setToken(null);
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('tokenGer');
  }, [navigate])

  React.useEffect(() => {
    async function autologin() {
      const token = window.localStorage.getItem('token');
      const tokenGer = window.localStorage.getItem('tokenGer');
      if (token) {
        try {
          window.localStorage.removeItem('tokenGer')
          setError(null);
          // setLoading(true);
          const { url, options } = TOKEN_VALIDATE(token);
          const response = await fetch(url, options);
          if (!response.ok) throw new Error('Token Inválido');
          await getUser(token);
          setToken(token)
        } catch (err) {
          userLogout();
        } finally {
          setLoading(false);
        }
      }
      if (tokenGer) {
        window.localStorage.removeItem('token')
        try {
          setError(null);
          // setLoading(true);
          const { url, options } = TOKEN_VALIDATE(tokenGer);
          const response = await fetch(url, options);
          if (!response.ok) throw new Error('Token Inválido');
          await getUserGer(tokenGer);
          setTokenGer(tokenGer)
        } catch (err) {
          userLogout();
        } finally {
          setLoading(false);
        }
      }
    }
    autologin();
  }, [userLogout])

  return (
    <UserContext.Provider value={{ menuVisible, setMenuVisible, history, tokenGer, loginGer, setLoading, circulares, setCirculares, error, setError, loading, login, data, userLogout, userLogin, token, setLogin, setToken, setTokenGer }}>
      {children}
    </UserContext.Provider>
  )
}

export const useUserContext = (): UserContextType => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUserContext must be used within a UserStorage');
  }

  return context;
};

export default UserContext