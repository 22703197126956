import React, { useEffect, useState } from 'react'
import styles from './InfosConv.module.css'
import Head from '../Helper/Head'
import TituloAssociado from '../Helper/TituloAssociado'
import { useAssociadoContext } from '../../useAssociadoContext'
import { useUserContext } from '../../UserContext'
import { GET_CIRCULARES_CONVENIO, GET_INFOS_CONVENIO } from '../../api'
import SubTituloAss from '../Helper/SubTituloAss'
import { Link } from 'react-router-dom'

const InfosConv = () => {
  const { convenio } = useAssociadoContext()
  const [infos, setInfos] = useState([])
  const { loading, setLoading } = useUserContext();

  useEffect(() => {
    async function getCirculares() {
      try {
        setLoading(true)
        const { url, options } = GET_INFOS_CONVENIO({ id_convenio: convenio[0].id });
        const response = await fetch(url, options);
        const json = await response.json();
        if (!response.ok) throw new Error(json.message)
        console.log(json)
        setInfos(json);
      } catch (error: any) {
        console.log(error.message)
      } finally {
        setLoading(false)
      }
    }
    getCirculares();
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title={"Área Gerencial - Informativos " + convenio[0].nome}
        description="Área Gerencial"
      />
      <TituloAssociado titulo={'informativos - ' + convenio[0].nome} />
      <div className={styles.divCirculares}>
        {infos.length > 0 ? infos?.map((item: any, index) => (
          <Link to={item.url} target='blank' key={index}>{item.nome_arquivo.replace('informativos/', '').replace('.pdf', '')}</Link>
        )) : <p className={styles.alerta}>Não existem informativos para esse convênio ainda.</p>}
        { }
      </div>
    </section>
  )
}

export default InfosConv