import React, { useEffect, useState } from 'react'
import styles from './Extratos.module.css'
import Head from '../Helper/Head'
import TituloAssociado from '../Helper/TituloAssociado'
import { Link } from 'react-router-dom'
import ListFiles from '../ServerSide/findFromS3'
import { useUserContext } from '../../UserContext'
import SubTituloAss from '../Helper/SubTituloAss'

const Extratos = () => {
  const [pagas, setPagas] = useState<Object[] | undefined>([{}])
  const [naoPagas, setNaoPagas] = useState<any>()
  const { data } = useUserContext()

  useEffect(() => {
    function extractDate(key: any) {
      const filename = key.split('/')[2]; // Remove a parte "extratos/" da chave
      const parts = filename.split('-'); // Divide a string em partes separadas pelo caractere '-'
      const datePart = parts[parts.length - 1]; // Obtém a última parte, que deve ser a data
      const dateStr = datePart.split('.')[0]; // Remove a extensão do arquivo para obter a data
      if (key.includes('DIRF')) {
        const year = parseInt(dateStr.substr(4, 4)); // Extrai o ano
        return new Date(year, 12, 31)
      } else {
        const year = parseInt(dateStr.substr(4, 4)); // Extrai o ano
        const month = parseInt(dateStr.substr(2, 2)); // Extrai o mês
        const day = parseInt(dateStr.substr(0, 2)); // Extrai o dia
        return new Date(year, month - 1, day);
      }
    }

    function extractCnpj(key: any) {
      const filename = key.split('/')[2]; // Remove a parte "extratos/" da chave
      const parts = filename.split('-'); // Divide a string em partes separadas pelo caractere '-'
      const cnpj = parts[1]; // Obtém a última parte, que deve ser a data

      return cnpj; // Cria um objeto Date com a data extraída
    }

    function formatDate(dado: any) {
      let filename
      if (data.pessoa_fisica) {
        filename = dado.replace('.pdf', '').slice(-8);
      } else {
        filename = dado.replace('.pdf', '').slice(-8);
      }
      if (filename.includes('DIRF')) {
        const year = filename.substr(4, 4);
        const formattedDate = 'DIRF - ' + year;
        return formattedDate
      }
      const year = filename.substr(4, 4);
      const month = filename.substr(2, 2);
      const day = filename.substr(0, 2);

      // Formata a data no formato "DD/MM/AAAA"
      const formattedDate = `${day}/${month}/${year}`;

      return formattedDate;
    }
    async function fetchExtratos() {
      try {
        let files
        if (data.pessoa_fisica) {
          files = await ListFiles('extratos/extratos/' + data.num_cpf, "");
        } else {
          files = await ListFiles('extratos/extratos/' + data.num_cnpj, "");
        }
        const sortedFiles = files?.sort((a, b) => {
          const dateA: any = extractDate(a.Key);
          const dateB: any = extractDate(b.Key);
          return dateB - dateA;
        })
        const filesDates = sortedFiles?.map((item) => {
          if (data.pessoa_fisica === true) {
            const dado = formatDate(item.Key);
            const cnpj = extractCnpj(item.Key)
            return { Key: item.Key, data: dado, cnpj: cnpj }
          } else {
            const dado = formatDate(item.Key);
            return { Key: item.Key, data: dado }
          }
        })
        setPagas(filesDates)

      } catch (error) {
        console.error("Erro ao buscar arquivos:", error);
      }
    }
    async function fetchPrevisoes() {
      try {
        let files
        if (data.pessoa_fisica) {
          files = await ListFiles('extratos/previsao/' + data.num_cpf, '');
        } else {
          files = await ListFiles('extratos/previsao/' + data.num_cnpj, '');
        }
        const sortedFiles = files?.sort((a, b) => {
          const dateA: any = extractDate(a.Key);
          const dateB: any = extractDate(b.Key);
          return dateB - dateA;
        })
        const filesDates = sortedFiles?.map((item) => {

          if (data.pessoa_fisica === true) {
            const dado = formatDate(item.Key);
            const cnpj = extractCnpj(item.Key)
            return { Key: item.Key, data: dado, cnpj: cnpj }
          } else {
            const dado = formatDate(item.Key);
            return { Key: item.Key, data: dado }
          }

        })
        setNaoPagas(filesDates)
      } catch (error) {
        console.error("Erro ao buscar arquivos:", error);
      }
    }
    fetchExtratos();
    fetchPrevisoes();
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head title='Área do Associados - Extratos' description='Área do Associado - Extratos' />
      <TituloAssociado titulo='extratos' />
      <div className={styles.divDados}>
        <p><span>*Os pagamentos da AMAI são disponibilizados dias 10 e 25 de cada mês </span>{"(caso caia em feriado ou final de semana será disponibilizado no próximo dia útil)"}</p>
        <br />
        <p className={styles.alerta}>*O pagamento do extrato está condicionado ao envio da nota fiscal.</p>
        <br />
        <p>Em caso de não entrega da Nota Fiscal em algum período, considerar a data do último repasse para emissão da mesma.</p>
        <br />
        <p>As notas enviadas até as 16:00 geralmente são pagas no mesmo dia, as enviadas após esse horário serão pagas no dia útil seguinte.</p>
        <br />
        <br />
        <h3>Dados para emissão da NF:</h3>
        <p><span>Razão Social:</span> Associação Médica de Assistência Integrada – AMAI</p>
        <p><span>CNPJ:</span> 02.561.546/0001-47</p>
        <p><span>I.E.:</span> 07.324.859/001-09</p>
        <p><span>CEP:</span> 72115-700</p>
        <p><span>Endereço:</span> Prime Excelência Médica - Setor "C" Norte Área Especial 1 Lote 12 Bloco C Loja 10</p>
        <p><span>Telefone:</span> (61) 3351-6245</p>
        <p><span>E-mail:</span> amai.contabilidade@gmail.com</p>
      </div>
      <div className={styles.divExtratos}>
        
        <div className={styles.divLinks}>
          <SubTituloAss subTitulo='Demonstrativo de pagamento (Emissão de Nota Fiscal)' />
          {pagas?.map((item: any, index) => (
            <div className={styles.divItem}>
              <Link className={styles.link} to={'https://site-amai.s3.sa-east-1.amazonaws.com/' + item.Key} key={index} target='_blank'>
                {data.pessoa_fisica ? item.data + ' - ' + 'CNPJ: ' + item.cnpj : item.data}
              </Link>
              <span className={styles.sep}></span>
            </div>
          ))}
        </div>
        <div className={styles.divLinks}>
          <SubTituloAss subTitulo='Guias faturadas e não repassadas (Aguardando Recebimento do Convênio)' />
          <div className={styles.divItem}>
            {naoPagas && naoPagas.length > 0 ? <Link className={styles.link} to={'https://site-amai.s3.sa-east-1.amazonaws.com/' + naoPagas[0].Key} target='_blank'>
              {data.pessoa_fisica ? naoPagas[0].data + ' - ' + 'CNPJ: ' + naoPagas[0].cnpj : naoPagas[0].data}
            </Link> : <p>Nenhuma previsão de pagamento até o momento</p>}
          </div>

        </div>
      </div>
    </section>
  )
}

export default Extratos