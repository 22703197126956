import React from 'react'
import styles from './Circulares.module.css'
import circulares from '../../Assets/circulares-img.jpg'
import Button from '../Forms/Button'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'

const Circulares = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  return (
    <section className={`${styles.containerPrincipal} ${inView ? 'animeRight' : ''}`} ref={ref} >
      <div className={styles.divPrincipal}>
        <img src={circulares} alt="Uma mulher gritando em um megafone" />
        <div className={styles.divTextos}>
          <h1>Informações atualizadas</h1>
          <p><span>Mantenham-se atualizados</span> com informações <span ref={ref}>relevantes</span> sobre assuntos diversos.</p>
          <Link to='/login-associado'>
            <Button classe="third" descricao='acesse aqui' />
          </Link>

        </div>
      </div>
    </section>
  )
}

export default Circulares
