import React, { useEffect, useState } from 'react'
import styles from './NovoAssociado.module.css'
import Head from '../Helper/Head'
import AssociadoForm from './AssociadoForm'
import Anexos from './Anexos'
import { useFileContext } from '../../useFileContext'
import { useAssociadoContext } from '../../useAssociadoContext'
import UploadFiles from '../ServerSide/uploadToS3'
import { useUserContext } from '../../UserContext'
import { REGISTER_NEW_ASS } from '../../api'
import { format } from 'date-fns';
import Modal from "react-modal";
import Button from '../Forms/Button'

const NovoAssociado = () => {
  const { uploadedFiles = [], setUploadedFiles } = useFileContext();
  const { cnpj, razaoSocial, nomeFantasia, especialidades, inscricaoEst, numConselho, endereco, bairro, cidade, uf, cep, telefones, email, whatsapp, instagram, rt, cpf, numConselhoRT, emailRT, telefoneRT, nomeFat, telefoneFat, emailFat, resp_cadastro, tel_resp_cadastro } = useAssociadoContext();
  const { loading, setLoading, error, setError } = useUserContext();
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');

  const resetForm = () => {
    razaoSocial.setValue('');
    nomeFantasia.setValue('');
    especialidades.setValue('');
    cnpj.setValue('');
    inscricaoEst.setValue('');
    numConselho.setValue('');
    endereco.setValue('');
    bairro.setValue('');
    cidade.setValue('');
    uf.setValue('');
    cep.setValue('');
    telefones.setValue('');
    email.setValue('');
    whatsapp.setValue('');
    instagram.setValue('');
    rt.setValue('');
    cpf.setValue('');
    numConselhoRT.setValue('');
    emailRT.setValue('');
    telefoneRT.setValue('');
    nomeFat.setValue('');
    telefoneFat.setValue('');
    emailFat.setValue('');
    resp_cadastro.setValue('');
    tel_resp_cadastro.setValue('');
  };

  function verificaArray(array: any) {
    return array.some((item: any) => item === undefined)
  }
  async function handleSubmit(e: any) {
    try {
      e.preventDefault();
      if (cnpj.validate &&
        razaoSocial.validate &&
        nomeFantasia.validate &&
        especialidades.validate &&
        inscricaoEst.validate &&
        numConselho.validate &&
        endereco.validate &&
        bairro.validate &&
        cidade.validate &&
        uf.validate &&
        cep.validate &&
        telefones.validate &&
        email.validate &&
        whatsapp.validate &&
        instagram.validate &&
        rt.validate &&
        cpf.validate &&
        numConselhoRT.validate &&
        emailRT.validate &&
        telefoneRT.validate &&
        nomeFat.validate &&
        telefoneFat.validate &&
        emailFat.validate &&
        resp_cadastro.validate &&
        tel_resp_cadastro.validate &&
        cnpj.validate() &&
        razaoSocial.validate() &&
        nomeFantasia.validate() &&
        especialidades.validate() &&
        inscricaoEst.validate() &&
        numConselho.validate() &&
        endereco.validate() &&
        bairro.validate() &&
        cidade.validate() &&
        uf.validate() &&
        cep.validate() &&
        telefones.validate() &&
        email.validate() &&
        whatsapp.validate() &&
        instagram.validate() &&
        rt.validate() &&
        cpf.validate() &&
        numConselhoRT.validate() &&
        emailRT.validate() &&
        telefoneRT.validate() &&
        nomeFat.validate() &&
        telefoneFat.validate() &&
        emailFat.validate() &&
        resp_cadastro.validate() &&
        tel_resp_cadastro.validate()) {
        if (!verificaArray(uploadedFiles) && uploadedFiles.length === 9) {
          const agora = new Date();
          const dataFormatada = format(agora, 'yyyy-MM-dd');
          setLoading(true)
          const response = await UploadFiles(uploadedFiles, cnpj.value + '-', 'docs-clientes')
          const docsResp = response?.map((item: any, index) => {
            return { url: item ? item.Location : '', tipo: index + 1 };
          })
          const dadosAss = {
            nom_clinica: razaoSocial.value,
            num_cnpj: cnpj.value.replace(/\D/g, ''),
            des_endereco: endereco.value,
            bairro: bairro.value,
            num_cep: cep.value,
            data: dataFormatada,
            num_inscricao: inscricaoEst.value,
            des_email: email.value,
            nom_fantasia: nomeFantasia.value,
            telefones: telefones.value,
            especialidades: especialidades.value,
            num_reg_conselho: numConselho.value,
            cidade: cidade.value,
            uf: uf.value,
            email_clinica: email.value,
            whatsapp: whatsapp.value,
            insta_clinica: instagram.value,
            rt_clinica: rt.value,
            cpf_rt: cpf.value.replace(/\D/g, ''),
            num_conselho_rt: numConselhoRT.value,
            email_rt: emailRT.value,
            telefone_rt: telefoneRT.value,
            nome_fat: nomeFat.value,
            telefone_fat: telefoneFat.value,
            email_fat: emailFat.value,
            resp_cadastro: resp_cadastro.value,
            tel_resp_cadastro: tel_resp_cadastro.value,
            docs: docsResp
          }
          const { url, options } = REGISTER_NEW_ASS(dadosAss);
          const data = await fetch(url, options);
          const { message } = await data.json();
          if (data.status === 405) {
            setMensagem('Cliníca já cadastrada anteriormente, favor entrar em contato com a AMAI.')
            setEmailSent(true);
            return
          } else if (data.ok) {
            // const envioEmail = await enviarEmail.enviarEmailAvisoCadastroClinica();
            setMensagem('Pré cadastro efetuado com sucesso, após a aprovação do cadastro feita pela AMAI você receberá um e-mail com as instruções de login.')
            setLoading(false);
            setEmailSent(true);
          }
        } else {
          setError('Anexe todos os arquivos solicitados')
        }
      } else {
        setError('Preencha todos os campos do formulário')
      }
    } catch (e: any) {
      console.log(e)
      setLoading(false)
    } finally {
      setLoading(false)
    }

  }

  useEffect(() => {
    resetForm();
    setUploadedFiles([])
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head title='Associe-se' description='Página de Cadastro de Associado' />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={() => setEmailSent(false)}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <button onClick={() => setEmailSent(false)}>Fechar</button>
      </Modal>
      <div className={styles.divIntro}>
        <h1>Seja um associado AMAI</h1>
        <p>Venha fazer parte da família AMAI, preencha o formulário abaixo para se tornar um associado e ter acesso aos nossos serviços. Informamos que a AMAI não cobra taxa para CADASTRAMENTO de associados.</p>
      </div>
      <form className={styles.divForm} onSubmit={handleSubmit}>
        <h1>Dados da Empresa</h1>
        <AssociadoForm />
        <h1>Anexar Documentos</h1>
        <Anexos label='Contrato Social' nome='contratoSocial' campo={0} accept={{
          'application/pdf': ['.pdf'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        }} />
        <Anexos label='CNPJ' nome='cnpj' campo={1} accept={{
          'application/pdf': ['.pdf'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        }} />
        <Anexos label='Inscrição Estadual' nome='inscricaoEstadual' campo={2} accept={{
          'application/pdf': ['.pdf'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        }} />
        <Anexos label='Licença de funcionamento e Licença sanitária (ou RLE quando for o caso)' nome='licencaFunc' campo={3} accept={{
          'application/pdf': ['.pdf'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        }} />
        <Anexos label='Termo de Responsabilidade Técnica (Vigilância Sanitária quando houver) ' nome='termo' campo={4} accept={{
          'application/pdf': ['.pdf'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        }} />
        <Anexos label='Inscrição da empresa no conselho profissional' nome='incricaoEmpresa' campo={5} accept={{
          'application/pdf': ['.pdf'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        }} />
        <Anexos label='Comprovane de conta corrente' nome='comprovanteConta' campo={6} accept={{
          'application/pdf': ['.pdf'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        }} />
        <Anexos label='Comprovante de endereço' nome='comprovanteEndereco' campo={7} accept={{
          'application/pdf': ['.pdf'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        }} />
        <Anexos label='Carta solicitando a inclusão da clínica assinada de forma digital pelo responsável pela empresa' nome='cartaInclusao' campo={8} accept={{
          'application/pdf': ['.pdf'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        }} />
        <Button classe='submit' descricao={loading ? 'enviando...' : 'enviar dados'} disabled={loading} />
        <p className={styles.error}>{error}</p>
      </form>
    </section >
  )
}

export default NovoAssociado
