import React, { useEffect, useState } from 'react'
import styles from './NavAssociado.module.css'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as Sair } from "../../Assets/sair.svg";
import path from 'path';
import { useUserContext } from '../../UserContext';

const NavAssociado = () => {
  const location = useLocation();
  const { userLogout, data, menuVisible } = useUserContext()
  const { pathname } = location;

  function handleSair() {
    userLogout()
  }

  return (
    <nav className={`${styles.menu} ${menuVisible ? '' : styles.menuVisible}`}>
      <div className={styles.divTitulo}>
        <h1>Bem-vindo a {/* </h1>
        <h1> */}<span>AMAI</span></h1>
        <span className={styles.sep}></span>
      </div>
      <div className={styles.divMenu}>
        {!data?.pessoa_fisica && <Link to='cadastrar-logo' className={pathname.includes('cadastrar-logo') ? styles.selected : styles.itemMenu}>Cadastrar logomarca</Link>}
        <Link to='circulares' className={pathname.includes('circulares') ? styles.selected : styles.itemMenu}>Circulares</Link>
        <Link to='convenios/geral' className={pathname.includes('convenios') ? styles.selected : styles.itemMenu}>Convênios</Link>
        {pathname.includes('convenios') &&
          <div className={styles.menuConv}>
            <Link to='convenios/especialidades' className={pathname.includes('especialidades') ? styles.selectedConv : styles.itemConvenio}>Relação de especialidades</Link>
            <Link to='convenios/manuais' className={pathname.includes('manuais') ? styles.selectedConv : styles.itemConvenio}>Manuais {`(Atendimento / Preenchimento)`}</Link>
            <Link to='convenios/geral' className={pathname.includes('geral') ? styles.selectedConv : styles.itemConvenio}>Geral {`(Guias / Circulares / Informativos etc.)`}</Link>
            <Link to='convenios/atendimento-restrito' className={pathname.includes('atendimento-restrito') ? styles.selectedConv : styles.itemConvenio}>Atendimento restrito - Hosp. Anchieta {`(Guias / Circulares / Informativos etc.)`}</Link>
            <Link to='convenios/formulario-entrega' className={pathname.includes('formulario-entrega') ? styles.selectedConv : styles.itemConvenio}>Formulário de entrega de guias</Link>
            <Link to='convenios/tabelas' className={pathname.includes('tabelas') ? styles.selectedConv : styles.itemConvenio}>Tabelas Gerais</Link>
          </div>}
        {!data?.pessoa_fisica && <Link to='corpo-clinico' className={pathname.includes('corpo-clinico') ? styles.selected : styles.itemMenu}>Corpo clínico</Link>}
        <Link to='extratos' className={pathname.includes('extratos') ? styles.selected : styles.itemMenu}>Extratos</Link>
        {/* <Link to='videos' className={pathname.includes('videos') ? styles.selected : styles.itemMenu}>Videos</Link> */}
      </div>
      <div className={styles.sair} onClick={handleSair}>
        <Sair className={styles.svg} />
        <p>Sair</p>
      </div>
    </nav>
  )
}

export default NavAssociado