import React from 'react'
import styles from './SubTituloAss.module.css'

interface SubTitutloProps{
  subTitulo: string;
}

const SubTituloAss = ({ subTitulo }: SubTitutloProps) => {
  return (
    <h2 className={styles.subTitulo}>{subTitulo}</h2>
  )
}

export default SubTituloAss