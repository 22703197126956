import React, { RefObject } from 'react'
import './CaixaTexto.css'
import { useInView } from 'react-intersection-observer';

interface Div{
  titulo: string,
  texto: string,
  classe: string, 
  myRef: React.LegacyRef<HTMLDivElement>
}

const CaixaTexto: React.FC<Div> = ({titulo, texto, classe, myRef}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  // React.useEffect(() => {
  //   if (myRef) {
  //     myRef.current = ref;
  //   }
  // }, [myRef, ref]);
  return (
    <div className={`divTexto ${classe}`} ref={myRef}>
      <h1>{titulo}</h1>
      <p>{texto}</p>
    </div>
  )
}

export default CaixaTexto