import React, { useEffect, useState } from 'react'
import styles from './NovoManual.module.css'
import Head from '../Helper/Head'
import TituloAssociado from '../Helper/TituloAssociado'
import { Link, useNavigate } from 'react-router-dom'
import Anexos from '../NovoAssociado/Anexos'
import Button from '../Forms/Button'
import { useUserContext } from '../../UserContext'
import { useFileContext } from '../../useFileContext'
import UploadFiles from '../ServerSide/uploadToS3'
import Modal from 'react-modal'

const NovoManual = () => {
  const { loading, setLoading, error, setError } = useUserContext();
  const [tipoUm, setTipoUm] = useState('');
  const [tipoDois, setTipoDois] = useState('');
  const { uploadedFiles = [], setUploadedFiles } = useFileContext();
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const navigate = useNavigate();

  async function handleChangeTipoUm(e: any) {
    setTipoUm(e.target.value)
  }

  async function handleChangeTipoDois(e: any) {
    setTipoDois(e.target.value)
  }

  const fecharModal = () => {
    setEmailSent(false);
    navigate(-1)
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    try {
      if (uploadedFiles.length === 1 && tipoUm && tipoDois) {
        setLoading(true)
        setError(null)
        const response = await UploadFiles(uploadedFiles, '', 'manuais/' + tipoUm + tipoDois);
        if (!response) throw new Error('Erro ao enviar documento para a nuvem.')
        setMensagem('Manual cadastrado com sucesso.');
        setEmailSent(true)
        setUploadedFiles([]);
      } else {
        setError('Preencha todos os campos')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setUploadedFiles([]);
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Novo Manual"
        description="Área do associado"
      />
      <TituloAssociado titulo='Cadastrar Manual' />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
      <div className={styles.divNovoManual}>
        <div className={styles.divCadastro}>
          <form onSubmit={handleSubmit}>
            <Anexos campo={0} label='Novo Manual' nome='manual' accept={{
              'application/msword': ['.doc'],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
              'application/pdf': ['.pdf'],
              'text/plain': ['.txt'],
            }} />
            <div className={styles.divTipo}>
              <label htmlFor="setores">Tipo de profissional</label>
              <select
                name="setores"
                id="setores"
                onChange={handleChangeTipoUm}
                value={tipoUm ? tipoUm : ""}
              >
                <option value="">Selecione um convênio...</option>
                <option value="medicos/">Médicos</option>
                <option value="demais/">Demais Profisionais</option>
              </select>
            </div>
            <div className={styles.divTipo}>
              <label htmlFor="setores">Tipo de manual</label>
              <select
                name="setores"
                id="setores"
                onChange={handleChangeTipoDois}
                value={tipoDois ? tipoDois : ""}
              >
                <option value="">Selecione um convênio...</option>
                <option value="manuais">Manual</option>
                <option value="preenchimento">Preenchimento de guias</option>
              </select>
            </div>
            <Button descricao={loading ? 'enviando...' : 'enviar'} classe='submit' disabled={loading} />
            <p className={styles.error}>{error}</p>
          </form>
        </div>
      </div>
    </section>
  )
}

export default NovoManual