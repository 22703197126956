const setores = [
  {
    nome: "Faturamento",
    email: "faturamentoamai@gmail.com"
  },
  {
    nome: "Glosas",
    email: "amai.glosas@gmail.com"
  },
  {
    nome: "Cadastro",
    email: "amai.cadastro@gmail.com"
  },
  {
    nome: "Pagamento",
    email: "amai.pagamento@gmail.com"
  },
  {
    nome: "Financeiro (recebimento de nota fiscal - clínicas)",
    email: "amai.contabilidade@gmail.com"
  },
  {
    nome: "Administração",
    email: "helena.admamai@gmail.com"
  },
]

export default setores;