import React, { useEffect } from 'react'
import logo from '../../Assets/logo-amai.png'
import styles from './Footer.module.css'
import pin from '../../Assets/pin.svg'
import tel from '../../Assets/tel.svg'
import mail from '../../Assets/mail.svg'
import insta from '../../Assets/insta.svg'
import facebook from '../../Assets/facebook.svg'
import wpp from '../../Assets/wpp.svg'
import { Link, useLocation } from 'react-router-dom'

const Footer = () => {
  const [currentRoute, setCurrentRoute] = React.useState("");
  const location = useLocation();

  useEffect(() => {
    setCurrentRoute(location.pathname)
  }, [location])

  return (
    <footer className={styles.footer}>
      <img src={logo} alt="logo da AMAI" />
      <div className={styles.divFooter}>
        <div className={styles.divUnidades}>
        <h1 className={styles.titleFooter}>unidades</h1>
          <div className={styles.divUnidade}>
            <p>ÁGUAS CLARAS SHOPPING</p>
            <div className={styles.divEndereco}>
              <img src={pin} alt="imagem de uma pin de localização" />
              <p>Avenida Araucárias lote 1835 Sala 407 4º Andar - Águas Claras</p>
            </div>
          </div>
          <div className={styles.divUnidade}>
            <p>HOSPITAL ANCHIETA</p>
            <div className={styles.divEndereco}>
              <img src={pin} alt="imagem de uma pin de localização" />
              <p>Setor “C” Norte Área Especial 8/9/10 Térreo Bloco B Loja 8-A - Taguatinga</p>
            </div>
          </div>
          <div className={styles.divUnidade}>
            <p>MIX PARK SUL</p>
            <div className={styles.divEndereco}>
              <img src={pin} alt="imagem de uma pin de localização" />
              <p>SGAS 910 Conjunto B Bloco F Sala 203 - Asa Sul</p>
            </div>
          </div>
          <div className={styles.divUnidade}>
            <p>PRIME EXCELÊNCIA MÉDICA</p>
            <div className={styles.divEndereco}>
              <img src={pin} alt="imagem de uma pin de localização" />
              <p>Área Especial 1 Setor “C” Norte Lotes 1 a 12 Bloco C Loja 10 Térreo - Taguatinga </p>
            </div>
          </div>
        </div>
          <div className={styles.divContatos}>
            <h1 className={styles.titleFooter}>contato</h1>
            <div className={styles.divContato}>
              <img src={tel} alt="ícone de um telefone" />
              <p>(61) 3351-6245 / (61) 3351-6795 / (61) 3352-7280 / (61) 3352-8570</p>
            </div>
            <div className={styles.divContato}>
              <img src={mail} alt="ícone de um envelope" />
              <p>faturamentoamai@gmail.com</p>
            </div>
            <div className={styles.divSocialMedia}>
              <Link to='https://www.instagram.com/amaisededf/' target='_blank'><img src={insta} alt="ícone do instagram" /></Link>
              <Link to='https://www.facebook.com/amaibsb/' target='_blank'><img src={facebook} alt="ícone do instagram" /></Link>
              <Link to='https://www.instagram.com/amaisededf/' target='_blank'><img src={wpp} alt="ícone do instagram" /></Link>
            </div>
          </div>
      </div>
      <div className={styles.divInfos}>
        <h1 className={styles.titleFooter}>informações</h1>
        <div className={styles.divMenu}>
          <Link to='/' className={currentRoute === "/" ? styles.selected : styles.itemMenu}>Home</Link>
          <Link to='/sobrenos' className={currentRoute === "/sobrenos" ? styles.selected : styles.itemMenu}>Sobre nós</Link>
          <Link to='/associados' className={currentRoute === "/associados" ? styles.selected : styles.itemMenu}>Associados</Link>
          <Link to='/convenios' className={currentRoute === "/convenios" ? styles.selected : styles.itemMenu}>Convênios</Link>
          <Link to='/faleconosco' className={currentRoute === "/faleconosco" ? styles.selected : styles.itemMenu}>Fale conosco</Link>
          <Link to='/areadoassociado/login' className={currentRoute.includes("/areadoassociado") ? styles.selected : styles.itemMenu}>Área do associado</Link>
        </div>
      </div>
      <p className={styles.copyright}>Associação Médica de Assistência Integrada © Todos os direitos reservados.</p>
    </footer>
  )
}

export default Footer
