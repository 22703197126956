import React, { useEffect, useState } from 'react'
import styles from './AlterarConv.module.css'
import TituloAssociado from '../Helper/TituloAssociado'
import ListFiles from '../ServerSide/findFromS3';
import { Link, useNavigate } from 'react-router-dom';
import Head from '../Helper/Head';
import { useUserContext } from '../../UserContext';
import Input from '../Forms/Input';
import useForm from '../Hooks/useForm';
import { ALTERAR_CONVENIO, GET_AVISO, NOVO_CONVENIO, POST_AVISO } from '../../api';
import Button from '../Forms/Button';
import { useAssociadoContext } from '../../useAssociadoContext';
import Modal from 'react-modal';



const AlterarConv = () => {
  const [nome, setNome] = useState('');
  const [urlAut, setUrlAut] = useState('');
  const [usuario, setUsuario] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const navigate = useNavigate()
  const [senha, setSenha] = useState('');
  const [tipoAut, setTipoAut] = useState('');
  const [instrucao, setInstrucao] = useState('');
  const [dataCred, setDataCred] = useState('');
  const [horaCred, setHoraCred] = useState('');
  const { loading, setLoading, setError, error } = useUserContext();
  const { convenio } = useAssociadoContext();
  const [convRestrito, setConvRestrito] = useState<boolean>(false);
  const [ativo, setAtivo] = useState<boolean>(false);

  function handleAtivo(e: any) {
    setAtivo(!ativo)
  }

  function handleConvRestrito(e: any) {
    setConvRestrito(e.target.checked)
  }
  function handleNome(e: any) {
    setNome(e.target.value)
  }
  function handleTipoAut(e: any) {
    setTipoAut(e.target.value)
  }
  function handleInstrucao(e: any) {
    setInstrucao(e.target.value)
  }
  function handleUsuario(e: any) {
    setUsuario(e.target.value)
  }
  function handleSenha(e: any) {
    setSenha(e.target.value)
  }
  function handleUrlAut(e: any) {
    setUrlAut(e.target.value)
  }
  function handleDataCred(e: any) {
    setDataCred(e.target.value)
  }
  function handleHoraCred(e: any) {
    setHoraCred(e.target.value)
  }

  function fecharModal() {
    setEmailSent(false);
    navigate(-1)
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    try {
      if (nome !== '') {
        setLoading(true)
        const { url, options } = ALTERAR_CONVENIO({ nome: nome, restrito: convRestrito, usuario: usuario, senha: senha, url_aut: urlAut, id: convenio ? convenio[0].id : '', ativo: ativo, instrucao_aut: instrucao, tipo_aut: tipoAut, data_alt_credencial: dataCred, hora_alt_credencial: horaCred });
        const response = await fetch(url, options);
        const json = await response.json();
        if (!response.ok) throw new Error(json.code)
        setMensagem(json.message)
        setEmailSent(true)
        setError(null)
      } else {
        setError('Preencha ao menos o nome do convênio')
      }
    } catch (error: any) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setNome(convenio[0].nome)
    setUsuario(convenio[0].usuario)
    setSenha(convenio[0].senha)
    setTipoAut(convenio[0].tipo_aut)
    setInstrucao(convenio[0].instrucao_aut)
    setUrlAut(convenio[0].url_aut)
    setConvRestrito(convenio[0].restrito)
    setDataCred(convenio[0].data_alt_credencial)
    setHoraCred(convenio[0].hora_alt_credencial)
    setAtivo(convenio[0].ativo)
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head title='Área Gerencial - Aviso' description='Área Gerencial' />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
      {convenio && <TituloAssociado titulo={'alterar convenio - ' + convenio[0].nome} />}
      <div className={styles.divNovoConv}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <Input label='Nome' type='text' name='nomeConv' placeholder='Digite o nome do convênio aqui...' value={nome} onChange={handleNome} />
          <div className={`${styles.ativarAviso} col1`}>
            <input type='checkbox' name='ativarConv' id='ativar' checked={ativo} onChange={handleAtivo} />
            <label htmlFor="ativar">Convenio Ativo</label>
          </div>
          <div className={`${styles.convRestrito} col1`}>
            <input type='checkbox' name='convRestrito' id='convRestrito' onChange={handleConvRestrito} checked={convRestrito} />
            <label htmlFor="convRestrito">Atendimento restrito ao Hospital Anchieta</label>
          </div>
          <Input label='Usuário' type='text' name='usuarioAut' placeholder='Digite o usuário para autorização...' classe='col1' value={usuario} onChange={handleUsuario} />
          <Input label='Senha' type='text' name='senhaAut' placeholder='Digite a senha para autorização...' classe='col1' value={senha} onChange={handleSenha} />
          <Input label='Data Alteração credenciais' type='text' name='dataCred' placeholder='Digite a data da alteração...' classe='col1' value={dataCred} onChange={handleDataCred} />
          <Input label='Hora Alteração credenciais' type='text' name='horaCred' placeholder='Digite a hora da alteração...' classe='col1' value={horaCred} onChange={handleHoraCred} />
          <Input label='Tipo' type='text' name='tipoAut' placeholder='Digite o tipo de autorização...' classe='col1' value={tipoAut} onChange={handleTipoAut} />
          <Input label='Instrução de autorização' type='text' name='instrucaoAut' placeholder='Digite a instrução de autorização...' classe='col1' value={instrucao} onChange={handleInstrucao} />
          <Input label='Endereço para autorização' type='text' name='enderecoAut' placeholder='Digite o site para autorização aqui' value={urlAut} onChange={handleUrlAut} />
          <Button classe='submit' descricao='Salvar' disabled={loading} />
          <p className={styles.error}>{error}</p>
        </form>
      </div>
    </section>
  )
}

export default AlterarConv