import { ReactNode, useEffect, useState } from 'react';
import { useUserContext } from '../../UserContext';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { login, setLogin, setLoading, setToken, setTokenGer, token, tokenGer } = useUserContext();
  const [loading, setLocalLoading] = useState(true);
  const url = localStorage.getItem('lastVisitedRoute');


  return login ? <>{children}</> : <Navigate to="/login-associado" />


};

export default ProtectedRoute