import { useEffect, useState } from 'react'
import styles from './NovaGuia.module.css'
import TituloAssociado from '../Helper/TituloAssociado'
import Head from '../Helper/Head'
import Anexos from '../NovoAssociado/Anexos'
import { useFileContext } from '../../useFileContext'
import Button from '../Forms/Button'
import { CADASTRAR_GUIA, CADASTRAR_INFORMATIVO } from '../../api'
import { useUserContext } from '../../UserContext'
import UploadFiles from '../ServerSide/uploadToS3'
import { useAssociadoContext } from '../../useAssociadoContext'
import Modal from 'react-modal';
import { unstable_HistoryRouter, useNavigate } from 'react-router-dom'
import { BrowserRouter as useHistory } from 'react-router-dom';


const NovaGuia = () => {
  const { uploadedFiles = [], setUploadedFiles } = useFileContext()
  const { convenio } = useAssociadoContext();
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const navigate = useNavigate();
  const { loading, setLoading, error, setError } = useUserContext();

  function fecharModal() {
    setEmailSent(false);
    navigate(-1)
  }


  async function handleSubmit(e: any) {
    e.preventDefault();
    try {
      if (uploadedFiles.length !== 0) {
        setLoading(true)
        setError(null)
        const response = await UploadFiles(uploadedFiles, '', 'guias')
        const docsResp = response?.map((item: any, index) => {
          return { url: item ? item.Location : '', nome_arquivo: item.Key };
        })
        if (!response) throw new Error('Erro ao enviar documento para a nuvem.')
        const { url, options } = CADASTRAR_GUIA({
          id_convenio: convenio[0].id,
          url: docsResp ? docsResp[0].url : '',
          nome_arquivo: docsResp ? docsResp[0].nome_arquivo : ''
        })
        const data = await fetch(url, options);
        const { message } = await data.json();
        if (!data.ok) throw new Error(message);
        setMensagem('Informativo adicionado com sucesso.')
        setEmailSent(true);
      } else if (uploadedFiles.length === 0) {
        setError('Anexe o arquivo antes de continuar')
      }
    } catch (e: any) {
      console.log(e.message)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setUploadedFiles([])
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <TituloAssociado titulo={convenio[0].nome + ' - Nova Guia'} />
      <Head title='Área Gerencial - Nova Guia' description='Área Gerencial' />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
      <div className={styles.divNovoDocEsp}>
        <form onSubmit={handleSubmit}>
          <div className={styles.divCampos}>
            <Anexos label={'Nova Guia - ' + convenio[0].nome} nome='NovaGuia' campo={0} accept={{
              'application/pdf': ['.pdf'],
              'text/plain': ['.txt'],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            }} />

          </div>
          <Button classe='submit' descricao='enviar' disabled={loading} />
          <p className={styles.error}>{error}</p>
        </form>
      </div>
    </section>
  )
}

export default NovaGuia