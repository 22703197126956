import React, { useEffect, useState } from 'react'
import styles from './NovoConvenio.module.css'
import TituloAssociado from '../Helper/TituloAssociado'
import ListFiles from '../ServerSide/findFromS3';
import { Link, useNavigate } from 'react-router-dom';
import Head from '../Helper/Head';
import { useUserContext } from '../../UserContext';
import Input from '../Forms/Input';
import useForm from '../Hooks/useForm';
import { GET_AVISO, NOVO_CONVENIO, POST_AVISO } from '../../api';
import Button from '../Forms/Button';
import Modal from 'react-modal';



const NovoConvenio = () => {
  const nome = useForm('');
  const url_aut = useForm('');
  const usuario = useForm('');
  const senha = useForm('');
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const navigate = useNavigate()
  const { loading, setLoading, setError, error } = useUserContext();
  const [convRestrito, setConvRestrito] = useState<boolean>(false);

  function handleConvRestrito(e: any) {
    setConvRestrito(e.target.checked)
  }

  function fecharModal() {
    setEmailSent(false);
    navigate('/area-gerencial/convenios')
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    try {
      if (nome.validate && nome.validate() && nome.value) {
        setLoading(true)
        const { url, options } = NOVO_CONVENIO({ nome: nome.value, restrito: convRestrito, usuario: usuario.value, senha: senha.value, url_aut: url_aut.value });
        const response = await fetch(url, options);
        const json = await response.json();
        if (!response.ok) {
          if (json.code === '23505') {
            setError('Convênio já existe')
          }
          throw new Error(json.code)
        }
        setMensagem('Convenio cadastrado com sucesso.')
        setEmailSent(true)
        setError(null)
      } else {
        setError('Preencha o nome do convênio')
      }
    } catch (error: any) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head title='Área Gerencial - Aviso' description='Área Gerencial' />
      <TituloAssociado titulo='cadastrar convênio' />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
      <div className={styles.divNovoConv}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <Input label='Nome' type='text' name='nomeConv' placeholder='Digite o nome do convênio aqui...'  {...nome} />
          <div className={`${styles.convRestrito} col2`}>
            <input type='checkbox' name='convRestrito' id='convRestrito' onChange={handleConvRestrito} />
            <label htmlFor="convRestrito">Atendimento restrito ao Hospital Anchieta</label>
          </div>
          <Input label='Usuário' type='text' name='usuarioAut' placeholder='Digite o usuário para autorização...' classe='col1' {...usuario} />
          <Input label='Senha' type='text' name='senhaAut' placeholder='Digite a senha para autorização...' classe='col1'  {...senha} />
          <Input label='Endereço para autorização' type='text' name='enderecoAut' placeholder='Digite o site para autorização aqui' {...url_aut} />
          <Button classe='submit' descricao='Salvar' disabled={loading} />
          <p className={styles.error}>{error}</p>
        </form>
      </div>
    </section>
  )
}

export default NovoConvenio