import React from 'react'
import TituloAssociado from '../Helper/TituloAssociado'
import Head from '../Helper/Head'
import styles from './Manuais.module.css'
import { Link } from 'react-router-dom'

const Manuais = () => {
  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Manuais"
        description="Área do associado"
      />
      <TituloAssociado titulo='Manuais' />
      <div className={styles.divManuais}>
        <Link to='medicos'>Manual - médicos</Link>
        <Link to='demais'>Manual - demais profissionais</Link>
      </div>
    </section>
  )
}

export default Manuais