import React, { useEffect, useState } from 'react'
import Head from '../Helper/Head'
import TituloAssociado from '../Helper/TituloAssociado'
import styles from './ExtratosGer.module.css'
import Anexos from '../NovoAssociado/Anexos'
import Button from '../Forms/Button'
import { useUserContext } from '../../UserContext'
import UploadFiles from '../ServerSide/uploadToS3'
import { useFileContext } from '../../useFileContext'
import AnexosMultiplos from '../NovoAssociado/AnexosMultiplos'
import AnexosPrevisao from '../NovoAssociado/AnexosPrevisao'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom'

const ExtratosGer = () => {
  const { loading, setLoading } = useUserContext();
  const { uploadedFiles = [], setUploadedFiles, uploadedPrev = [], setUploadedPrev } = useFileContext();
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');

  const fecharModal = () => {
    setEmailSent(false);
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    try {
      setLoading(true)
      const response = await UploadFiles(uploadedFiles, '', 'extratos/extratos')
      if (!response) throw new Error('Erro ao enviar documento para a nuvem.')
      setMensagem('Extratos enviados com sucesso')
      setEmailSent(true)
    } catch (e: any) {
      console.log(e.message)
      setLoading(false)
    } finally {
      setUploadedFiles([])
      setLoading(false)
    }
  }
  async function handleSubmitPrev(e: any) {
    e.preventDefault();
    try {
      setLoading(true)
      const response = await UploadFiles(uploadedPrev, '', 'extratos/previsao')
      if (!response) throw new Error('Erro ao enviar documento para a nuvem.')
      setMensagem('Prévias de pagamento enviadas com sucesso')
      setEmailSent(true)
    } catch (e: any) {
      console.log(e.message)
      setLoading(false)
    } finally {
      setUploadedPrev([])
      setLoading(false)
    }
  }

  useEffect(() => {
    setUploadedFiles([]);
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área Gerencial - Extratos"
        description="Área Gerencial"
      />
      <TituloAssociado titulo='Cadastrar Pagamentos' />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
      <div className={styles.divNovosExtratos}>
        <div className={styles.divCadastro}>
          <h2>Extratos</h2>
          <form onSubmit={handleSubmit}>
            <AnexosMultiplos />
            <Button descricao={loading ? 'enviando...' : 'enviar'} classe='submit' disabled={loading} />
          </form>
        </div>
        <span className={styles.sep}></span>
        <div className={styles.divCadastro}>
          <h2>Previsões</h2>
          <form onSubmit={handleSubmitPrev}>
            <AnexosPrevisao />
            <Button descricao={loading ? 'enviando...' : 'enviar'} classe='submit' disabled={loading} />
          </form>
        </div>
      </div>
    </section>
  )
}

export default ExtratosGer