import React, { useEffect, useState } from 'react'
import Head from '../Helper/Head'
import TituloAssociado from '../Helper/TituloAssociado'
import styles from './Tabelas.module.css'
import ListFiles from '../ServerSide/findFromS3'
import { ObjectList } from 'aws-sdk/clients/s3'
import { Link } from 'react-router-dom'
import SubTituloAss from '../Helper/SubTituloAss'

const Tabelas = () => {
  const [amb, setAmb] = useState<Object[]>();
  const [cbhpm, setCbhpm] = useState<Object[]>();
  const [portes, setPortes] = useState<Object[]>();


  useEffect(() => {
    async function buscaTabelas() {
      const dataAmb = await ListFiles('tabelas/amb/', "");
      const dataCbhpm = await ListFiles('tabelas/cbhpm/', '');
      const dataPortes = await ListFiles('tabelas/portes/', '');
      setAmb(dataAmb)
      setCbhpm(dataCbhpm)
      setPortes(dataPortes)
    }
    buscaTabelas();
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Tabelas Gerais"
        description="Área do associado"
      />
      <TituloAssociado titulo='Tabelas Gerais' />
      <div className={styles.divTabelas}>
        <div className={styles.divContainerLinks}>
          <SubTituloAss subTitulo={'Tabelas AMB/92 e de referências (de para TUSS)'} />
          <div className={styles.divLinks}>
            {amb && amb.map((item: any, index: any) => (
              index !== 0 ?
                <Link to={'https://site-amai.s3.sa-east-1.amazonaws.com/' + item.Key} target='_blank' key={index}>
                  {item.Key.replace('tabelas/amb/', '').replace('.pdf', '')}
                </Link> : <></>
            ))}
          </div>
        </div>
        <div className={styles.divContainerLinks}>
          <SubTituloAss subTitulo={'Tabelas CBHPM'} />
          <div className={styles.divLinks}>
            {cbhpm && cbhpm.map((item: any, index: any) => (
              index !== 0 ?
                <Link to={'https://site-amai.s3.sa-east-1.amazonaws.com/' + item.Key} target='_blank' key={index}>
                  {item.Key.replace('tabelas/cbhpm/', '').replace('.pdf', '')}
                </Link> : <></>
            ))}
          </div>
        </div>
        <div className={styles.divContainerLinks}>
          <SubTituloAss subTitulo={'Tabelas de portes'} />
          <div className={styles.divLinks}>
            {portes && portes.map((item: any, index: any) => (
              index !== 0 ?
                <Link to={'https://site-amai.s3.sa-east-1.amazonaws.com/' + item.Key} target='_blank' key={index}>
                  {item.Key.replace('tabelas/portes/', '').replace('.pdf', '')}
                </Link> : <></>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Tabelas