import React, { useEffect, useState } from 'react'
import styles from './CadastrarLogo.module.css'
import TituloAssociado from '../Helper/TituloAssociado'
import Head from '../Helper/Head'
import Input from '../Forms/Input'
import useForm from '../Hooks/useForm'
import Anexos from '../NovoAssociado/Anexos'
import Button from '../Forms/Button'
import { useUserContext } from '../../UserContext'
import { useFileContext } from '../../useFileContext'
import { REGISTER_LOGO } from '../../api'
import UploadFiles from '../ServerSide/uploadToS3'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-modal'

const CadastrarLogo = () => {
  const site = useForm('');
  const { loading, setLoading, token, data, error, setError } = useUserContext();
  const { uploadedFiles = [], setUploadedFiles } = useFileContext();
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const navigate = useNavigate();

  function fecharModal() {
    setEmailSent(false);
    navigate('/area-associado')
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    try {
      setLoading(true)
      if (uploadedFiles.length < 1 || !site.value) {
        setError('Favor preencher todos os campos acima.')
        setLoading(false)
        return
      }
      const response = await UploadFiles(uploadedFiles, data.num_cnpj + '-', 'logos')
      const docsResp = response?.map((item: any, index) => {
        return { url: item.Location };
      })
      const { url, options } = REGISTER_LOGO({ link_clinica: site.value, url_logo: docsResp ? docsResp[0].url : '' }, token)
      const dataAws = await fetch(url, options);
      const { message } = await dataAws.json();
      if (!dataAws.ok) throw new Error(message)
      setMensagem(message)
      setEmailSent(true)
    } catch (e: any) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setUploadedFiles([])
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <TituloAssociado titulo='cadastrar logomarca - associados' />
      <Head title='Área do Associado - Cadastrar Logo' description='Área do associado' />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>
          {mensagem}
        </p>
        <button onClick={fecharModal}>Fechar</button>
      </Modal>
      <div className={styles.divCadastro}>
        <form onSubmit={handleSubmit}>
          <Input label='Site da Clínica' type='text' name='urlSite' placeholder='Digite a url do site da sua clínica aqui...'  {...site} maxLength={1000}/>
          <Anexos campo={0} label='Logomarca' nome='logomarca' accept={{
            'image/jpeg': [],
            'image/png': []
          }} />
          <Button descricao={loading ? 'enviando...' : 'enviar'} classe='submit' disabled={loading} />
          <p className={styles.error}>{error}</p>
        </form>
      </div>
    </section>
  )
}

export default CadastrarLogo