import React, { useEffect, useState } from 'react'
import styles from './NavGerencial.module.css'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as Sair } from "../../Assets/sair.svg";
import { ReactComponent as Arrow } from "../../Assets/arrow.svg";
import path from 'path';
import { useUserContext } from '../../UserContext';

const NavGerencial = () => {
  const location = useLocation();
  const { pathname } = location;
  const { userLogout, menuVisible } = useUserContext()


  function handleSair() {
    userLogout()
  }



  return (
    <nav className={`${styles.menu} ${menuVisible ? '' : styles.menuVisible}`}>
      <div className={styles.divTitulo}>
        <h1>Área</h1>
        <h1><span>Gerencial</span></h1>
        <span className={styles.sep}></span>
      </div>
      <div className={styles.divMenu}>
        <Link to='aviso' className={pathname.includes('aviso') ? styles.selected : styles.itemMenu}>Aviso</Link>
        <Link to='circulares' className={pathname.includes('circulares') ? styles.selected : styles.itemMenu}>Circulares</Link>
        <Link to='convenios/geral' className={pathname.includes('convenios') ? styles.selected : styles.itemMenu}>Convênios</Link>
        {pathname.includes('convenios') &&
          <div className={styles.menuConv}>
            <Link to='convenios/especialidades' className={pathname.includes('especialidades') ? styles.selectedConv : styles.itemConvenio}>Relação de especialidades</Link>
            <Link to='convenios/manuais' className={pathname.includes('manuais') ? styles.selectedConv : styles.itemConvenio}>Manuais</Link>
            <Link to='convenios/geral' className={pathname.includes('geral') ? styles.selectedConv : styles.itemConvenio}>Geral</Link>
            <Link to='convenios/atendimento-restrito' className={pathname.includes('atendimento-restrito') ? styles.selectedConv : styles.itemConvenio}>Atendimento restrito - Hosp. Anchieta</Link>
            <Link to='convenios/formulario-entrega' className={pathname.includes('formulario-entrega') ? styles.selectedConv : styles.itemConvenio}>Formulário de entrega de guias</Link>
            <Link to='convenios/tabelas' className={pathname.includes('tabelas') ? styles.selectedConv : styles.itemConvenio}>Tabelas Gerais</Link>
          </div>}
        <Link to='corpo-clinico/associados' className={pathname.includes('corpo-clinico') ? styles.selected : styles.itemMenu}>Corpo clínico</Link>
        {pathname.includes('corpo-clinico') &&
          <div className={styles.menuConv}>
            <Link to='corpo-clinico/associados' className={pathname.includes('associados') ? styles.selectedConv : styles.itemConvenio}>Associados</Link>
            <Link to='corpo-clinico/profissionais' className={pathname.includes('profissionais') ? styles.selectedConv : styles.itemConvenio}>Profissionais de Saúde</Link>
            <Link to='corpo-clinico/nova-clinica-pre' className={pathname.includes('nova-clinica-pre') ? styles.selectedConv : styles.itemConvenio}>Nova clínica - Pré cadastro</Link>
            <Link to='corpo-clinico/novo-medico-pre' className={pathname.includes('novo-medico-pre') ? styles.selectedConv : styles.itemConvenio}>Novo profissional - Pré cadastro</Link>
          </div>}
        <Link to='extratos' className={pathname.includes('extratos') ? styles.selected : styles.itemMenu}>Extratos</Link>
      </div>
      <div className={styles.sair} onClick={handleSair}>
        <Sair className={styles.svg} />
        <p>Sair</p>
      </div>
    </nav>
  )
}

export default NavGerencial