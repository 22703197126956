import React, { useEffect, useState } from 'react'
import styles from './Especialidades.module.css'
import Head from '../Helper/Head'
import TituloAssociado from '../Helper/TituloAssociado'
import { Link } from 'react-router-dom'
import { useUserContext } from '../../UserContext'
import { GET_ESPECIALIDADES } from '../../api'
import { useFileContext } from '../../useFileContext'

const Especialidades = () => {
  const { token, setLoading } = useUserContext();
  const { especialidadesGer, setEspecialidadesGer, setDocsGer } = useFileContext();

  useEffect(() => {
    async function getEspecialidades() {
      try {
        setLoading(true)
        const { url, options } = GET_ESPECIALIDADES(token);
        const response = await fetch(url, options);
        const { especialidades, docs } = await response.json();
        if (!response.ok) throw new Error('Erro ao tentar trazer as especialidades');
        setEspecialidadesGer(especialidades);
        setDocsGer(docs);
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    getEspecialidades();
  }, [])

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Especialidades"
        description="Área do associado"
      />
      <TituloAssociado titulo='Relação de especialidades' />
      <div className={styles.divEspecialidades}>
        {especialidadesGer && especialidadesGer.map((item: any, index: any) => (
          <Link key={index} to={item.id.toString()}>{item.descricao}</Link>
        ))}
      </div>
    </section>
  )
}

export default Especialidades